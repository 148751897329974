import React, { useRef, useState } from 'react';
import Banner from 'sats-ui-lib/react/banner';
import LinkButton from 'sats-ui-lib/react/link-button';

import FlowLayout from 'components/flow-layout/flow-layout';
import Spinner from 'components/spinner/spinner';

import { useAdyen, type Core } from 'hooks/use-adyen';
import useAsyncEffect from 'hooks/use-async-effect';

import type { PayDebt as Props } from './pay-debt.types';
import { replaceQueryParameters } from 'shared/replace-query-parameters';

const PayDebt: React.FunctionComponent<Props> = ({
  actions,
  layout,
  messages,
  payment,
}) => {
  const checkout = useRef<Core>();
  const dropinContainer = useRef<HTMLDivElement>(null);
  const [localMessages, setLocalMessages] = useState<
    Parameters<typeof Banner>[0][]
  >([]);
  const handleAdyenLoadingError = () =>
    setLocalMessages([messages.errorLoading]);
  const [isLoading, hasLoaded] = useAdyen(handleAdyenLoadingError);

  useAsyncEffect(async () => {
    if (
      !dropinContainer.current ||
      !hasLoaded ||
      !payment.session ||
      !window.AdyenCheckout ||
      isLoading
    ) {
      return;
    }

    setLocalMessages([]);

    const adyenConfiguration: Parameters<typeof window.AdyenCheckout>[0] = {
      clientKey: payment.clientKey,
      environment: payment.environment,
      locale: payment.locale,
      onError: () => {
        setLocalMessages([messages.errorCheckout]);
        if (checkout.current) {
          checkout.current.update();
        }
      },
      onPaymentCompleted: response => {
        setLocalMessages([messages.paymentComplete[response.resultCode]]);

        switch (response.resultCode) {
          case 'Authorised': {
            const staleHref = replaceQueryParameters(window.location.href, {
              [payment.staleKey]: payment.staleKey,
            });

            window.history.replaceState(null, document.title, staleHref);
            window.location.assign(payment.nextHref);

            break;
          }
          default: {
            if (checkout.current) {
              checkout.current.update();
            }
          }
        }
      },
      paymentMethodsConfiguration: {
        card: {
          /**
           * '01' (250*400) is the only size that fits 320 wide viewports, which we must support.
           *
           * https://docs.adyen.com/online-payments/3d-secure/native-3ds2/web-component/
           */
          challengeWindowSize: '01',
          hasHolderName: true,
          holderNameRequired: true,
          name: payment.cardName,
        },
      },
      session: payment.session,
    };

    const _checkout = await window.AdyenCheckout(adyenConfiguration);
    _checkout.create('dropin').mount(dropinContainer.current);
    checkout.current = _checkout;
  }, [isLoading, hasLoaded, payment]);

  return (
    <FlowLayout {...layout}>
      <div className="pay-debt">
        <noscript>
          <Banner {...messages.noscript} />
        </noscript>
        <div className="pay-debt__messages">
          {localMessages.map(entry => (
            <div key={entry.text}>
              <Banner {...entry} />
            </div>
          ))}
        </div>
        {isLoading ? <Spinner theme={Spinner.themes.centered} /> : null}
        <div ref={dropinContainer} />
        <div className="pay-debt__actions">
          {actions.map(entry => (
            <div key={entry.href}>
              <LinkButton {...entry} variant={LinkButton.variants.secondary} />
            </div>
          ))}
        </div>
      </div>
    </FlowLayout>
  );
};

export default PayDebt;
