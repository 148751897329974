import cn from 'classnames';
import React from 'react';

import { FocusArea, useImageQuery } from 'hooks/use-image-query';

import Blur from '../blur/blur';

import type { BackgroundImage as Props } from './background-image.types';

// NOTE: BackgroundImage needs to be rendered inside a parent element which has non-static positioning.
// NOTE: 150/85 default values for defaultHeight and defaultWidth roughly equals 16/9
const BackgroundImage: React.FunctionComponent<Props> & {
  focusAreas: typeof FocusArea;
} = ({ className, defaultHeight = 85, defaultWidth = 150, focusArea, src }) => {
  const [setElement, query] = useImageQuery(
    defaultWidth,
    defaultHeight,
    focusArea
  );

  return (
    <div
      aria-hidden
      className={cn('background-image', className)}
      ref={setElement}
      style={{
        backgroundImage: `url(${src}${query})`,
      }}
    >
      <Blur />
    </div>
  );
};

BackgroundImage.focusAreas = FocusArea;

export default BackgroundImage;
