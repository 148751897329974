import type { LinkButton } from 'sats-ui-lib/react/link-button/link-button.types';

import type { ObjectValues } from 'client/types';

export const themes = {
  dark: 'dark',
  light: 'light',
} as const;

export const variants = {
  cta: 'cta',
  secondary: 'secondary',
} as const;

export type CTABanner = {
  link: LinkButton;
  text: string;
  theme?: ObjectValues<typeof themes>;
  variant?: ObjectValues<typeof variants>;
  title: string;
};
