import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ArrowLink from 'components/arrow-link/arrow-link';
import ContentContainer from 'components/content-container/content-container';
import MyMembershipCards from 'components/my-membership-cards/my-membership-cards';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import NavigationCard from 'components/navigation-card/navigation-card';

import type { MyPage as Props } from './my-page.types';

const MyPage: React.FunctionComponent<Props> = ({
  settings,
  myPageLayout,
  otherMembers,
  primaryMemberships,
}) => (
  <MyPageLayout {...myPageLayout} light>
    <div className="my-page">
      <div className="my-page__primary">
        <ContentContainer>
          <MyMembershipCards {...primaryMemberships} />
        </ContentContainer>
      </div>
      <ContentContainer>
        <div className="my-page__secondary">
          <nav>
            <Text
              elementName="h2"
              size={Text.sizes.headline2}
              theme={Text.themes.emphasis}
            >
              {settings.title}
            </Text>
            <div className="my-page__settings">
              <NavigationCard {...settings.profile} />
              <NavigationCard {...settings.paymentMethod} />
              <NavigationCard {...settings.upcomingTrainings} />
              <NavigationCard {...settings.payments} />
              <NavigationCard {...settings.communicationSettings} />
              <NavigationCard {...settings.rewards} />
            </div>
          </nav>
          {otherMembers.items.length ? (
            <div className="my-page__other-members">
              <Text
                elementName="h2"
                size={Text.sizes.headline2}
                theme={Text.themes.emphasis}
              >
                {otherMembers.title}
              </Text>
              <div className="my-page__other-members-list">
                {otherMembers.items.map(link => (
                  <ArrowLink {...link} key={link.href} />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </ContentContainer>
    </div>
  </MyPageLayout>
);

export default MyPage;
