import React from 'react';

import Banner from 'sats-ui-lib/react/banner';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from '../content-container/content-container';
import MyPageFooter from '../my-page-footer/my-page-footer';
import MyPageMenu from '../my-page-menu/my-page-menu';

import type { FlowLayout as Props } from './flow-layout.types';

const FlowLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  footer,
  menu,
  messages,
  title,
}) => (
  <div className="flow-layout">
    <header>
      <MyPageMenu {...menu} />
    </header>
    <ContentContainer>
      <main className="flow-layout__stack flow-layout__stack--xl">
        <Text
          elementName="h1"
          size={Text.sizes.headline2}
          theme={Text.themes.headline}
        >
          {title}
        </Text>
        <div className="flow-layout__stack flow-layout__stack--m">
          {messages.map(entry => (
            <div key={entry.text}>
              <Banner {...entry} icon />
            </div>
          ))}
        </div>
        <div>{children}</div>
      </main>
    </ContentContainer>
    <footer>
      <MyPageFooter {...footer} />
    </footer>
  </div>
);

export default FlowLayout;
