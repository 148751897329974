import React from 'react';

import Button from 'sats-ui-lib/react/button';
import useToggle from 'sats-ui-lib/react/hooks/use-toggle';

import ChatButton from 'components/chat-button/chat-button';
import { useSalesforceChat } from 'hooks/use-salesforce-chat';

import type { SalesforceChatTrigger as Props } from './salesforce-chat-trigger.types';

const SalesforceChatTrigger: React.FunctionComponent<Props> = ({
  config,
  floating,
  onMessage: publishMessage,
  texts,
}) => {
  const [isTriggerEnabled, , enableTrigger] = useToggle(false);

  useSalesforceChat(config.esw, config.init, config.settings, enableTrigger);

  const triggerChat = () => {
    if (!window.embedded_svc) {
      if (publishMessage) {
        publishMessage({ text: texts.missingApi, theme: 'error' });
      }
      return;
    }

    if (typeof window.embedded_svc.onHelpButtonClick !== 'function') {
      if (publishMessage) {
        publishMessage({ text: texts.missingFunction, theme: 'error' });
      }
      return;
    }

    window.embedded_svc.onHelpButtonClick();
  };

  return (
    <div className="salesforce-chat-trigger">
      {floating ? (
        <ChatButton
          disabled={!isTriggerEnabled}
          edge
          onClick={triggerChat}
          text={texts.trigger}
        />
      ) : (
        <Button
          disabled={!isTriggerEnabled}
          onClick={triggerChat}
          text={texts.trigger}
        />
      )}
    </div>
  );
};

export default SalesforceChatTrigger;
