import Check from '@sats-group/icons/16/check';
import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import ChangeMembershipSummary from 'components/change-membership-summary/change-membership-summary';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { ChangeMembershipOverviewPage as Props } from './change-membership-overview-page.types';

const ChangeMembershipOverviewPage: React.FunctionComponent<Props> = ({
  membershipOptions,
  myPageLayout,
  summary,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="change-membership-overview-page">
        <div className="change-membership-overview-page__content">
          <div className="change-membership-overview-page__membership-options">
            {membershipOptions.map(option => (
              <div
                key={option.title}
                className="change-membership-overview-page__membership-option"
              >
                <Text
                  className="change-membership-overview-page__membership-option-title"
                  size={Text.sizes.headline2}
                  theme={Text.themes.emphasis}
                >
                  {option.title}
                </Text>
                <div className="change-membership-overview-page__membership-option-card">
                  <div className="change-membership-overview-page__check">
                    <Check />
                  </div>
                  <Text
                    className="change-membership-overview-page__membership-option-card-title"
                    size={Text.sizes.headline3}
                  >
                    {option.card.title}
                  </Text>
                  <Text className="change-membership-overview-page__membership-option-card-text">
                    {option.card.text}
                  </Text>
                </div>
                <LinkButton
                  variant={LinkButton.variants.secondary}
                  {...option.button}
                  data-test-option
                ></LinkButton>
              </div>
            ))}
          </div>
          <div className="change-membership-overview-page__summary">
            {membershipOptions.length ? (
              <ChangeMembershipSummary {...summary} />
            ) : null}
          </div>
          {summary.nextButton ? (
            <div className="change-membership-overview-page__button-container">
              <LinkButton
                className="change-membership-overview-page__desktop-button"
                variant={LinkButton.variants.cta}
                wide={true}
                data-test-next
                {...summary.nextButton}
              />
            </div>
          ) : null}
        </div>
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default ChangeMembershipOverviewPage;
