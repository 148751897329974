import type { ObjectValues } from 'client/types';
import type { DynamicImage } from 'components/dynamic-image/dynamic-image.types';

export const headingSizes = {
  large: 'large',
  medium: 'medium',
} as const;

export const headingThemes = {
  emphasis: 'emphasis',
};

export const themes = {
  narrowImage: 'narrow-image',
} as const;

export type ImageLinkCard = {
  headingSize?: ObjectValues<typeof headingSizes>;
  headingTheme?: ObjectValues<typeof headingThemes>;
  href: string;
  image?: DynamicImage;
  shouldOpenInNewTab?: boolean;
  tag?: string;
  text?: string;
  theme?: ObjectValues<typeof themes>;
  title: string;
};
