import React from 'react';

import Expander from 'sats-ui-lib/react/expander';
import Text from 'sats-ui-lib/react/text';

import ContactUs from 'components/contact-us/contact-us';
import ContentBlocks from 'components/content-blocks/content-blocks';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';
import MemberCareCategoryGroup from 'components/member-care-category-group/member-care-category-group';
import RichText from 'components/rich-text/rich-text';
import SearchModal from 'components/search-modal/search-modal';
import Tagline from 'components/tagline/tagline';

import type { MemberCarePage as Props } from './member-care-page.types';

const MemberCarePage: React.FunctionComponent<Props> = ({
  blocks,
  categories,
  categoriesTitle,
  contactUs,
  highlights,
  layout,
  search,
  tagline,
  title,
}) => (
  <Layout {...layout}>
    <div className="member-care-page">
      <ContentContainer>
        <section className="member-care-page__header">
          <div className="member-care-page__tagline">
            <Tagline {...tagline} />
          </div>
          <Text
            className="member-care-page__title"
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
          {search ? (
            <div className="member-care-page__search">
              <SearchModal {...search} size={SearchModal.sizes.fullWidth} />
            </div>
          ) : null}
        </section>
        <section className="member-care-page__categories">
          <Text
            className="member-care-page__categories-title"
            elementName="h2"
            theme={Text.themes.emphasis}
            size={Text.sizes.headline3}
          >
            {categoriesTitle}
          </Text>
          <MemberCareCategoryGroup categories={categories} />
        </section>
        {highlights ? (
          <section className="member-care-page__highlights">
            <Expander {...highlights} itemRenderer={RichText} />
          </section>
        ) : null}
      </ContentContainer>
      {blocks ? (
        <section className="member-care-page__blocks-section">
          <div className="member-care-page__blocks">
            <ContentContainer>
              <ContentBlocks {...blocks} />
            </ContentContainer>
          </div>
        </section>
      ) : null}
    </div>
    <ContentContainer>
      <div className="member-care-page__contact-us">
        <ContactUs {...contactUs} />
      </div>
    </ContentContainer>
  </Layout>
);

export default MemberCarePage;
