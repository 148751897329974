export enum BlockType {
  ArticleCardCarousel = 'article-card-carousel',
  ArticleCardComponentList = 'article-card-component-list',
  ContentElementCarousel = 'content-element-carousel',
  ContentElementComponentList = 'content-element-component-list',
  Expander = 'expander',
  ProductBox = 'product-box',
  ProfileCardCarousel = 'profile-card-carousel',
  ProfileCardComponentList = 'profile-card-component-list',
  Quote = 'quote',
  QuoteCarousel = 'quote-carousel',
  QuoteComponentList = 'quote-component-list',
  SplitInfoModule = 'split-info-module',
  TextModule = 'text-module',
  TextModuleCarousel = 'text-module-carousel',
  TextModuleComponentList = 'text-module-component-list',
  Usp = 'usp',
  UspCarousel = 'usp-carousel',
  UspList = 'usp-list',
  UspComponentList = 'usp-component-list',
  VideoCardCarousel = 'video-card-carousel',
  YoutubeEmbed = 'youtube-embed',
}
