import ArrowRight from '@sats-group/icons/24/arrow-right';
import React from 'react';

import Button from 'sats-ui-lib/react/button';
import Text from 'sats-ui-lib/react/text';

import DynamicImage from 'components/dynamic-image/dynamic-image';
import OpenStatus from 'components/open-status/open-status';

import type { ClubElementForMap as Props } from './club-element-for-map.types';

const ClubElementForMap: React.FC<Props> = ({
  address,
  image,
  link,
  location,
  mapIsOpen,
  name,
  showInMap,
  openStatus,
  viewInMapButton,
}) => (
  <div
    className="club-element-for-map"
    data-lat={location.lat}
    data-lng={location.lng}
    tabIndex={-1}
  >
    <div className="club-element-for-map__row">
      <a
        className="club-element-for-map__link"
        aria-label={link.ariaLabel}
        href={link.href}
      >
        <div className="club-element-for-map__information">
          <div className="club-element-for-map__club-information">
            <Text
              className="club-element-for-map__name"
              size={Text.sizes.headline3}
              theme={Text.themes.emphasis}
            >
              {name}
            </Text>
            <div className="club-element-for-map__address">
              <Text>{address.streetAddress}</Text>
              <Text>{address.area}</Text>
            </div>
            {openStatus ? (
              <div className="club-element-for-map__open-status">
                <OpenStatus {...openStatus} />
              </div>
            ) : null}
          </div>

          {!mapIsOpen ? (
            <div className="club-element-for-map__read-more">
              <Text
                className="club-element-for-map__read-more-text"
                aria-label={link.ariaLabel}
              >
                {link.text}
              </Text>
              <div className="club-element-for-map__icon-wrapper">
                <ArrowRight />
              </div>
            </div>
          ) : null}
        </div>

        <div className="club-element-for-map__image">
          <DynamicImage {...image} />
        </div>
      </a>
    </div>
    <div className="club-element-for-map__row">
      {mapIsOpen ? (
        <div className="club-element-for-map__view-in-map-button">
          <Button
            variant={Button.variants.tertiary}
            leadingIcon={<ArrowRight />}
            onClick={showInMap}
            {...viewInMapButton}
          />
        </div>
      ) : null}
    </div>
  </div>
);

export default ClubElementForMap;
