import SvgAvatar from '@sats-group/icons/160/avatar-subtract';
import FuzzySearch from 'fuzzy-search';
import { byProperty } from 'list-fns';
import React, { useMemo, useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Select from 'sats-ui-lib/react/select';
import Text from 'sats-ui-lib/react/text';

import interpolate from 'shared/interpolate';

import { mod } from 'client/helpers/add-bem-modifiers';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import ContentContainer from 'components/content-container/content-container';
import DropdownWrapper from 'components/dropdown-wrapper/dropdown-wrapper';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import ElementInterpolator from 'components/element-interpolator/element-interpolator';
import Layout from 'components/layout/layout';

import type {
  ProfileFilterPage as Props,
  Sort,
} from './profile-filter-page.types';
import { descriptionThemes } from './profile-filter-page.types';

const ProfileFilterPage: React.FunctionComponent<Props> & {
  descriptionThemes: typeof descriptionThemes;
} = ({
  breadcrumbs,
  defaultItemsToShow = 20,
  descriptionTheme,
  dropdownWrapper,
  layout,
  profiles = [],
  results,
  searchOptions,
  selectedFiltersLabel,
  showMoreButton,
  sort,
  title,
}) => {
  const [numberOfItemsToShow, setNumberOfItemsToShow] =
    useState(defaultItemsToShow);
  const showMore = () =>
    setNumberOfItemsToShow(number => number + defaultItemsToShow);

  const [searchResult, setSearchResult] = useState(profiles);
  const [sortingId, setSortingId] = useState<Sort>();

  const sortedProfiles = useMemo(
    () =>
      sortingId ? [...searchResult].sort(byProperty(sortingId)) : searchResult,
    [searchResult, sortingId]
  );

  const search = (searchTerm: string) => {
    const searcher = new FuzzySearch(
      profiles,
      searchOptions.searchKey,
      searchOptions.options
    );
    const result = searcher.search(searchTerm);
    setSearchResult(result);
  };

  return (
    <Layout {...layout}>
      {breadcrumbs ? <Breadcrumbs {...breadcrumbs} /> : null}
      <div className="profile-filter-page">
        <ContentContainer className="profile-filter-page__header">
          <Text
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
        </ContentContainer>
        <div className="profile-filter-page__dropdown-wrapper">
          <ContentContainer>
            <DropdownWrapper {...dropdownWrapper} search={search} />
          </ContentContainer>
        </div>
        <ContentContainer>
          <div className="profile-filter-page__sub-header">
            <Text size={Text.sizes.basic}>
              <ElementInterpolator
                template={results}
                elements={{
                  number: (
                    <Text
                      className="profile-filter-page__number"
                      elementName="span"
                      theme={Text.themes.emphasis}
                    >
                      {searchResult.length}
                    </Text>
                  ),
                }}
              />
            </Text>
            {sort ? (
              <Select
                className="profile-filter-page__sort"
                {...sort}
                // NOTE: Casting below to tell it that the value is usable as keys to sort through PersonalTrainer's
                onChange={e => setSortingId(e.target.value as Sort)}
              />
            ) : undefined}
          </div>
          <div className="profile-filter-page__trainer-wrapper">
            {sortedProfiles
              .slice(0, numberOfItemsToShow)
              .map(
                ({
                  description,
                  image,
                  metadata,
                  name,
                  selectedFilters,
                  url,
                }) => (
                  <a
                    className="profile-filter-page__trainer"
                    key={name}
                    href={url}
                  >
                    <div className="profile-filter-page__image-wrapper">
                      {image ? (
                        <DynamicImage
                          {...image}
                          aspectRatio={DynamicImage.aspectRatios.square}
                          theme={DynamicImage.themes.round}
                        />
                      ) : (
                        <SvgAvatar />
                      )}
                    </div>
                    <div className="profile-filter-page__info-wrapper">
                      <Text
                        theme={Text.themes.emphasis}
                        size={Text.sizes.large}
                      >
                        {name}
                      </Text>
                      <div className="profile-filter-page__meta-wrapper">
                        {metadata.map((item, i) => (
                          <React.Fragment key={item}>
                            <Text size={Text.sizes.basic}>{item}</Text>
                            {i + 1 < metadata.length ? (
                              <div className="profile-filter-page__dot"></div>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </div>
                      {description ? (
                        <ul
                          className={mod(
                            'profile-filter-page__list',
                            descriptionTheme
                          )}
                        >
                          {description.map((item, i) => (
                            <li
                              key={`${name}-${item}-${i}`}
                              className="profile-filter-page__info"
                            >
                              <div className="profile-filter-page__dot profile-filter-page__dot--red" />
                              <Text size={Text.sizes.basic}>{item}</Text>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                      {selectedFilters.length ? (
                        <div className="profile-filter-page__searched-terms">
                          <Text elementName="span" size={Text.sizes.basic}>
                            {selectedFiltersLabel}
                          </Text>
                          <Text elementName="span" size={Text.sizes.basic}>
                            {selectedFilters.join(', ')}
                          </Text>
                        </div>
                      ) : null}
                    </div>
                  </a>
                )
              )}
          </div>
          {searchResult.length > numberOfItemsToShow ? (
            <div className="profile-filter-page__show-more-wrapper">
              <Button
                onClick={showMore}
                text={interpolate(showMoreButton.text, {
                  number: String(searchResult.length - numberOfItemsToShow),
                })}
              />
            </div>
          ) : null}
        </ContentContainer>
      </div>
    </Layout>
  );
};

ProfileFilterPage.descriptionThemes = descriptionThemes;

export default ProfileFilterPage;
