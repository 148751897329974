import Check from '@sats-group/icons/16/check';
import ArrowDown from '@sats-group/icons/24/arrow-down';
import ArrowUp from '@sats-group/icons/24/arrow-up';
import React, { useId, useState } from 'react';
import Collapse from 'react-tiny-collapse';

import Text from 'sats-ui-lib/react/text';
import VisuallyHidden from 'sats-ui-lib/react/visually-hidden';

import { mod } from 'client/helpers/add-bem-modifiers';

import type { AccordionItem as Props } from './accordion-item.types';

const AccordionItem: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  accordionId,
  children,
  collapseLabel,
  defaultIsOpen,
  expandLabel,
  hasCheckmark,
  isSelected,
  id,
  onClick,
  title,
}) => {
  const ariaId = useId();
  const [isOpen, setIsOpen] = useState(Boolean(defaultIsOpen));

  return (
    <div className="accordion__item">
      <div className={mod('accordion__header', { expanded: isOpen })}>
        <button
          aria-controls={ariaId}
          className="accordion__select"
          data-test-accordion-item={id}
          onClick={() => {
            if (onClick && accordionId) {
              onClick(accordionId, id);
            }
          }}
        >
          {hasCheckmark ? (
            <div
              className={mod('accordion__check', {
                checked: isSelected,
              })}
            >
              {isSelected ? <Check /> : null}
            </div>
          ) : null}

          <Text
            className="accordion__title"
            elementName="h3"
            size={Text.sizes.large}
            theme={Text.themes.emphasis}
          >
            {title}
          </Text>
        </button>
        <button
          aria-controls={ariaId}
          className="accordion__expand"
          onClick={() => setIsOpen(prevState => !prevState)}
        >
          <VisuallyHidden>
            {isOpen ? collapseLabel : expandLabel}
          </VisuallyHidden>
          {isOpen ? <ArrowUp /> : <ArrowDown />}
        </button>
      </div>

      <div aria-hidden={!isOpen} id={ariaId}>
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </div>
    </div>
  );
};

export default AccordionItem;
