import React, { useEffect, useRef } from 'react';

import { useAmberPlayer } from 'hooks/use-amber-player';

import type { EmbedVideo as Props } from './embed-video.types';

const EmbedVideo: React.FunctionComponent<Props> = ({ autoplay, embedId }) => {
  const container = useRef<HTMLDivElement>(null);
  const iframeHtml = embedId
    ? `<iframe allowfullscreen controls id="${embedId}"></iframe>`
    : '';

  const amberPlayer = useAmberPlayer();
  useEffect(() => {
    if (!amberPlayer || !iframeHtml || !embedId) {
      return;
    }

    // NOTE: The great people at Screen9 has decided that when calling `Player.dispose()`, the underlying DOM node should be removed. In order for this mess to work, the DOM node has to be added manually when starting a new Player.
    if (container.current && !container.current.querySelector('iframe')) {
      container.current.innerHTML = iframeHtml;
    }

    const player = new amberPlayer.Player({
      mediaid: embedId,
      containerid: embedId,
      autoplay,
    });

    return () => {
      // NOTE: Amber player sometimes throws "Player not found! Probably disposed." when calling this
      player.dispose().catch(() => {});
    };
  }, [embedId, iframeHtml, amberPlayer]);

  // NOTE: See comment further up about why dangerouslySetInnerHTML is necessary
  return (
    <div className="embed-video">
      <div
        className="embed-video__player"
        ref={container}
        dangerouslySetInnerHTML={{ __html: iframeHtml }}
      />
    </div>
  );
};

export default EmbedVideo;
