import React from 'react';

import type { YoutubePlayer as Props } from './youtube-player.types';

const YoutubePlayer: React.FunctionComponent<Props> = ({ autoplay, id }) => (
  <div className="youtube-player">
    <iframe
      src={`https://www.youtube-nocookie.com/embed/${id}?autoplay=${
        autoplay ? 1 : 0
      }&rel=0`}
      allow="autoplay; picture-in-picture;"
      allowFullScreen
    />
  </div>
);

export default YoutubePlayer;
