import React from 'react';

import Link from 'sats-ui-lib/react/link';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import UpcomingTrainingsList from 'components/upcoming-trainings-list/upcoming-trainings-list';

import type { MyUpcomingTrainingPage as Props } from './my-upcoming-training-page.types';

const MyUpcomingTrainingPage: React.FunctionComponent<Props> = ({
  bookButton,
  bookingRules,
  disclaimer,
  myUpcomingTraining,
  myPageLayout,
  whereToBook,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="my-upcoming-training-page">
        <div className="my-upcoming-training-page__disclaimer">
          <Text>{disclaimer}</Text>
        </div>

        {myUpcomingTraining.length ? (
          <div className="my-upcoming-training-page__activities">
            {myUpcomingTraining.map(entry => (
              <div key={entry.title}>
                <UpcomingTrainingsList
                  {...entry}
                  titleProps={{
                    elementName: 'h2',
                    size: Text.sizes.headline3,
                    theme: Text.themes.emphasis,
                  }}
                />
              </div>
            ))}
          </div>
        ) : null}

        <div className="my-upcoming-training-page__booking">
          <div className="my-upcoming-training-page__booking-text">
            <Text>{whereToBook}</Text>
          </div>
          <div className="my-upcoming-training-page__booking-link">
            <LinkButton {...bookButton} />
            {bookingRules ? <Link {...bookingRules} /> : null}
          </div>
        </div>
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default MyUpcomingTrainingPage;
