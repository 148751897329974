import React from 'react';

import { mod } from 'client/helpers/add-bem-modifiers';
import List from 'components/list/list';

import type { OpenStatus as Props } from './open-status.types';

// NOTE: This could be implemented as a hook, but then we wouldn't get any 'prop-types' validation which we want since the props are sort of complicated.
const OpenStatus: React.FunctionComponent<Props> = ({
  isOpen,
  metaText,
  shouldShowStatus = true,
  statusText,
}) => {
  const status = (
    <div key={statusText} className="open-status__status">
      {statusText}
    </div>
  );

  return (
    <div className={mod('open-status', { open: isOpen })}>
      <List theme={List.themes.horizontalDots}>
        {[shouldShowStatus ? status : undefined, metaText].filter(a => a)}
      </List>
    </div>
  );
};

export default OpenStatus;
