import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ClubLoadIndicator from 'components/club-load-indicator/club-load-indicator';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import OpenStatus from 'components/open-status/open-status';
import useClubLoadFactors from 'hooks/use-club-load-factors';

import type { LocationCard as Props } from './location-card.types';

const LocationCard: React.FunctionComponent<Props> = ({
  href,
  id,
  image,
  loadEndpoint,
  loadIndicator,
  openStatus,
  title,
  texts = [],
}) => {
  const [loadFactors] = useClubLoadFactors(loadEndpoint);

  return (
    <a className="location-card" href={href}>
      <Text
        className="location-card__title"
        elementName="h3"
        size={Text.sizes.large}
      >
        {title}
      </Text>

      <div className="location-card__image">
        {image ? <DynamicImage {...image} /> : null}
      </div>

      <Text className="location-card__text" size={Text.sizes.small}>
        {texts.map(text => (
          <p key={text}>{text}</p>
        ))}

        {openStatus ? <OpenStatus {...openStatus} /> : null}

        {loadIndicator && loadFactors[id] ? (
          <ClubLoadIndicator
            loadFactor={loadFactors[id]}
            theme={ClubLoadIndicator.themes.muted}
            {...loadIndicator}
          />
        ) : null}
      </Text>
    </a>
  );
};

export default LocationCard;
