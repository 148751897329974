import Avatar from '@sats-group/icons/160/avatar-subtract';
import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import ContentContainer from 'components/content-container/content-container';
import DisplayBootCampEvents from 'components/display-boot-camp-events/display-boot-camp-events';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import EmbedVideo from 'components/embed-video/embed-video';
import FullscreenModal from 'components/fullscreen-modal/fullscreen-modal';
import GroupExerciseSchedule from 'components/group-exercise-schedule/group-exercise-schedule';
import Layout from 'components/layout/layout';
import ProfileCards from 'components/profile-cards/profile-cards';
import RichText from 'components/rich-text/rich-text';

import type { ProfilePage as Props } from './profile-page.types';

const ProfilePage: React.FunctionComponent<Props> = ({
  availableHours,
  bootCamps,
  breadcrumbs,
  callToAction,
  contactButton,
  detailedLists,
  introduction,
  layout,
  lists,
  recommendations,
  schedule,
  similarProfiles,
  title,
  video,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [showAllRecommendations, setShowAllRecommendations] = useState(false);

  return (
    <Layout {...layout}>
      <Breadcrumbs {...breadcrumbs} />
      <div className="profile-page">
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <div className="profile-page__content">
            <div className="profile-page__section">
              <div className="profile-page__introduction">
                <div className="profile-page__image">
                  {introduction.image ? (
                    <DynamicImage
                      {...introduction.image}
                      aspectRatio={DynamicImage.aspectRatios.square}
                      theme={DynamicImage.themes.round}
                    />
                  ) : (
                    <Avatar />
                  )}
                </div>
                <div>
                  <Text
                    elementName="h1"
                    theme={Text.themes.emphasis}
                    size={Text.sizes.headline1}
                  >
                    {title}
                  </Text>
                  {introduction.meta.length ? (
                    <div className="profile-page__meta">
                      {introduction.meta.map(info => (
                        <div className="profile-page__meta-item" key={info}>
                          <Text>{info}</Text>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
              {introduction.description ? (
                <Text>{introduction.description}</Text>
              ) : null}
              {introduction.richDescription ? (
                <RichText {...introduction.richDescription} />
              ) : null}
              <div className="profile-page__contact-wrap">
                {introduction.email && introduction.email.email ? (
                  <div className="profile-page__contact-field">
                    <Text>{introduction.email.label}</Text>
                    <Text
                      elementName="a"
                      href={`mailto:${introduction.email.email}`}
                    >
                      {introduction.email.email}
                    </Text>
                  </div>
                ) : null}
                {introduction.email &&
                introduction.email.email &&
                introduction.phoneNumber &&
                introduction.phoneNumber.number ? (
                  <div className="profile-page__dot" />
                ) : null}
                {introduction.phoneNumber && introduction.phoneNumber.number ? (
                  <div className="profile-page__contact-field">
                    <Text>{introduction.phoneNumber.label}</Text>
                    <Text>{introduction.phoneNumber.number}</Text>
                  </div>
                ) : null}
              </div>
            </div>
            {video ? (
              <div className="profile-page__video">
                <Button
                  text={video.showVideo}
                  onClick={() => setModalIsVisible(true)}
                />
                {modalIsVisible ? (
                  <FullscreenModal
                    {...video.modal}
                    hide={() => setModalIsVisible(false)}
                  >
                    <EmbedVideo {...video.embed} autoplay={true} />
                  </FullscreenModal>
                ) : null}
              </div>
            ) : null}
            {recommendations ? (
              <div>
                <Text
                  className="profile-page__section-heading"
                  elementName="h2"
                  theme={Text.themes.emphasis}
                  size={Text.sizes.headline2}
                >
                  {recommendations.title}
                </Text>
                <div className="profile-page__recommendations">
                  {recommendations.cards
                    .slice(
                      0,
                      showAllRecommendations
                        ? recommendations.cards.length
                        : recommendations.numberOfItemsToShow
                    )
                    .map(card => (
                      <div key={card.review} className="profile-page__card">
                        <div>
                          <Text
                            theme={Text.themes.emphasis}
                            size={Text.sizes.large}
                            elementName="span"
                          >
                            {card.name}
                          </Text>
                          {card.age ? (
                            <Text
                              size={Text.sizes.large}
                              elementName="span"
                              className="profile-page__age"
                            >
                              {card.age}
                            </Text>
                          ) : null}
                        </div>
                        {card.review}
                      </div>
                    ))}
                </div>
                {recommendations.cards.length >
                recommendations.numberOfItemsToShow ? (
                  <div className="profile-page__show-more-wrapper">
                    <button
                      className="profile-page__show-more"
                      onClick={() => setShowAllRecommendations(state => !state)}
                    >
                      {showAllRecommendations
                        ? recommendations.hideLabel
                        : recommendations.showLabel}
                      {showAllRecommendations ? undefined : (
                        <span className="profile-page__show-more-number">
                          (
                          {recommendations.cards.length -
                            recommendations.numberOfItemsToShow}
                          )
                        </span>
                      )}
                    </button>
                  </div>
                ) : null}
              </div>
            ) : null}
            {lists.map(list => (
              <div key={list.title} className="profile-page__section">
                <Text
                  className="profile-page__section-heading"
                  elementName="h2"
                  theme={Text.themes.emphasis}
                  size={Text.sizes.headline2}
                >
                  {list.title}
                </Text>
                <ul className="profile-page__list">
                  {list.list.map(description => (
                    <li key={description}>
                      <Text>{description}</Text>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {detailedLists.map(list => (
              <div key={list.title} className="profile-page__section">
                <Text
                  className="profile-page__section-heading"
                  elementName="h2"
                  theme={Text.themes.emphasis}
                  size={Text.sizes.headline2}
                >
                  {list.title}
                </Text>
                <ul className="profile-page__list">
                  {list.list.map(({ title, description }) => (
                    <li key={title}>
                      <Text theme={Text.themes.emphasis}>{title}</Text>
                      <Text>{description}</Text>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {callToAction ? (
              <div className="profile-page__action">
                <LinkButton
                  {...callToAction}
                  variant={LinkButton.variants.cta}
                />
              </div>
            ) : null}
            {availableHours && availableHours.days.length ? (
              <div className="profile-page__section">
                <Text
                  className="profile-page__section-heading"
                  elementName="h2"
                  theme={Text.themes.emphasis}
                  size={Text.sizes.headline2}
                >
                  {availableHours.title}
                </Text>
                <dl className="profile-page__availability">
                  {availableHours.days.map(({ name, times }) => (
                    <div className="profile-page__availability-row" key={name}>
                      <dt>
                        <Text>{name}</Text>
                      </dt>
                      <dd>
                        {times.map(text => (
                          <div key={text}>
                            <Text>{text}</Text>
                          </div>
                        ))}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            ) : null}
            {contactButton ? (
              <div className="profile-page__action">
                <LinkButton {...contactButton} />
              </div>
            ) : undefined}
            {schedule ? (
              <div>
                <Text
                  className="profile-page__section-heading"
                  elementName="h2"
                  theme={Text.themes.emphasis}
                  size={Text.sizes.headline2}
                >
                  {schedule.title}
                </Text>
                <GroupExerciseSchedule {...schedule.schedule} />
              </div>
            ) : null}
            {bootCamps ? (
              <div>
                <Text
                  className="profile-page__section-heading"
                  elementName="h2"
                  theme={Text.themes.emphasis}
                  size={Text.sizes.headline2}
                >
                  {bootCamps.title}
                </Text>
                <DisplayBootCampEvents {...bootCamps.events} />
              </div>
            ) : null}
          </div>
        </ContentContainer>
        {similarProfiles ? (
          <div className="profile-page__banner">
            <ContentContainer theme={ContentContainer.themes.narrow}>
              <Text
                elementName="h2"
                theme={Text.themes.emphasis}
                size={Text.sizes.headline2}
              >
                {similarProfiles.header}
              </Text>
              <ProfileCards {...similarProfiles.profileCards} />
            </ContentContainer>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default ProfilePage;
