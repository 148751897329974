import Back from '@sats-group/icons/24/back';
import Location from '@sats-group/icons/24/location';
import SearchIcon from '@sats-group/icons/24/search';
import FuzzySearch from 'fuzzy-search';
import React, { useEffect, useState, useRef } from 'react';

import Button from 'sats-ui-lib/react/button';
import { useIsMounted } from 'sats-ui-lib/react/hooks/use-is-mounted';
import useToggle from 'sats-ui-lib/react/hooks/use-toggle';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import { mapToggleHelper } from 'shared/map-helper';

import { mod } from 'client/helpers/add-bem-modifiers';
import debounce from 'client/helpers/debounce';
import ClubElementForMap from 'components/club-element-for-map/club-element-for-map';
import type { ClubElementForMap as ClubElementForMapType } from 'components/club-element-for-map/club-element-for-map.types';
import ContentContainer from 'components/content-container/content-container';
import DropdownWrapper from 'components/dropdown-wrapper/dropdown-wrapper';
import Layout from 'components/layout/layout';
import Map from 'components/map/map';
import type { OpenStatus } from 'components/open-status/open-status.types';
import Spinner from 'components/spinner/spinner';
import usePolling from 'hooks/use-polling';

import type { ClubsOverviewPage as Props } from './clubs-overview-page.types';

const ClubsOverviewPage: React.FunctionComponent<Props> = ({
  clubList,
  dropdownWrapper,
  heroBanner,
  isMapOpenByDefault,
  layout,
  map,
  noSearchResults,
  openingHoursEndpoint,
  searchInput,
  searchOptions,
}) => {
  const [isMapOpen, toggleMap, , closeMapFn] = useToggle(isMapOpenByDefault);
  const mapContainer = useRef<HTMLDivElement>(null);
  const [mapLocations, setMapLocations] = useState(clubList);
  const [mapOptions, setMapOptions] = useState(map ? map.map.options : {});
  const [hiddenInputs, setHiddenInputs] = useState(
    dropdownWrapper.hiddenInputs
  );
  const [openStatuses = {}] =
    usePolling<Record<string, OpenStatus>>(openingHoursEndpoint);
  const [searchResult, setSearchResult] = useState(clubList);

  useEffect(() => {
    setHiddenInputs(
      dropdownWrapper.hiddenInputs.concat([
        {
          name: 'map',
          value: isMapOpen ? mapToggleHelper.open : mapToggleHelper.closed,
        },
      ])
    );
  }, [isMapOpen]);

  const viewInMap = (mapOptions: ClubElementForMapType['mapOptions']) => {
    if (mapContainer.current) {
      mapContainer.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }

    setMapOptions(mapOptions);
  };

  const search = (searchTerm: string) => {
    const searcher = new FuzzySearch(
      clubList,
      searchOptions.searchKey,
      searchOptions.options
    );
    const result = searcher.search(searchTerm);
    setSearchResult(result);
    setMapLocations(result);
  };

  const isMounted = useIsMounted();

  const handleLocationClick = (lat: number, lng: number) => {
    if (!mapContainer.current) {
      return;
    }

    if (
      mapContainer.current.clientWidth === document.documentElement.clientWidth
    ) {
      // NOTE: The map is as wide as the document,
      // so the club elements are not next to it and
      // we then don't want to scroll to a club element.
      return;
    }

    // NOTE: By default returns `Element`, but we "know" it'll be an `HTMLElement`.
    // `focus` exists on `HTMLElement`, but not `Element`.
    const club = document.querySelector<HTMLElement>(
      `[data-lat="${lat}"][data-lng="${lng}"]`
    );
    if (club) {
      club.scrollIntoView();
      club.focus({ preventScroll: true });
    }
  };

  const markers = map
    ? map.map.markers
        .filter(marker =>
          mapLocations.find(
            ml =>
              ml.location.lat === marker.lat && ml.location.lng === marker.lng
          )
        )
        .map(marker =>
          Object.assign({}, marker, {
            onClick: () => handleLocationClick(marker.lat, marker.lng),
          })
        )
    : [];

  return (
    <Layout {...layout}>
      <div className="clubs-overview-page">
        <div className="clubs-overview-page__hero">
          <ContentContainer theme={ContentContainer.themes.wide}>
            <Text
              className="clubs-overview-page__hero-title"
              elementName="h1"
              theme={Text.themes.headline}
              italic
              size={Text.sizes.headline1}
            >
              {heroBanner.title}
            </Text>
            {heroBanner.text ? (
              <Text
                className="clubs-overview-page__hero-text"
                size={Text.sizes.large}
              >
                {heroBanner.text}
              </Text>
            ) : null}
            {searchInput ? (
              <div className="clubs-overview-page__search-input">
                {isMounted ? (
                  <div className="clubs-overview-page__search-input-inner">
                    <TextInput
                      {...searchInput}
                      icon={<SearchIcon />}
                      onInput={debounce(
                        e => search((e.target as HTMLInputElement).value),
                        300
                      )}
                    />
                  </div>
                ) : (
                  <Spinner />
                )}
              </div>
            ) : null}
          </ContentContainer>
        </div>
        <ContentContainer
          className="clubs-overview-page__content"
          theme={ContentContainer.themes.fullToWide}
        >
          <section className="clubs-overview-page__filters">
            <div className="clubs-overview-page__dropdown-wrapper">
              <DropdownWrapper
                {...dropdownWrapper}
                hiddenInputs={hiddenInputs}
              />
            </div>
            {map ? (
              <div className="clubs-overview-page__map-button">
                <Button
                  leadingIcon={<Location />}
                  text={isMapOpen ? map.hide : map.show}
                  variant={Button.variants.secondary}
                  onClick={toggleMap}
                />
              </div>
            ) : null}
          </section>

          <div
            className={mod('clubs-overview-page__clubs', {
              columns: isMapOpen,
            })}
          >
            <section className="clubs-overview-page__clubs-container">
              {searchResult.length ? (
                searchResult.map(club => (
                  <ClubElementForMap
                    key={club.name}
                    {...club}
                    mapIsOpen={isMapOpen}
                    openStatus={openStatuses[club.id]}
                    showInMap={() => viewInMap(club.mapOptions)}
                  />
                ))
              ) : (
                <div className="clubs-overview-page__no-search-results">
                  <SearchIcon />
                  <Text>{noSearchResults}</Text>
                </div>
              )}
            </section>
            {map && isMapOpen ? (
              <section
                className="clubs-overview-page__map-container"
                ref={mapContainer}
              >
                <Map
                  {...map.map}
                  markers={markers.length ? markers : map.map.markers}
                  showMarkers={mapLocations.length ? true : false}
                  options={mapOptions}
                />
                <div className="clubs-overview-page__map-closer">
                  <Button
                    leadingIcon={<Back />}
                    onClick={closeMapFn}
                    text={map.hide}
                    variant={Button.variants.primary}
                  />
                </div>
              </section>
            ) : null}
          </div>
        </ContentContainer>
      </div>
    </Layout>
  );
};

export default ClubsOverviewPage;
