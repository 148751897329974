import ChevronLeft from '@sats-group/icons/24/arrow-left';
import ChevronRight from '@sats-group/icons/24/arrow-right';
import React from 'react';

import Text from 'sats-ui-lib/react/text';

import useCounter from 'hooks/use-counter';

import type { Slideshow as Props } from './slideshow.types';

const Slideshow: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  initialSlide = 0,
  labels = [],
  nextButtonText,
  previousButtonText,
  thumbnails,
}) => {
  const slidesCount = React.Children.count(children);
  const maxIndex = slidesCount - 1;
  const [current, next, previous, setCurrent] = useCounter(
    0,
    maxIndex,
    initialSlide
  );

  const trackStyle = { transform: `translateX(${-current * 100}%)` };

  const thumbIndex = Math.max(0, Math.min(current - 1, maxIndex - 2));
  const thumbTrackStyle = {
    transform: `translateX(${(-thumbIndex * 100) / 3}%)`,
  };

  const previousButton = (
    <button
      aria-label={previousButtonText}
      className="slideshow__previous"
      onClick={previous}
      disabled={current === 0}
    >
      <ChevronLeft />
    </button>
  );

  const nextButton = (
    <button
      aria-label={nextButtonText}
      className="slideshow__next"
      onClick={next}
      disabled={current === maxIndex}
    >
      <ChevronRight />
    </button>
  );

  return (
    <div className="slideshow">
      <div className="slideshow__main">
        {previousButton}
        {nextButton}

        <div className="slideshow__track-container">
          <div className="slideshow__track" style={trackStyle}>
            {React.Children.map(children, child => (
              <div className="slideshow__slide">{child}</div>
            ))}
          </div>
        </div>
      </div>

      <div className="slideshow__sidebar">
        <div className="slideshow__thumbnails">
          <div className="slideshow__thumbnails-track" style={thumbTrackStyle}>
            {React.Children.map(thumbnails || children, (child, index) => (
              <div className="slideshow__thumbnail">
                <button
                  aria-label={labels?.[index]}
                  className="slideshow__thumbnail-button"
                  onClick={() => setCurrent(index)}
                />
                {child}
              </div>
            ))}
          </div>
        </div>

        <div className="slideshow__nav">
          {previousButton}
          <Text className="slideshow__count" theme={Text.themes.emphasis}>{`${
            current + 1
          }/${slidesCount}`}</Text>
          <Text className="slideshow__label">{labels?.[current]}</Text>
          {nextButton}
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
