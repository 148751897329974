import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import PaymentMethodCard from 'client/components/payment-method-card/payment-method-card';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { MyPaymentMethodsPage as Props } from './my-payment-methods-page.types';

const MyPaymentMethodsPage: React.FunctionComponent<Props> = ({
  activePaymentCard,
  actions,
  deleteForm,
  disclaimer,
  form,
  inActiveCardsDescription,
  inActiveCardsTitle,
  myPageLayout,
  noActiveAgreements,
  paymentCards,
}) => (
  <MyPageLayout {...myPageLayout}>
    <div className="my-payment-methods-page">
      <ContentContainer>
        <div className="my-payment-methods-page__content">
          {noActiveAgreements ? (
            <section className="my-payment-methods-page__no-active-agreements">
              <Text
                className="my-payment-methods-page__sub-title"
                elementName="h2"
                theme={Text.themes.emphasis}
                size={Text.sizes.headline3}
              >
                {noActiveAgreements.title}
              </Text>
              <Text
                className="my-payment-methods-page__description"
                elementName="p"
                size={Text.sizes.basic}
              >
                {noActiveAgreements.text}
              </Text>
            </section>
          ) : null}
          {activePaymentCard ? (
            <PaymentMethodCard {...activePaymentCard} />
          ) : null}
          {paymentCards.length > 0 ? (
            <section className="my-payment-methods-page__inactive-cards">
              <Text
                className="my-payment-methods-page__sub-title"
                elementName="h2"
                size={Text.sizes.headline3}
                theme={Text.themes.emphasis}
              >
                {inActiveCardsTitle}
              </Text>
              <Text
                className="my-payment-methods-page__description"
                elementName="p"
              >
                {inActiveCardsDescription}
              </Text>
              {paymentCards.map(card => (
                <div
                  className="my-payment-methods-page__inactive-card"
                  key={card.id}
                >
                  <PaymentMethodCard
                    {...card}
                    form={form}
                    deleteForm={deleteForm}
                  />
                </div>
              ))}
            </section>
          ) : null}
        </div>
        {actions.length ? (
          <div className="my-payment-methods-page__actions">
            {actions.map(action => (
              <LinkButton {...action} key={action.href} />
            ))}
          </div>
        ) : null}
        {disclaimer ? (
          <React.Fragment>
            <Text
              className="my-payment-methods-page__sub-title"
              elementName="h2"
              size={Text.sizes.headline3}
              theme={Text.themes.emphasis}
            >
              {disclaimer.title}
            </Text>
            <Text
              className="my-payment-methods-page__description"
              elementName="p"
              sizes={Text.sizes.small}
            >
              {disclaimer.message}
            </Text>
          </React.Fragment>
        ) : null}
      </ContentContainer>
    </div>
  </MyPageLayout>
);

export default MyPaymentMethodsPage;
