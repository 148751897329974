import React from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Text from 'sats-ui-lib/react/text';

import type { Regret as Props } from './regret.types';

const Regret: React.FC<Props> = ({
  button,
  description,
  endpoint,
  hiddenInputs,
  id,
  onSubmit,
  rawIcon,
  title,
}) => (
  <div className="regret" data-id={id}>
    <div
      className="regret__icon"
      dangerouslySetInnerHTML={{ __html: rawIcon }}
    />
    <div className="regret__text">
      <Text elementName="h3" theme={Text.themes.emphasis}>
        {title}
      </Text>
      {description ? (
        <Text elementName="p" size={Text.sizes.small}>
          {description}
        </Text>
      ) : null}
    </div>
    <form
      action={endpoint}
      className="regret__form"
      method="post"
      onSubmit={onSubmit}
    >
      {hiddenInputs.map(input => (
        <HiddenInput {...input} key={input.name} />
      ))}
      <Button {...button} type="submit" variant={Button.variants.primary} />
    </form>
  </div>
);

export default Regret;
