import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';

import { post } from 'client/helpers/api-helper';
import extractServerError from 'client/helpers/extract-server-error';
import { publish } from 'client/helpers/messages';

import type { ScheduleEventList as Props } from './group-exercise-schedule.types';
import type { ScheduleEvent as ScheduleEventProps } from './group-exercise-schedule.types';
import ScheduleEvent from './schedule-event';

const handleError = (error: Error) => {
  const message = extractServerError(error);
  if (message) {
    publish({ text: message, theme: 'error' });
  } else {
    throw error;
  }
};

const ScheduleEventList: React.FunctionComponent<Props> = ({
  date,
  event,
  events = [],
  isVisible,
  loadMoreEndpoint,
  loadMoreLabel,
  query,
  totalHits,
}) => {
  const [classes, setClasses] = useState<Partial<ScheduleEventProps>[]>(events);

  const handleLoadMore = async () => {
    try {
      //@ts-ignore
      const data = await post<{
        classes: Partial<ScheduleEventProps>[];
      }>(loadMoreEndpoint, {
        ...query,
        date,
        offset: classes.length,
      });
      setClasses(prevState => [...prevState, ...data.classes]);
    } catch (error) {
      return handleError(error);
    }
  };

  return (
    <div>
      <ul className="group-exercise-schedule__events">
        {classes.map(item => (
          <ScheduleEvent
            key={item.id}
            isVisible={isVisible}
            {...event}
            {...item}
          />
        ))}
      </ul>
      {totalHits && totalHits > classes.length ? (
        <div className="group-exercise-schedule__load">
          <Button onClick={handleLoadMore} text={loadMoreLabel} />
        </div>
      ) : null}
    </div>
  );
};

export default ScheduleEventList;
