import React from 'react';

import Text from 'sats-ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';
import DynamicImage from 'components/dynamic-image/dynamic-image';

import type { ImageLinkCard as Props } from './image-link-card.types';
import { headingSizes, headingThemes, themes } from './image-link-card.types';

const ImageLinkCard: React.FunctionComponent<Props> & {
  headingSizes: typeof headingSizes;
  headingThemes: typeof headingThemes;
  themes: typeof themes;
} = ({
  headingSize,
  headingTheme,
  href,
  image,
  shouldOpenInNewTab,
  tag,
  text,
  theme,
  title,
}) => {
  const openInNewTabProps = shouldOpenInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return (
    <a
      className={mod('image-link-card', theme)}
      href={href}
      {...openInNewTabProps}
    >
      <div className={mod('image-link-card__text', headingSize)}>
        {tag ? (
          <Text
            className="image-link-card__tag"
            size={Text.sizes.small}
            theme={Text.themes.emphasis}
          >
            {tag}
          </Text>
        ) : null}
        <Text
          elementName="h2"
          theme={
            headingTheme === headingThemes.emphasis
              ? Text.themes.emphasis
              : Text.themes.normal
          }
          size={
            headingSize === headingSizes.large
              ? Text.sizes.headline2
              : headingSize === headingSizes.medium
                ? Text.sizes.headline3
                : Text.sizes.basic
          }
        >
          {title}
        </Text>
        {text ? <p>{text}</p> : null}
      </div>

      <div className="image-link-card__image">
        {image ? (
          <DynamicImage
            aspectRatio={DynamicImage.aspectRatios.fourThree}
            {...image}
          />
        ) : null}
      </div>
    </a>
  );
};

ImageLinkCard.headingSizes = headingSizes;
ImageLinkCard.headingThemes = headingThemes;
ImageLinkCard.themes = themes;

export default ImageLinkCard;
