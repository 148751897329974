import SvgCheck from '@sats-group/icons/18/check';
import React from 'react';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Link from 'sats-ui-lib/react/link';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import DateInput from 'components/date-input/date-input';
import ElementInterpolator from 'components/element-interpolator/element-interpolator';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { CancelMembershipPage as Props } from './cancel-membership-page.types';

const CancelMembershipPage: React.FunctionComponent<Props> = ({
  cancellationDateTitle,
  cancellationDateText,
  dateInput,
  digitalUser,
  endpoint,
  generalConsent,
  hiddenInputs,
  marketingConsent,
  myPageLayout,
  previousPage,
  submit,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="cancel-membership-page">
        <form method="POST" action={endpoint}>
          {hiddenInputs.map(input => (
            <HiddenInput {...input} key={input.name} />
          ))}
          <div className="cancel-membership-page__content">
            <div className="cancel-membership-page__section">
              <Text
                elementName="h2"
                size={Text.sizes.headline2}
                theme={Text.themes.emphasis}
              >
                {cancellationDateTitle}
              </Text>

              <Text elementName="p">{cancellationDateText}</Text>

              <div className="cancel-membership-page__date-input">
                <DateInput {...dateInput} required />
              </div>
            </div>

            <div className="cancel-membership-page__section cancel-membership-page__section--l cancel-membership-page__section--contained">
              <Text
                elementName="h2"
                size={Text.sizes.headline2}
                theme={Text.themes.emphasis}
              >
                {digitalUser.title}
              </Text>

              {digitalUser.benefits.length ? (
                <ul className="cancel-membership-page__benefits">
                  {digitalUser.benefits.map(b => (
                    <li className="cancel-membership-page__benefit" key={b}>
                      <div aria-hidden>
                        <SvgCheck />
                      </div>
                      <Text>{b}</Text>
                    </li>
                  ))}
                </ul>
              ) : null}

              {digitalUser.terms ? (
                <Text elementName="p">
                  <ElementInterpolator
                    template={digitalUser.terms.template}
                    elements={{
                      digitalUserTermsAndConditions: (
                        <Link {...digitalUser.terms.link} />
                      ),
                    }}
                  />
                </Text>
              ) : null}

              <hr />

              <div>
                <Checkbox data-test-cancel-option {...digitalUser.checkbox} />
              </div>
            </div>

            {generalConsent || marketingConsent ? (
              <div className="cancel-membership-page__section cancel-membership-page__section--xl">
                {generalConsent ? (
                  <div className="cancel-membership-page__terms">
                    <Checkbox
                      {...generalConsent.checkbox}
                      required
                      data-test-terms
                    />
                    <div>
                      <Link
                        {...generalConsent.terms}
                        rel="noopener noreferrer"
                        target="_blank"
                      />
                    </div>
                  </div>
                ) : null}
                {marketingConsent ? (
                  <div className="cancel-membership-page__terms">
                    <Checkbox {...marketingConsent.checkbox} />
                    <Text size={Text.sizes.small}>
                      {marketingConsent.description}
                    </Text>
                    <div>
                      <Link
                        {...marketingConsent.terms}
                        rel="noopener noreferrer"
                        target="_blank"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="cancel-membership-page__actions">
              <LinkButton
                {...previousPage}
                variant={LinkButton.variants.secondary}
              />
              <Button type="submit" data-test-submit text={submit} />
            </div>
          </div>
        </form>
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default CancelMembershipPage;
