import React from 'react';

import Text from 'sats-ui-lib/react/text';

import DynamicImage from 'components/dynamic-image/dynamic-image';

import type { RewardCard as Props } from './reward-card.types';

const RewardCard: React.FunctionComponent<Props> = ({
  level,
  summary,
  benefits,
  icon,
}) => (
  <div className="reward-card">
    <div className="reward-card__icon">
      <DynamicImage aspectRatio={DynamicImage.aspectRatios.square} {...icon} />
    </div>
    <section className="reward-card__text">
      <Text
        className="reward-card__title"
        elementName="h3"
        size={Text.sizes.large}
        theme={Text.themes.emphasis}
      >
        {level}
      </Text>
      <Text
        className="reward-card__subtitle"
        elementName="h4"
        size={Text.sizes.small}
        theme={Text.themes.emphasis}
      >
        {summary}
      </Text>
      <Text className="reward-card__benefits" elementName="p">
        {benefits}
      </Text>
    </section>
  </div>
);

export default RewardCard;
