import React, { useEffect, useMemo, useState } from 'react';

import Button from 'sats-ui-lib/react/button';

import LinkBox from 'client/components/link-box/link-box';
import useUrlState from 'hooks/use-url-state';

import type { LinkBoxList as Props } from './link-box-list.types';

const pageSize = 20;

const LinkBoxList: React.FunctionComponent<Props> = ({
  initiallyVisiblePages,
  list = [],
  onListChange = () => {},
  pagesKey,
  showMoreLabel,
}) => {
  const [, setUrlState] = useUrlState();
  const [visiblePages, setVisiblePages] = useState(initiallyVisiblePages);

  const numberOfItemsToShow = useMemo(
    () => visiblePages * pageSize,
    [visiblePages]
  );

  const showMore = () =>
    setVisiblePages(currentVisiblePages => currentVisiblePages + 1);

  useEffect(() => {
    onListChange({
      filteredLength: list.length,
      visibleLength: Math.min(list.length, numberOfItemsToShow),
    });
  }, [list, numberOfItemsToShow]);

  useEffect(() => {
    setUrlState({ [pagesKey]: visiblePages });
  }, [visiblePages]);

  return (
    <div className="link-box-list">
      <div className="link-box-list__items">
        {list.slice(0, numberOfItemsToShow).map(item => (
          <div className="link-box-list__item" key={item.id}>
            <LinkBox {...item} />
          </div>
        ))}
      </div>

      {list.length > numberOfItemsToShow ? (
        <div className="link-box-list__load-more">
          <Button onClick={showMore} text={showMoreLabel} />
        </div>
      ) : null}
    </div>
  );
};

export default LinkBoxList;
