import React from 'react';

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9584 4.9585C8.09443 4.9585 4.95844 8.0945 4.95844 11.9585C4.95844 15.8225 8.09443 18.9585 11.9584 18.9585C15.8224 18.9585 18.9584 15.8225 18.9584 11.9585C18.9584 8.0945 15.8224 4.9585 11.9584 4.9585Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.928 1 1 5.928 1 12C1 18.072 5.928 23 12 23C18.072 23 23 18.072 23 12C23 5.928 18.072 1 12 1ZM2.30231 12C2.30231 17.358 6.64202 21.6977 12 21.6977C17.358 21.6977 21.6977 17.358 21.6977 12C21.6977 6.64202 17.358 2.30231 12 2.30231C6.64202 2.30231 2.30231 6.64202 2.30231 12Z"
      fill="currentColor"
    />
  </svg>
);
