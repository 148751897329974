import RunningIcon from '@sats-group/icons/32/running';
import React, { useState } from 'react';

import Text from 'sats-ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';
import DateList from 'components/date-list/date-list';

import type { GroupExerciseSchedule as Props } from './group-exercise-schedule.types';
import ScheduleEventList from './schedule-event-list';

const GroupExerciseSchedule: React.FunctionComponent<Props> = ({
  applyFiltersWarning,
  dateList,
  event,
  events = [],
  firstDateWithEvents,
  hasResults,
  noResultsText,
  noResultsDescription,
  query,
}) => {
  const [selectedDate, setSelectedDate] = useState(firstDateWithEvents);

  const handleDateClick = (date: string) => {
    setSelectedDate(date);
  };

  return (
    <div className="group-exercise-schedule">
      {hasResults ? (
        <React.Fragment>
          <DateList
            {...dateList}
            onClick={handleDateClick}
            selectedDate={selectedDate}
          />
          {events.map(group => {
            const isVisible = selectedDate === group.date;
            return (
              <div
                key={group.date}
                className={mod('group-exercise-schedule__event-list', {
                  hidden: !isVisible,
                })}
              >
                <ScheduleEventList
                  event={event}
                  query={query}
                  isVisible={isVisible}
                  {...group}
                />
              </div>
            );
          })}
        </React.Fragment>
      ) : (
        <div className="group-exercise-schedule__apply-filters-warning">
          <RunningIcon />
          <Text>{applyFiltersWarning}</Text>
        </div>
      )}
      {hasResults === false ? (
        <div className="group-exercise-schedule__empty">
          <Text
            className="group-exercise-schedule__empty-text"
            theme={Text.themes.emphasis}
            size={Text.sizes.large}
          >
            {noResultsText}
          </Text>
          <Text size={Text.sizes.basic}>{noResultsDescription}</Text>
        </div>
      ) : null}
    </div>
  );
};

export default GroupExerciseSchedule;
