import React from 'react';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';

import type { TerminationConfirmation as Props } from './termination-confirmation.types';
import Text from 'sats-ui-lib/react/text';

const TerminationConfirmation: React.FC<Props> = ({
  details,
  info,
  layout,
}) => (
  <FlowTerminusLayout {...layout}>
    <div className="termination-confirmation">
      {info.data.length ? (
        <div className="termination-confirmation__section">
          <Text
            elementName="h2"
            size={Text.sizes.large}
            theme={Text.themes.headline}
          >
            {info.title}
          </Text>
          <div className="termination-confirmation__info">
            {info.data.map(entry => (
              <p key={entry.text}>
                <div dangerouslySetInnerHTML={{ __html: entry.rawIcon }} />
                <Text>{entry.text}</Text>
              </p>
            ))}
          </div>
        </div>
      ) : null}
      {details.data.length ? (
        <div className="termination-confirmation__section">
          <Text
            elementName="h2"
            size={Text.sizes.large}
            theme={Text.themes.headline}
          >
            {details.title}
          </Text>
          <table className="termination-confirmation__details">
            <tbody>
              {details.data.map(entry => (
                <tr key={entry[0]}>
                  <Text elementName="th" theme={Text.themes.emphasis}>
                    {entry[0]}
                  </Text>
                  <Text elementName="td">{entry[1]}</Text>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  </FlowTerminusLayout>
);

export default TerminationConfirmation;
