import type { Block, Inline } from '@contentful/rich-text-types';

import { isString } from 'shared/is';

import type { CTABanner } from 'components/cta-banner/cta-banner.types';

export const toCtaBannerProps = (
  node: Block | Inline
): CTABanner | undefined => {
  if (!node || !node.data || !node.data.target || !node.data.target.fields) {
    return;
  }

  const { buttonText, buttonUrl, description, heading } =
    node.data.target.fields;

  if (
    isString(buttonText) &&
    isString(buttonUrl) &&
    isString(description) &&
    isString(heading)
  ) {
    return {
      link: {
        href: buttonUrl,
        text: buttonText,
      },
      text: description,
      theme: 'light',
      title: heading,
      variant: 'cta',
    };
  }
};
