import React from 'react';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';
import type { CorporateCodeError as Props } from './corporate-code-error.types';

const CorporateCodeError: React.FC<Props> = ({ layout }) => (
  <FlowTerminusLayout {...layout} />
);

export default CorporateCodeError;
