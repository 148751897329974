import React from 'react';

import TextInput from 'sats-ui-lib/react/text-input';

import type { PrefixInput as Props } from './prefix-input.types';

const PrefixInput: React.FunctionComponent<Props> = ({
  prefix,
  ...inputProps
}) => (
  <div className="prefix-input">
    {prefix ? <div className="prefix-input__prefix">{prefix}</div> : null}
    <div className="prefix-input__input">
      <TextInput {...inputProps} />
    </div>
  </div>
);

export default PrefixInput;
