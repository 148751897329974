import React from 'react';

import Text from 'sats-ui-lib/react/text';

import List from 'components/list/list';

import type { ChangeMembershipCard as Props } from './change-membership-card.types';

const ChangeMembershipCard: React.FunctionComponent<Props> = ({
  addons,
  club,
  membership,
  price,
  title,
}) => (
  <div className="change-membership-card">
    <Text
      className="change-membership-card__title"
      theme={Text.themes.emphasis}
    >
      {title}
    </Text>

    <div>
      <Text
        className="change-membership-card__group-title"
        size={Text.sizes.small}
      >
        {club.title}
      </Text>
      <Text size={Text.sizes.small}>{club.name}</Text>
    </div>

    <div>
      <Text
        className="change-membership-card__group-title"
        size={Text.sizes.small}
      >
        {membership.title}
      </Text>
      <div className="change-membership-card__list-container">
        <List theme={List.themes.greenCheckmarks}>{membership.items}</List>
      </div>
    </div>

    {addons ? (
      <div>
        <Text
          className="change-membership-card__group-title"
          size={Text.sizes.small}
        >
          {addons.title}
        </Text>
        <div className="change-membership-card__list-container">
          <List theme={List.themes.greenCheckmarks}>{addons.items}</List>
        </div>
      </div>
    ) : null}

    {price ? (
      <div className="change-membership-card__price-container">
        <Text>{price.title}</Text>
        <div className="change-membership-card__price">
          <Text>{price.amount}</Text>
          {price.period ? <Text>{price.period}</Text> : null}
        </div>
      </div>
    ) : null}
  </div>
);

export default ChangeMembershipCard;
