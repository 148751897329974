import React from 'react';

import Text from 'sats-ui-lib/react/text';

import AccordionItem from './accordion-item';
import type { Accordion as Props } from './accordion.types';

const Accordion: React.FunctionComponent<Props> = ({
  id,
  list = [],
  onClick,
  selectedId,
}) => (
  <div className="accordion">
    {list.map(item => (
      <AccordionItem
        key={item.id}
        {...item}
        accordionId={id}
        defaultIsOpen={selectedId === item.id}
        hasCheckmark={true}
        isSelected={selectedId === item.id}
        onClick={onClick}
      >
        <ul className="accordion__detail-list">
          {item.details?.map(detail => (
            <Text
              elementName="li"
              key={detail}
              className="accordion__detail-list-item"
            >
              {detail}
            </Text>
          ))}
        </ul>
      </AccordionItem>
    ))}
  </div>
);

export default Accordion;
