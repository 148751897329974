import React, { useState } from 'react';
import Text from 'sats-ui-lib/react/text';

import ArrowLink from '../arrow-link/arrow-link';
import MyMembershipCard from '../my-membership-card/my-membership-card';
import Regret from '../regret/regret';
import Spinner from '../spinner/spinner';

import type { MyMembershipCards as Props } from './my-membership-cards.types';

const MyMembershipCards: React.FunctionComponent<Props> = ({
  actions,
  bigActions,
  items,
  regretActions,
  title,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return items.length ? (
    <div className="my-membership-cards">
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      {title ? (
        <div className="my-membership-cards__title">
          <Text
            elementName="h2"
            size={Text.sizes.headline2}
            theme={Text.themes.emphasis}
          >
            {title}
          </Text>
        </div>
      ) : null}
      <div className="my-membership-cards__regrets">
        {regretActions.map(entry => (
          <div key={entry.id}>
            <Regret {...entry} onSubmit={handleSubmit} />
          </div>
        ))}
      </div>
      <div className="my-membership-cards__items">
        {items.map(({ key, membership }) => (
          <div key={key}>
            <MyMembershipCard {...membership} />
          </div>
        ))}
      </div>
      <div className="my-membership-cards__actions my-membership-cards__actions--grid">
        {actions.map(link => (
          <ArrowLink {...link} key={link.href} secondary small />
        ))}
      </div>
      <div className="my-membership-cards__actions my-membership-cards__actions--big">
        {bigActions.map(link => (
          <ArrowLink {...link} key={link.href} small />
        ))}
      </div>
    </div>
  ) : null;
};

export default MyMembershipCards;
