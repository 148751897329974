import { useEffect, useState } from 'react';

import { appendScript } from 'client/helpers/append-script';

type Player = {
  dispose(): Promise<unknown>;
  getCurrentTime(): number;
  getDuration(): number;
};

interface PlayerConstructor {
  new ({
    mediaid,
    containerid,
    autoplay,
  }: {
    mediaid: string;
    containerid: string;
    autoplay?: boolean;
  }): Player;
}

type AmberPlayer = {
  screen9: {
    Player: PlayerConstructor;
  };
};

declare global {
  interface Window {
    screen9?: AmberPlayer['screen9'];
  }
}

/**
 * https://dev.screen9.com/doc/amber-player/amber-player.html
 */
export const useAmberPlayer = () => {
  const [player, setPlayer] = useState<AmberPlayer['screen9']>();

  useEffect(() => {
    if (player) {
      return;
    }

    appendScript('https://cdn.screen9.com/players/amber-player.js', () =>
      setPlayer(window.screen9)
    );
  }, []);

  return player;
};
