import React, { useEffect } from 'react';

import useUrlState from 'client/hooks/use-url-state';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import Payment from 'components/payment/payment';

import type { MyPaymentAddCardPage as Props } from './my-payment-add-card-page.types';

const PaymentMethodsPage: React.FunctionComponent<Props> = ({
  myPageLayout,
  payment,
  redirectUrlStale,
  redirectUrlSuccess,
  redirectUrlError,
}) => {
  const [{ stale }] = useUrlState();

  useEffect(() => {
    if (stale) {
      window.location.replace(redirectUrlStale);
    }
  }, [stale]);

  const handlePaymentComplete = () =>
    window.location.assign(redirectUrlSuccess);
  const handlePaymentError = () => window.location.assign(redirectUrlError);

  return (
    <MyPageLayout {...myPageLayout}>
      <ContentContainer>
        <div className="payment-add-card-page">
          <div className="payment-add-card-page__content">
            <Payment
              {...payment}
              onPaymentComplete={handlePaymentComplete}
              onPaymentError={handlePaymentError}
            />
          </div>
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default PaymentMethodsPage;
