import React from 'react';

import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import ContentContainer from 'components/content-container/content-container';
import List from 'components/list/list';
import MemberTypeCard from 'components/member-type-card/member-type-card';
import RichText from 'components/rich-text/rich-text';

import type { SelectMemberTypePage as Props } from './select-member-type-page.types';

const SelectMemberTypePage: React.FC<Props> = ({
  campaignDisclaimer,
  campaignDisclaimerTitle,
  checkoutLayout,
  errorMessageGeneric,
  memberTypes = [],
  text,
}) => (
  <CheckoutLayout {...checkoutLayout}>
    <ContentContainer className="select-member-type-page">
      {memberTypes.length > 0 ? (
        <List theme={List.themes.gridSmall}>
          {memberTypes.map(card => (
            <MemberTypeCard key={card.name} {...card} />
          ))}
        </List>
      ) : (
        <Message
          inline
          text={errorMessageGeneric}
          theme={Message.themes.error}
        />
      )}
      {campaignDisclaimer ? (
        <div className="select-member-type-page__campaign">
          <Text elementName="h2" size={Text.sizes.headline3}>
            {campaignDisclaimerTitle}
          </Text>
          <Text className="select-member-type-page__campaign-disclaimer-text">
            {campaignDisclaimer}
          </Text>
        </div>
      ) : null}
      {text ? (
        <div className="select-member-type-page__text">
          <RichText {...text} />
        </div>
      ) : null}
    </ContentContainer>
  </CheckoutLayout>
);

export default SelectMemberTypePage;
