import React from 'react';

import { mod } from 'client/helpers/add-bem-modifiers';

import ProfileCard from './profile-card';
import type { ProfileCards as Props } from './profile-cards.types';
import { scrolling } from './profile-cards.types';

const ProfileCards: React.FunctionComponent<Props> & {
  scrolling: typeof scrolling;
} = ({ profiles, scrolling, isCentered }) =>
  profiles.length ? (
    <div
      className={mod('profile-cards__wrapper', scrolling, {
        centered: isCentered,
      })}
    >
      {profiles.map(profile => (
        <ProfileCard {...profile} key={profile.url} />
      ))}
    </div>
  ) : null;

ProfileCards.scrolling = scrolling;

export default ProfileCards;
