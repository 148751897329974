import Check from '@sats-group/icons/24/check';
import * as React from 'react';

import Flag from 'sats-ui-lib/react/flag';
import Text from 'sats-ui-lib/react/text';

import type { InfoBox as Props } from './info-box.types';

const InfoBox: React.FC<Props> = ({ items, tag, text }) => (
  <div className="info-box">
    <div className="info-box__tag-row">
      <div className="info-box__tag">
        <Flag text={tag} />
      </div>
    </div>
    <Text size={Text.sizes.basic}>{text}</Text>
    {items.length ? (
      <ul className="info-box__items">
        {items.map(item => (
          <li className="info-box__item" key={item}>
            <div className="info-box__check">
              <Check />
            </div>
            <Text size={Text.sizes.basic}>{item}</Text>
          </li>
        ))}
      </ul>
    ) : null}
  </div>
);

export default InfoBox;
