import SearchIcon from '@sats-group/icons/24/search';
import classNames from 'classnames';
import { uniqueBy } from 'list-fns';
import React, { useEffect, useState } from 'react';

import type { Chip } from 'sats-ui-lib/react/chip/chip.types';
import ChipSelected from 'sats-ui-lib/react/chip-selected';
import FilterWrapper from 'sats-ui-lib/react/filter-wrapper';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import { useIsMounted } from 'sats-ui-lib/react/hooks/use-is-mounted';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import debounce from 'client/helpers/debounce';
import Spinner from 'components/spinner/spinner';

import type { DropdownWrapper as Props } from './dropdown-wrapper.types';

const DropdownWrapper: React.FunctionComponent<Props> = ({
  action,
  filters,
  hiddenInputs,
  searchInput,
  search = () => {},
  selectedFilters,
  selectedValues = [],
  title,
}) => {
  const [allSelectedValues, setAllSelectedValues] = useState<Chip[]>([]);

  useEffect(() => {
    const values = selectedValues.filter(uniqueBy(item => item.id));
    setAllSelectedValues(values);
  }, []);

  const isMounted = useIsMounted();
  return (
    <div className="dropdown-wrapper">
      <div
        className={classNames('dropdown-wrapper__form-wrapper', {
          'dropdown-wrapper__form-wrapper--single':
            filters.filters.length === 1,
        })}
      >
        {searchInput || title ? (
          <div className="dropdown-wrapper__filter-header">
            {title ? (
              <Text theme={Text.themes.emphasis} size={Text.sizes.section}>
                {title}
              </Text>
            ) : null}
            {searchInput ? (
              <div className="dropdown-wrapper__search-input">
                {isMounted ? (
                  <div className="dropdown-wrapper__search-input-inner">
                    <TextInput
                      {...searchInput}
                      icon={<SearchIcon />}
                      onInput={debounce(
                        e => search((e.target as HTMLInputElement).value),
                        300
                      )}
                    />
                  </div>
                ) : (
                  <Spinner />
                )}
              </div>
            ) : null}
          </div>
        ) : null}
        <form action={action}>
          {hiddenInputs.map(input => (
            <HiddenInput key={input.name} {...input} />
          ))}
          <FilterWrapper {...filters} />
        </form>
        {selectedFilters && allSelectedValues && allSelectedValues.length ? (
          <div className="dropdown-wrapper__selected-options">
            <ChipSelected {...selectedFilters} chips={allSelectedValues} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DropdownWrapper;
