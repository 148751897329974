import SvgCreditcard from '@sats-group/icons/24/creditcard';
import React from 'react';
import Text from 'sats-ui-lib/react/text';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';
import type { CorporateCodeConfirmation as Props } from './corporate-code-confirmation.types';

const CorporateCodeConfirmation: React.FunctionComponent<Props> = ({
  data,
  layout,
  message,
}) => (
  <FlowTerminusLayout {...layout}>
    <div className="corporate-code-confirmation">
      <div className="corporate-code-confirmation__section">
        <Text
          elementName="h2"
          size={Text.sizes.large}
          theme={Text.themes.headline}
        >
          {message.title}
        </Text>
        <div className="corporate-code-confirmation__message">
          <div>
            <SvgCreditcard />
          </div>
          <Text>{message.text}</Text>
        </div>
      </div>
      {data.entries.length ? (
        <div className="corporate-code-confirmation__section">
          <Text
            elementName="h2"
            size={Text.sizes.large}
            theme={Text.themes.headline}
          >
            {data.title}
          </Text>
          <table className="corporate-code-confirmation__data">
            <tbody>
              {data.entries.map(([key, value]) => (
                <tr key={key}>
                  <Text elementName="th" theme={Text.themes.emphasis}>
                    {key}
                  </Text>
                  <Text elementName="td">{value}</Text>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  </FlowTerminusLayout>
);

export default CorporateCodeConfirmation;
