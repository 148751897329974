import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import { MediaType } from 'shared/media-type-helper';

import { mod } from 'client/helpers/add-bem-modifiers';
import BackgroundImage from 'components/background-image/background-image';
import BackgroundVideo from 'components/background-video/background-video';

import CleanLink from '../clean-link/clean-link';
import RichText from '../rich-text/rich-text';
import Tagline from '../tagline/tagline';

import type { SplitInfoModule as Props } from './split-info-module.types';
import { themes, variants } from './split-info-module.types';

const SplitInfoModule: React.FunctionComponent<Props> & {
  themes: typeof themes;
  variants: typeof variants;
} = ({
  disclaimer,
  heading,
  link,
  media,
  subButtonLink,
  tagline,
  text,
  theme = themes.rightAlignedMedia,
  variant,
}) => (
  <div className={mod('split-info-module', variant)}>
    <div className="split-info-module__content-parent">
      <div
        className={mod('split-info-module__content-wrapper', theme, variant)}
      >
        {tagline ? (
          <Tagline
            {...tagline}
            theme={
              variant === variants.featured
                ? Tagline.themes.onDarkBackground
                : undefined
            }
          />
        ) : null}
        <div className="split-info-module__content">
          <Text
            elementName="h2"
            size={Text.sizes.headline1}
            theme={Text.themes.emphasis}
          >
            {heading}
          </Text>
          <RichText {...text} />
          {link ? (
            <div>
              <LinkButton
                {...link}
                variant={
                  variant === variants.featured
                    ? LinkButton.variants.ctaSecondaryWhite
                    : LinkButton.variants.primary
                }
              />
            </div>
          ) : null}
          {disclaimer ? (
            <Text elementName="p" size={Text.sizes.interface}>
              {disclaimer}
            </Text>
          ) : null}
          {subButtonLink ? (
            <div className="split-info-module__sub-link">
              <CleanLink {...subButtonLink} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
    {React.createElement(
      link ? 'a' : 'div',
      {
        'aria-label': link ? heading : undefined,
        className: mod('split-info-module__media-wrapper', theme),
        href: link ? link.href : undefined,
      },
      <React.Fragment>
        <div aria-hidden className="split-info-module__media" />
        {media.type === MediaType.Image ? (
          <BackgroundImage {...media.props} />
        ) : (
          <BackgroundVideo {...media.props} />
        )}
      </React.Fragment>
    )}
  </div>
);

SplitInfoModule.themes = themes;
SplitInfoModule.variants = variants;

export default SplitInfoModule;
