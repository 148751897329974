import SvgBarbell from '@sats-group/icons/24/barbell';
import SvgLocation from '@sats-group/icons/24/location';
import SvgTime from '@sats-group/icons/24/time';
import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import ScaleBar from 'sats-ui-lib/react/scale-bar';
import Text from 'sats-ui-lib/react/text';

import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import ContentContainer from 'components/content-container/content-container';
import GroupClasses from 'components/group-classes/group-classes';
import Layout from 'components/layout/layout';
import List from 'components/list/list';
import PageHeader from 'components/page-header/page-header';
import RichText from 'components/rich-text/rich-text';

import type { GroupExercisePage as Props } from './group-exercise-page.types';

const GroupExercisePage: React.FunctionComponent<Props> = ({
  booking,
  breadcrumbs,
  charts = [],
  duration,
  equipment,
  facility,
  layout,
  header,
  preamble,
  similarClasses,
  supplementaryClasses,
  text,
}) => (
  <Layout {...layout}>
    <Breadcrumbs {...breadcrumbs} />
    <div className="group-exercise-page">
      <ContentContainer>
        <PageHeader {...header} />

        <div className="group-exercise-page__main">
          <div className="group-exercise-page__metadata">
            <LinkButton
              {...booking}
              wide={true}
              size={LinkButton.sizes.large}
            />
            {duration ? (
              <div className="group-exercise-page__metadata-item">
                <SvgTime />
                <Text>{duration}</Text>
              </div>
            ) : null}
            {facility ? (
              <div className="group-exercise-page__metadata-item">
                <SvgLocation />
                <Text>{facility}</Text>
              </div>
            ) : null}
            {equipment ? (
              <div className="group-exercise-page__metadata-item">
                <SvgBarbell />
                <Text>{equipment}</Text>
              </div>
            ) : null}
          </div>
          <div className="group-exercise-page__content">
            {preamble ? (
              <p className="group-exercise-page__preamble">{preamble}</p>
            ) : null}
            {text ? (
              <div className="group-exercise-page__text">
                <RichText {...text} />
              </div>
            ) : null}
          </div>
        </div>

        {charts.length ? (
          <div className="group-exercise-page__charts">
            <List theme={List.themes.gridLarge}>
              {charts.map(({ label, chart }) => (
                <div className="group-exercise-page__chart" key={label}>
                  <Text
                    className="group-exercise-page__chart-label"
                    elementName="div"
                  >
                    {label}
                  </Text>
                  <ScaleBar {...chart} />
                </div>
              ))}
            </List>
          </div>
        ) : null}

        {supplementaryClasses ? (
          <div className="group-exercise-page__related-classes">
            <GroupClasses {...supplementaryClasses} />
          </div>
        ) : null}

        {similarClasses ? (
          <div className="group-exercise-page__related-classes">
            <GroupClasses {...similarClasses} />
          </div>
        ) : null}
      </ContentContainer>
    </div>
  </Layout>
);

export default GroupExercisePage;
