import type { DynamicImage } from 'client/components/dynamic-image/dynamic-image.types';
import type { ObjectValues } from 'client/types';

export const scrolling = {
  horizontal: 'horizontal',
} as const;

export type ProfileCard = {
  name: string;
  position?: string;
  metadata: string[];
  image?: DynamicImage;
  list: string[];
  url: string;
};

export type ProfileCards = {
  profiles: ProfileCard[];
  isCentered?: boolean;
  scrolling?: ObjectValues<typeof scrolling>;
};
