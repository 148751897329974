import React from 'react';

import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import DropdownWrapper from 'components/dropdown-wrapper/dropdown-wrapper';
import GroupExerciseSchedule from 'components/group-exercise-schedule/group-exercise-schedule';
import Layout from 'components/layout/layout';

import type { GroupExerciseBookingPage as Props } from './group-exercise-booking-page.types';

const GroupExerciseBookingPage: React.FunctionComponent<Props> = ({
  filters,
  layout,
  messages,
  schedule,
  title,
}) => (
  <Layout {...layout}>
    <div className="group-exercise-booking-page">
      <ContentContainer>
        <Text
          elementName="h1"
          className="group-exercise-booking-page__title"
          size={Text.sizes.headline2}
          theme={Text.themes.headline}
          italic
        >
          {title}
        </Text>
      </ContentContainer>
      {messages.length ? (
        <ContentContainer>
          <div className="group-exercise-booking-page__messages">
            {messages.map(message => (
              <Message key={message.text} {...message} />
            ))}
          </div>
        </ContentContainer>
      ) : null}
      <div className="group-exercise-booking-page__dropdown-wrapper">
        <ContentContainer>
          <DropdownWrapper {...filters} />
        </ContentContainer>
      </div>
      <ContentContainer>
        <GroupExerciseSchedule {...schedule} />
      </ContentContainer>
    </div>
  </Layout>
);

export default GroupExerciseBookingPage;
