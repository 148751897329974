import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';

import { mod } from 'client/helpers/add-bem-modifiers';
import type { ThemedComponent } from 'client/types';

import { renderOptions } from './rich-text-renderer-options';
import type { RichText as Props } from './rich-text.types';
import { themes } from './rich-text.types';

const RichText: ThemedComponent<Props, typeof themes> = ({
  className,
  content,
  theme,
}) => (
  <div className={[mod('rich-text', theme), className].join(' ')}>
    {content ? documentToReactComponents(content, renderOptions) : null}
  </div>
);

RichText.themes = themes;

export default RichText;
