import SearchIcon from '@sats-group/icons/24/search';
import FuzzySearch from 'fuzzy-search';
import React, { useMemo, useRef, useState } from 'react';

import useClickOutside from 'sats-ui-lib/react/hooks/use-click-outside';
import useEscape from 'sats-ui-lib/react/hooks/use-escape';
import useToggle from 'sats-ui-lib/react/hooks/use-toggle';
import TabTrapper from 'sats-ui-lib/react/modal/tab-trapper';
import TextInput from 'sats-ui-lib/react/text-input';

import { mod } from 'client/helpers/add-bem-modifiers';
import useFocus from 'hooks/use-focus';

import type { SearchModal as Props } from './search-modal.types';
import { colors, sizes } from './search-modal.types';

const searchOptions = { sort: true };

const SearchModal: React.FunctionComponent<Props> & {
  colors: typeof colors;
  sizes: typeof sizes;
} = ({ color, dataset = [], searchInput, searchKeys, size }) => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [isFocused, setIsFocused] = useState(false);
  const wrapper: React.RefObject<HTMLDivElement> = useRef(null);

  const [inputRef, setInputFocus] = useFocus<HTMLInputElement>();
  const [expanded, , open, close] = useToggle(false);

  useClickOutside(wrapper, () => {
    close();
    setIsFocused(false);
  });

  useEscape(() => {
    close();
    setIsFocused(false);
    setInputFocus();
  });

  const search = useMemo(() => {
    const searcher = new FuzzySearch(dataset, searchKeys, searchOptions);
    return (term: string) => searcher.search(term);
  }, [dataset, searchKeys, searchOptions]);

  const searchResults = useMemo(
    () => (searchTerm ? search(searchTerm).slice(0, 10) : []),
    [search, searchTerm]
  );

  return (
    <div className={mod('search-modal', color, size)} ref={wrapper}>
      <div className="search-modal__input">
        <TextInput
          ref={inputRef}
          theme={color === colors.dark ? TextInput.themes.dark : undefined}
          hasMovingLabel={true}
          icon={<SearchIcon />}
          onChange={e => {
            setSearchTerm(e.target.value);
            open();
          }}
          aria-expanded={searchTerm && expanded ? 'true' : undefined}
          aria-controls="search-modal"
          {...searchInput}
        />
      </div>
      <div
        className={mod('search-modal__results', {
          open: Boolean(searchTerm && expanded),
        })}
        tabIndex={0}
        onFocus={() => setIsFocused(true)}
        id="search-modal"
      >
        {searchTerm && expanded ? (
          <TabTrapper isActive={isFocused}>
            <ul className="search-modal__list">
              {searchResults.map(({ href, question }) => (
                <li key={question} className="search-modal__item">
                  <a
                    href={href}
                    onFocus={e => {
                      e.stopPropagation();
                    }}
                  >
                    {question}
                  </a>
                </li>
              ))}
            </ul>
          </TabTrapper>
        ) : null}
      </div>
    </div>
  );
};

SearchModal.colors = colors;
SearchModal.sizes = sizes;

export default SearchModal;
