import { useEffect, useRef } from 'react';

import { trackEvent } from 'client/helpers/tracking';

const isDefined = <T>(v: T | undefined): v is T =>
  typeof v !== 'undefined' && v !== null;

/** Tracks changes to the provided value if an analytics provider is available. An event is not tracked if the value is `undefined`. Also, the first event with a non-`undefined` value is skipped (since we're tracking the _change_). Use the provided callback to build an event object:

```js
useTrackChange(someValue, v => ({
  action: 'ChangeSomething',
  label: v
}));
```
 */
export default function useTrackChange<T>(value: T, callback: (v: T) => void) {
  const cachedValue = useRef(value);

  useEffect(() => {
    if (!isDefined(value)) {
      return;
    }

    if (isDefined(cachedValue.current) && cachedValue.current !== value) {
      trackEvent(callback(value));
    }
    cachedValue.current = value;
  }, [value]);
}
