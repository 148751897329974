import { useEffect, useState } from 'react';

import { subscribe } from '../helpers/messages';
import type { MessageDetail } from '../helpers/messages.types';

export const useMessage = (
  initialMessage?: MessageDetail
): [message: MessageDetail | undefined, clearMessage: () => void] => {
  const [message, setMessage] = useState(initialMessage);

  const clearMessage = () => setMessage(undefined);

  const onMessage = (message: MessageDetail) => {
    setMessage(message);
  };

  useEffect(() => subscribe(onMessage), []);

  return [message, clearMessage];
};

export default useMessage;
