import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import DynamicMessage from 'components/dynamic-message/dynamic-message';

import type { WebAdminLayout as Props } from './web-admin-layout.types';

const WebAdminLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ actions, children, dynamicMessage, links, messages }) => (
  <div className="web-admin-layout">
    <DynamicMessage {...dynamicMessage} closeText="Close" />
    <ContentContainer className="web-admin-layout__content">
      <div className="web-admin-layout__header">
        <div>
          <Text
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
            italic
          >
            <a className="web-admin-layout__main-link" href="/web-admin">
              Web Admin
            </a>
          </Text>
        </div>
        {actions.length ? (
          <div className="web-admin-layout__actions">
            {actions.map(action => (
              <div key={action.href}>
                <LinkButton
                  {...action}
                  size={LinkButton.sizes.small}
                  variant={LinkButton.variants.secondary}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <nav className="web-admin-layout__links">
        {links.map(link => (
          <a key={link.href} href={link.href}>
            <Text size={Text.sizes.basic} theme={Text.themes.normal}>
              {link.text}
            </Text>
          </a>
        ))}
      </nav>
      {messages.map(message => (
        <div key={message.text}>
          <Message {...message} inline />
        </div>
      ))}
      <main>{children}</main>
    </ContentContainer>
  </div>
);

export default WebAdminLayout;
