import React from 'react';

import Message from 'sats-ui-lib/react/message';

import AccessLevelCard from 'components/access-level-card/access-level-card';
import CheckoutLayout from 'components/checkout-layout/checkout-layout';
import ContentContainer from 'components/content-container/content-container';
import SelectedClubCard from 'components/selected-club-card/selected-club-card';

import type { SelectAccessLevelPage as Props } from './select-access-level-page.types';

const SelectAccessLevelPage: React.FC<Props> = ({
  accessLevels = [],
  club,
  error,
  layout,
}) => (
  <CheckoutLayout {...layout}>
    <div className="select-access-level-page">
      <ContentContainer>
        {error ? (
          <Message inline text={error} theme={Message.themes.error} />
        ) : null}
        <div className="select-access-level-page__club">
          {club ? <SelectedClubCard {...club} /> : null}
        </div>

        <div className="select-access-level-page__list">
          {accessLevels.map(item => (
            <AccessLevelCard key={item.title} {...item} />
          ))}
        </div>
      </ContentContainer>
    </div>
  </CheckoutLayout>
);

export default SelectAccessLevelPage;
