import type { NodeRenderer } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';

import EmbeddedAssetImage from './embedded-asset-image';

const EmbeddedAssetRenderer: NodeRenderer = node => {
  if (node.nodeType !== BLOCKS.EMBEDDED_ASSET) {
    return null;
  }

  if (!node.data.target) {
    return null;
  }

  const fields = node.data.target.fields;
  if (!fields) {
    return;
  }

  const file = fields.file;
  if (!file) {
    return null;
  }

  if (!file.url) {
    return null;
  }

  return (
    <EmbeddedAssetImage alt={fields.title ? fields.title : ''} src={file.url} />
  );
};

export default EmbeddedAssetRenderer;
