import { useEffect, useState } from 'react';

import { toQueryString } from 'shared/to-query-string';
import { tuple } from 'shared/tuple';

export enum FocusArea {
  Bottom = 'bottom',
  BottomLeft = 'bottom_left',
  BottomRight = 'bottom_right',
  Center = 'center',
  Face = 'face',
  Faces = 'faces',
  Left = 'left',
  Right = 'right',
  Top = 'top',
  TopLeft = 'top_left',
  TopRight = 'top_right',
}

// NOTE: See docs at https://www.contentful.com/developers/docs/references/images-api/
// "The maximum allowed value is 4000 pixels.".
// Larger values result in error responses.
const maximumPixels = 4000;

const imageQuery = (width: number, height: number, focusArea?: FocusArea) =>
  toQueryString({
    f: focusArea,
    fit: 'fill',
    w: Math.ceil(Math.min(width, maximumPixels)),
    h: Math.ceil(Math.min(height, maximumPixels)),
    q: 75,
  });

const useBoundingClientRect = () => {
  const [element, setElement] = useState<HTMLElement | null>();
  const [rect, setRect] = useState<DOMRect>();

  useEffect(() => {
    if (element) {
      setRect(element.getBoundingClientRect());
    }
  }, [element]);

  return tuple([setElement, rect]);
};

const useDpr = () => {
  const [dpr, setDpr] = useState(1);

  useEffect(() => {
    setDpr(window.devicePixelRatio);
  }, []);

  return tuple([dpr]);
};

export const useImageQuery = (
  defaultWidth = 100,
  defaultHeight = 100,
  focusArea?: FocusArea
) => {
  const [setElement, rect] = useBoundingClientRect();
  const [dpr] = useDpr();
  const width = rect ? rect.width * dpr : defaultWidth;
  const height = rect ? rect.height * dpr : defaultHeight;

  return tuple([setElement, imageQuery(width, height, focusArea)]);
};
