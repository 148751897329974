import React from 'react';

import CleanLink from 'client/components/clean-link/clean-link';
import type { CheckoutProgressStepContent as Props } from 'components/checkout-progress/checkout-progress-step-content.types';

const CheckoutProgressStepContainer: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children, className, isComplete, link, query }) =>
  isComplete && link ? (
    <CleanLink {...link} className={className} query={query}>
      {children}
    </CleanLink>
  ) : (
    <div className={className}>{children}</div>
  );

export default CheckoutProgressStepContainer;
