import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ArrowLinkGroup from 'components/arrow-link-group/arrow-link-group';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { CancelMembershipLandingPage as Props } from './cancel-membership-landing-page.types';

const CancelMembershipLandingPage: React.FunctionComponent<Props> = ({
  cancellationReasons,
  cancellationReasonsTitle,
  myPageLayout,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="cancel-membership-landing-page">
        <Text size={Text.sizes.headline2} theme={Text.themes.emphasis}>
          {cancellationReasonsTitle}
        </Text>
        <ArrowLinkGroup {...cancellationReasons} />
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default CancelMembershipLandingPage;
