import React from 'react';

import Expander from 'sats-ui-lib/react/expander';
import Text from 'sats-ui-lib/react/text';

import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import ContactUs from 'components/contact-us/contact-us';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';
import RichText from 'components/rich-text/rich-text';

import type { MemberCareSubcategoryPage as Props } from './member-care-subcategory-page.types';

const MemberCareSubcategoryPage: React.FunctionComponent<Props> = ({
  articles,
  breadcrumbs,
  contact,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="member-care-subcategory-page">
      <Breadcrumbs {...breadcrumbs} />
      <ContentContainer>
        <Text
          className="member-care-subcategory-page__title"
          elementName="h1"
          italic
          size={Text.sizes.headline2}
          theme={Text.themes.headline}
        >
          {title}
        </Text>
        {articles ? (
          <div className="member-care-subcategory-page__articles">
            <Expander {...articles} itemRenderer={RichText} />
          </div>
        ) : null}
        <div className="member-care-subcategory-page__contact-us">
          <ContactUs {...contact} mode="closed" />
        </div>
      </ContentContainer>
    </div>
  </Layout>
);

export default MemberCareSubcategoryPage;
