/* eslint-disable no-console */
import { createElement } from 'react';
import { hydrateRoot } from 'react-dom/client';

import { pageComponents } from 'shared/page-components.generated';

import getValueFromAttribute from './helpers/get-value-from-attribute';

(() => {
  const componentName = getValueFromAttribute('data-component-name');
  if (!componentName) {
    console.error('No component name found.');
    return;
  }

  const mountPoint = document.getElementById('mount-point');
  if (!mountPoint) {
    console.error('No mount point found.');
    return;
  }

  const component = pageComponents[componentName];
  if (!component) {
    console.error(`Component "${componentName}" not found.`);
    return;
  }

  const propsNode = document.querySelector('[data-props]');
  if (!propsNode) {
    console.error('Props node not found.');
    return;
  }

  const props = propsNode.textContent;
  if (!props) {
    console.error('No props present in props node.');
    return;
  }

  let json;
  try {
    json = JSON.parse(props);
  } catch (error) {
    console.error('Prop parsing failed');
    console.error(error);
    return;
  }

  hydrateRoot(mountPoint, createElement(component, json));
  document.documentElement.setAttribute('data-hydrated', '');
})();
