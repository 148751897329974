import React from 'react';

import LinkCard from 'sats-ui-lib/react/link-card';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import ImageLinkCard from 'components/image-link-card/image-link-card';
import Layout from 'components/layout/layout';
import List from 'components/list/list';
import PageHeader from 'components/page-header/page-header';
import RichText from 'components/rich-text/rich-text';

import type { ArticlePage as Props } from './article-page.types';

const ArticlePage: React.FunctionComponent<Props> = ({
  header,
  intro,
  layout,
  pdfs,
  related,
  text,
  webLinks,
}) => (
  <Layout {...layout}>
    <article className="article-page">
      <ContentContainer theme={ContentContainer.themes.medium}>
        <PageHeader {...header} />
        {intro ? (
          <Text
            elementName="p"
            className="article-page__intro"
            size={Text.sizes.large}
          >
            {intro}
          </Text>
        ) : null}
        <RichText className="article-page__main" {...text} />
      </ContentContainer>

      {webLinks ? (
        <ContentContainer theme={ContentContainer.themes.medium}>
          <ul className="article-page__link-container">
            {webLinks.map(link => (
              <li key={link.href}>
                <LinkCard {...link} />
              </li>
            ))}
          </ul>
        </ContentContainer>
      ) : null}

      {related.length ? (
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <List
            className="article-page__related"
            theme={List.themes.marginLarge}
          >
            {related.map(link => (
              <ImageLinkCard
                headingSize={ImageLinkCard.headingSizes.medium}
                headingTheme={ImageLinkCard.headingThemes.emphasis}
                key={link.href}
                {...link}
              />
            ))}
          </List>
        </ContentContainer>
      ) : null}
      {pdfs ? (
        <ContentContainer theme={ContentContainer.themes.medium}>
          <ul className="article-page__link-container">
            {pdfs.map(pdf => (
              <li key={pdf.href}>
                <LinkCard {...pdf} />
              </li>
            ))}
          </ul>
        </ContentContainer>
      ) : null}
    </article>
  </Layout>
);

export default ArticlePage;
