import React, { useEffect, useImperativeHandle, useRef } from 'react';

import Video from '../video/video';

import type { BackgroundVideo as Props } from './background-video.types';

export type BackgroundVideoRef = {
  pause: () => void;
  play: () => Promise<void>;
};

/** Must be rendered inside a parent which has relative positioning. */
const BackgroundVideo = React.forwardRef<BackgroundVideoRef, Props>(
  ({ onPause, onTimeUpdate, video }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      if (videoRef.current) {
        if (onPause) {
          videoRef.current.addEventListener('pause', onPause);
        }

        if (onTimeUpdate) {
          videoRef.current.addEventListener('timeupdate', onTimeUpdate);
        }

        videoRef.current.play().catch(() => {
          // NOTE: This is a noop on purpose
        });

        if (onPause || onTimeUpdate) {
          return () => {
            if (videoRef.current) {
              if (onPause) {
                videoRef.current.removeEventListener('pause', onPause);
              }

              if (onTimeUpdate) {
                videoRef.current.removeEventListener(
                  'timeupdate',
                  onTimeUpdate
                );
              }
            }
          };
        }
      }
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        pause: () => {
          if (videoRef.current) {
            videoRef.current.pause();
          }
        },
        play: () => {
          if (videoRef.current) {
            return videoRef.current.play();
          }

          return new Promise(() => {});
        },
      }),
      []
    );

    return (
      <div className="background-video">
        <Video {...video} autoplay loop muted playsInline ref={videoRef} />
      </div>
    );
  }
);

BackgroundVideo.displayName = 'BackgroundVideo';

export default BackgroundVideo;
