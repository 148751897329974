import Check from '@sats-group/icons/24/check';
import Close from '@sats-group/icons/24/close';
import React from 'react';

import { mod } from 'client/helpers/add-bem-modifiers';
import type { ThemedComponent } from 'client/types';

import type { List as Props } from './list.types';
import { themes } from './list.types';

const List: ThemedComponent<React.PropsWithChildren<Props>, typeof themes> = ({
  className,
  children,
  theme,
  ...rest
}) => (
  <ul className={[mod('list', theme), className].join(' ')} {...rest}>
    {React.Children.map(children, child => (
      <li className="list__item">
        {theme &&
        (themes.checkmarks === theme || themes.greenCheckmarks === theme) ? (
          <div className="list__checkmark">
            <Check />
          </div>
        ) : null}
        {theme &&
        (themes.redCrosses === theme || themes.blackCrosses === theme) ? (
          <div className="list__cross">
            <Close />
          </div>
        ) : null}
        {child}
      </li>
    ))}
  </ul>
);

List.themes = themes;

export default List;
