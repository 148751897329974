import React from 'react';

import ArrowLink from '../arrow-link/arrow-link';

import type { ArrowLinkGroup as Props } from './arrow-link-group.types';

const ArrowLinkGroup: React.FunctionComponent<Props> = ({ links }) => (
  <div className="arrow-link-group">
    {links.map((link, index, items) => (
      <ArrowLink
        {...link}
        key={link.href}
        groupPosition={
          items.length > 1
            ? index
              ? index === items.length - 1
                ? 'bottom'
                : 'middle'
              : 'top'
            : undefined
        }
        small
      />
    ))}
  </div>
);

export default ArrowLinkGroup;
