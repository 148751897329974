import React from 'react';

import Logo from 'client/components/logo/logo';
import { mod } from 'client/helpers/add-bem-modifiers';

import type { ProfilingLayout as Props } from './profiling-layout.types';

const ProfilingLayout: React.FC<React.PropsWithChildren<Props>> = ({
  brand,
  children,
  progress,
}) => (
  <React.Fragment>
    <div className="profiling-layout">
      <header className="profiling-layout__header">
        <Logo brand={brand} color={Logo.colors.dark} />
        {progress.length ? (
          <div className="profiling-layout__progress-wrapper">
            {progress.map((step, index) => (
              <div
                key={index}
                className={mod('profiling-layout__progress', {
                  isSelected: step,
                })}
                data-progress-at={step ? index : undefined}
              ></div>
            ))}
          </div>
        ) : null}
      </header>
      <main className="profiling-layout__main">
        <div className="profiling-layout__main-content">{children}</div>
      </main>
    </div>
  </React.Fragment>
);

export default ProfilingLayout;
