import cn from 'classnames';
import React from 'react';

import type { ActionBox as Props } from './action-box.types';
import Text from 'sats-ui-lib/react/text';
import LinkButton from 'sats-ui-lib/react/link-button';

const ActionBox: React.FunctionComponent<Props> = ({
  header,
  info,
  link,
  rawIcon,
  secondary,
}) => (
  <div className={cn('action-box', { 'action-box--illustrated': rawIcon })}>
    <div
      className={cn('action-box__header', {
        'action-box__header--illustrated': rawIcon,
      })}
    >
      {rawIcon ? (
        <div
          className="action-box__icon"
          dangerouslySetInnerHTML={{ __html: rawIcon }}
        />
      ) : null}
      <div className="action-box__text">
        <Text theme={Text.themes.emphasis}>{header}</Text>
        <Text size={Text.sizes.small}>{info}</Text>
      </div>
    </div>
    <div
      className={cn(
        'action-box__link',
        rawIcon ? 'action-box__link--bottom' : 'action-box__link--top'
      )}
    >
      <LinkButton
        {...link}
        size={LinkButton.sizes.small}
        variant={
          secondary
            ? LinkButton.variants.secondary
            : LinkButton.variants.primary
        }
        wide
      />
    </div>
  </div>
);

export default ActionBox;
