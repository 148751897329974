import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Text from 'sats-ui-lib/react/text';

import Spinner from 'components/spinner/spinner';

import type { UpcomingTrainings as Props } from './upcoming-trainings.types';

const UpcomingTrainings: React.FunctionComponent<Props> = ({
  endpoint,
  minutesText,
  trainings,
  unbookText,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <ul className="upcoming-trainings">
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      {trainings.map(training => (
        <li
          key={`${training.startTime} ${training.centerName} ${training.activityName}`}
          className="upcoming-trainings__day"
        >
          <div>
            <div>
              <Text elementName="time" theme={Text.themes.emphasis}>
                {training.startTime}
              </Text>
            </div>
            {training.duration ? (
              <div className="upcoming-trainings__duration">
                <Text elementName="time" size={Text.sizes.small}>
                  {training.duration} {minutesText}
                </Text>
              </div>
            ) : null}
          </div>
          <div className="upcoming-trainings__activity">
            <div className="upcoming-trainings__activity-texts">
              <div className="upcoming-trainings__activity-primary">
                <div className="upcoming-trainings__activity-name">
                  <Text size={Text.sizes.basic} theme={Text.themes.emphasis}>
                    {training.activityName}
                  </Text>
                </div>
                <div className="upcoming-trainings__activity-instructor">
                  <Text size={Text.sizes.small}>{training.instructor}</Text>
                </div>
              </div>
              <div className="upcoming-trainings__activity-secondary">
                <Text size={Text.sizes.small}>{training.centerName}</Text>
              </div>
            </div>

            <div className="upcoming-trainings__activity-form-container">
              <form
                method="POST"
                action={endpoint}
                className="upcoming-trainings__form"
                onSubmit={handleSubmit}
              >
                {training.hiddenInput.map(input => (
                  <HiddenInput key={input.name} {...input} />
                ))}
                <Button
                  variant="secondary"
                  text={unbookText}
                  type="submit"
                  size={Button.sizes.small}
                />
              </form>
              <div className="upcoming-trainings__member-status">
                {training.memberStatus.map(({ text, type }) => (
                  <Text
                    key={text}
                    size={Text.sizes.small}
                    theme={
                      type === 'default' ? Text.themes.emphasis : undefined
                    }
                  >
                    <span
                      className={`upcoming-trainings__member-status upcoming-trainings__member-status--type-${type}`}
                    >
                      {text}
                    </span>
                  </Text>
                ))}
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default UpcomingTrainings;
