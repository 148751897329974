import ArrowDown from '@sats-group/icons/24/arrow-down';
import ArrowRight from '@sats-group/icons/24/arrow-right';
import ArrowUp from '@sats-group/icons/24/arrow-up';
import React, { useState } from 'react';
import Collapse from 'react-tiny-collapse';

import Text from 'sats-ui-lib/react/text';

import type { SitemapNode as Props } from './sitemap-node.types';

/* eslint-disable react/no-multi-comp */
const SitemapNode: React.FunctionComponent<Props> = ({
  data = {},
  parentUrl = '',
}) => (
  <div className="web-admin-sitemap__node">
    {Object.entries(data)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([slug, children]) => {
        const url = [parentUrl, slug].join('/');

        const link =
          typeof children === 'object' && children['/'] ? (
            <a href={url}>{slug}</a>
          ) : typeof children === 'boolean' && slug !== '/' ? (
            <a href={url}>{slug}</a>
          ) : slug !== '/' ? (
            slug
          ) : null;

        const childNodes =
          typeof children === 'object' ? (
            <SitemapNode data={children} parentUrl={url} />
          ) : null;

        return link || childNodes ? (
          <ListElement header={link} key={url}>
            {childNodes}
          </ListElement>
        ) : null;
      })}
  </div>
);

const ListElement: React.FunctionComponent<
  React.PropsWithChildren<{ header: React.ReactNode }>
> = ({ children, header }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <div className="web-admin-sitemap__node-header">
        {children ? (
          <button
            className="web-admin-sitemap__node-indicator web-admin-sitemap__node-indicator--toggler"
            onClick={() => setIsExpanded(a => !a)}
          >
            {isExpanded ? <ArrowUp /> : <ArrowDown />}
          </button>
        ) : (
          <div className="web-admin-sitemap__node-indicator">
            <ArrowRight />
          </div>
        )}
        <Text onClick={() => setIsExpanded(a => !a)} size={Text.sizes.basic}>
          {header}
        </Text>
      </div>

      {children ? (
        <Collapse isOpen={isExpanded}>
          <div className="web-admin-sitemap__node-content">{children}</div>
        </Collapse>
      ) : null}
    </div>
  );
};

export default SitemapNode;
