import React from 'react';

import Link from 'sats-ui-lib/react/link';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import List from 'components/list/list';
import Price from 'components/price/price';

import ElementInterpolator from '../element-interpolator/element-interpolator';

import type { ChangeMembershipSummary as Props } from './change-membership-summary.types';

export const summaryContent: React.FunctionComponent<Props> = summary => (
  <div className="change-membership-summary__content">
    <Text
      size={Text.sizes.headline3}
      className="change-membership-summary__title"
    >
      {summary.summaryTitle}
    </Text>
    <div className="change-membership-summary__club-container">
      <Text className="change-membership-summary__club-label">
        {summary.clubLabel}
      </Text>
      <Text className="change-membership-summary__club-name">
        {summary.clubName}
      </Text>
      <div className="change-membership-summary__change-club-notice">
        <ElementInterpolator
          template={summary.changeClubNoticeTemplate}
          elements={{
            memberCareLink: <Link {...summary.memberCareLink} />,
          }}
        />
      </div>
    </div>
    <div className="change-membership-summary__membership-container">
      <Text
        className="change-membership-summary__membership-title"
        theme={Text.themes.emphasis}
      >
        {summary.currentMembership.title}
      </Text>
      <Text className="change-membership-summary__label">
        {summary.membershipOptionsLabel}
      </Text>
      <List
        className="change-membership-summary__membership-options-list"
        theme={List.themes.greenCheckmarks}
      >
        {summary.currentMembership.membershipOptions.map(option => (
          <Text key={option}>{option}</Text>
        ))}
      </List>
      {summary.membershipChanges && summary.currentMembership.price ? (
        <div className="change-membership-summary__current-membership-price">
          <Text>{summary.currentPrice}</Text>
          <Price {...summary.currentMembership.price} />
        </div>
      ) : null}
    </div>
    {summary.membershipChanges ? (
      <div className="change-membership-summary__membership-container">
        <Text
          className="change-membership-summary__membership-title"
          theme={Text.themes.emphasis}
        >
          {summary.membershipChanges.title}
        </Text>
        <Text className="change-membership-summary__label">
          {summary.membershipOptionsLabel}
        </Text>
        <List
          className="change-membership-summary__membership-options-list"
          theme={List.themes.greenCheckmarks}
        >
          {summary.membershipChanges.membershipOptions.map(option => (
            <Text key={option}>{option}</Text>
          ))}
        </List>
      </div>
    ) : null}
  </div>
);

export const summaryFooter: React.FunctionComponent<Props> = summary => (
  <div className="change-membership-summary__footer">
    <div className="change-membership-summary__container">
      <div className="change-membership-summary__price">
        <Text className="change-membership-summary__label">
          {summary.feeLabel}
        </Text>
        <Price {...summary.price} />
      </div>
      {summary.nextButton ? (
        <LinkButton
          variant={LinkButton.variants.cta}
          className="change-membership-summary__button"
          {...summary.nextButton}
        />
      ) : null}
    </div>
  </div>
);
