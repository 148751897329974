import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Link from 'sats-ui-lib/react/link';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import Spinner from 'components/spinner/spinner';

import type { AutogiroAccountsPage as Props } from './autogiro-accounts-page.types';

const AutogiroAccountsPage: React.FunctionComponent<Props> = ({
  accounts,
  action,
  appLinks,
  cancel,
  help,
  inputs,
  myPageLayout,
  qr,
  submit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <MyPageLayout {...myPageLayout}>
      <ContentContainer>
        <div className="autogiro-accounts-page">
          {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
          {help.length ? (
            <div className="autogiro-accounts-page__help">
              {help.map(h => (
                <Text key={h}>{h}</Text>
              ))}
            </div>
          ) : null}
          {qr ? (
            <div className="autogiro-accounts-page__qr">
              <div>
                <img {...qr.image} />
              </div>
              <div>
                <Text theme={Text.themes.emphasis}>{qr.help}</Text>
                {appLinks.map(link => (
                  <Link {...link} key={link.href} />
                ))}
              </div>
            </div>
          ) : (
            appLinks.map(link => <Link {...link} key={link.href} />)
          )}
          {accounts.length ? (
            <div className="autogiro-accounts-page__accounts">
              {accounts.map(account => (
                <form
                  action={action}
                  className="autogiro-accounts-page__account"
                  key={account.key}
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div>
                    {inputs.map(input => (
                      <HiddenInput {...input} key={input.name} />
                    ))}
                    {account.inputs.map(input => (
                      <HiddenInput {...input} key={input.name} />
                    ))}
                    <Text theme={Text.themes.emphasis}>{account.name}</Text>
                    {account.metadata.map(m => (
                      <Text key={m}>{m}</Text>
                    ))}
                  </div>
                  <div>
                    <Button text={submit} type="submit" />
                  </div>
                </form>
              ))}
            </div>
          ) : null}
          <LinkButton {...cancel} variant={LinkButton.variants.secondary} />
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default AutogiroAccountsPage;
