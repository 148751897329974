import type { ObjectValues } from 'client/types';

export const themes = {
  muted: 'muted',
} as const;

export type ClubLoadIndicator = {
  loadFactor?: {
    current?: number;
    max?: number;
  };
  loadTexts?: Record<string, string>;
  theme?: ObjectValues<typeof themes>;
};
