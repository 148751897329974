import React from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import NewClubCard from 'components/new-club-card/new-club-card';
import NewPriceCard from 'components/new-price-card/new-price-card';

import type { ChangeHomeClubPreviewPage as Props } from './change-home-club-preview-page.types';

const ChangeHomeClubPreviewPage: React.FunctionComponent<Props> = ({
  club,
  myPageLayout,
  next,
  previous,
  price,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="change-home-club-preview-page">
        {club ? (
          <div>
            <NewClubCard {...club} />
          </div>
        ) : null}
        {price ? (
          <div>
            <NewPriceCard {...price} />
          </div>
        ) : null}
        {next || previous ? (
          <div className="change-home-club-preview-page__actions">
            {previous ? (
              <LinkButton
                {...previous}
                variant={LinkButton.variants.secondary}
              />
            ) : null}
            {next ? (
              <form action={next.action} method="post">
                {next.inputs.map(input => (
                  <HiddenInput {...input} key={input.name} />
                ))}
                <Button
                  text={next.trigger}
                  type="submit"
                  variant={Button.variants.cta}
                />
              </form>
            ) : null}
          </div>
        ) : null}
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default ChangeHomeClubPreviewPage;
