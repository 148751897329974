import type React from 'react';

import type { ObjectValues } from 'client/types';

export const themes = {
  blackCrosses: 'black-crosses',
  checkmarks: 'checkmarks',
  greenCheckmarks: 'green-checkmarks',
  grid: 'grid',
  gridLarge: 'grid-large', // Bigger grid cells
  gridSmall: 'grid-small', // Smaller grid cells, less space between
  horizontal: 'horizontal',
  horizontalDots: 'horizontal-dots',
  marginLarge: 'margin-large',
  marginMedium: 'margin-medium',
  noMargin: 'no-margin',
  redCrosses: 'red-crosses',
} as const;

export type List = {
  className?: string;
  children?: React.ReactNode;
  theme?: ObjectValues<typeof themes>;
};
