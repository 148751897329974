import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ProfilingLayout from 'client/components/profiling-layout/profiling-layout';

import type { ProfilingQuestionnaireConfirmationPage as Props } from './profiling-questionnaire-confirmation-page.types';

const ProfilingQuestionnaireConfirmationPage: React.FunctionComponent<
  Props
> = ({ description, layout, title }) => (
  <ProfilingLayout {...layout}>
    <div className="profiling-questionnaire-confirmation-page">
      <div className="profiling-questionnaire-confirmation-page__title">
        <Text elementName="h1" size={Text.sizes.headline2}>
          {title}
        </Text>
      </div>

      <div className="profiling-questionnaire-confirmation-page__description">
        <Text>{description}</Text>
      </div>
    </div>
  </ProfilingLayout>
);

export default ProfilingQuestionnaireConfirmationPage;
