import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'client/components/content-container/content-container';
import ImageLink from 'client/components/image-link/image-link';
import Layout from 'client/components/layout/layout';
import RichText from 'client/components/rich-text/rich-text';

import type { AboutSatsPage as Props } from './about-sats-page.types';

const AboutSatsPage: React.FunctionComponent<Props> = ({
  body,
  layout,
  links,
  title,
}) => (
  <Layout {...layout}>
    <div className="about-sats-page">
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <Text
          elementName="h1"
          className="about-sats-page__title"
          size={Text.sizes.headline2}
          theme={Text.themes.headline}
          italic
        >
          {title}
        </Text>
        <RichText {...body} />
      </ContentContainer>
      <ContentContainer>
        {links.length ? (
          <ul className="about-sats-page__links">
            {links.map(link => (
              <li key={link.href}>
                <ImageLink {...link} />
              </li>
            ))}
          </ul>
        ) : null}
      </ContentContainer>
    </div>
  </Layout>
);

export default AboutSatsPage;
