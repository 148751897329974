import cn from 'classnames';
import React from 'react';

import Banner from 'sats-ui-lib/react/banner';
import Expander from 'sats-ui-lib/react/expander';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import ContentContainer from '../content-container/content-container';
import MemberCareCard from '../member-care-card/member-care-card';
import MyPageFooter from '../my-page-footer/my-page-footer';
import MyPageMenu from '../my-page-menu/my-page-menu';
import RichText from '../rich-text/rich-text';

import type { MyPageLayout as Props } from './my-page-layout.types';

const MyPageLayout: React.FC<React.PropsWithChildren<Props>> = ({
  banners,
  breadcrumbs,
  children,
  footer,
  highlights,
  light,
  memberCare,
  menu,
  messages,
  title,
}) => (
  <div className="my-page-layout">
    <header>
      <MyPageMenu {...menu} />
      {breadcrumbs ? (
        <Breadcrumbs {...breadcrumbs} width={ContentContainer.themes.default} />
      ) : null}
    </header>
    <main className="my-page-layout__main">
      <div
        className={cn('my-page-layout__top', {
          'my-page-layout__top--light': light,
        })}
      >
        <ContentContainer>
          <Text
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.headline}
          >
            {title}
          </Text>
        </ContentContainer>
        {messages.length ? (
          <ContentContainer>
            <div className="my-page-layout__messages">
              {messages.map(message => (
                <div key={message.text}>
                  <Message {...message} inline />
                </div>
              ))}
            </div>
          </ContentContainer>
        ) : null}
        {banners.length ? (
          <ContentContainer>
            <div className="my-page-layout__messages">
              {banners.map(banner => (
                <div key={banner.text}>
                  <Banner {...banner} icon />
                </div>
              ))}
            </div>
          </ContentContainer>
        ) : null}
      </div>
      <div className="my-page-layout__main-content">{children}</div>
    </main>
    {highlights || memberCare ? (
      <aside className="my-page-layout__member-care">
        {highlights ? (
          <ContentContainer>
            <Expander {...highlights} itemRenderer={RichText} />
          </ContentContainer>
        ) : null}
        {memberCare ? (
          <ContentContainer>
            <MemberCareCard {...memberCare} />
          </ContentContainer>
        ) : null}
      </aside>
    ) : null}
    <footer>
      <MyPageFooter {...footer} />
    </footer>
  </div>
);

export default MyPageLayout;
