import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';
import Spinner from 'components/spinner/spinner';

import type { ResetPasswordPage as Props } from './reset-password-page.types';

const ResetPasswordPage: React.FC<Props> = ({
  description,
  endpoint,
  hiddenInputs,
  inputs,
  layout,
  messages,
  resetButtonText,
  title,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <Layout {...layout}>
      <ContentContainer theme={ContentContainer.themes.veryNarrow}>
        <div className="reset-password-page">
          {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
          <Text
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.normal}
          >
            {title}
          </Text>
          {messages.length ? (
            <div className="reset-password-page__messages">
              {messages.map(message => (
                <Message {...message} inline key={message.text} />
              ))}
            </div>
          ) : null}
          {inputs.length ? (
            <form
              action={endpoint}
              className="reset-password-page__form"
              method="post"
              onSubmit={handleSubmit}
            >
              <Text>{description}</Text>
              {hiddenInputs.map(input => (
                <HiddenInput {...input} key={input.name} />
              ))}
              {inputs.map(input => (
                <div key={input.name} className="reset-password-page__input">
                  <TextInput {...input} />
                </div>
              ))}
              <Button
                disabled={isSubmitting}
                text={resetButtonText}
                type="submit"
                wide
              />
            </form>
          ) : null}
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default ResetPasswordPage;
