import React from 'react';

import Text from 'sats-ui-lib/react/text';

import type { PreCorporatePrice as Props } from './pre-corporate-price.types';

const PreCorporatePrice: React.FunctionComponent<Props> = ({
  title,
  description,
  price,
  priceLabel,
}) => (
  <div className="pre-corporate-price">
    <div className="pre-corporate-price__content">
      <Text theme={Text.themes.headline} italic>
        {title}
      </Text>
      <Text size={Text.sizes.small}>{description}</Text>
      <div className="pre-corporate-price__price">
        <Text size={Text.sizes.small}>{priceLabel}</Text>
        <div className="pre-corporate-price__amount">
          <Text size={Text.sizes.headline3} theme={Text.themes.headline} italic>
            {price.amount}
          </Text>
          {price.currency || price.period ? (
            <Text size={Text.sizes.small}>
              {price.currency && price.period ? (
                <span>
                  {price.currency} {price.period}
                </span>
              ) : price.currency && !price.period ? (
                price.currency
              ) : !price.currency && price.period ? (
                price.period
              ) : null}
            </Text>
          ) : null}
        </div>
      </div>
    </div>
  </div>
);

export default PreCorporatePrice;
