import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import ProfilingLayout from 'components/profiling-layout/profiling-layout';
import RadioList from 'components/radio-list/radio-list';
import Spinner from 'components/spinner/spinner';

import type { ProfilingQuestionnairePage as Props } from './profiling-questionnaire-page.types';

const ProfilingQuestionnairePage: React.FunctionComponent<Props> = ({
  back,
  description,
  endpoint,
  hiddenInputs = [],
  layout,
  messages,
  next,
  multipleChoice = [],
  singleChoice,
  skip,
  title,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <ProfilingLayout {...layout}>
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      <div className="profiling-questionnaire-page">
        <Text
          elementName="h1"
          size={Text.sizes.headline2}
          className="profiling-questionnaire-page__heading"
        >
          {title}
        </Text>
        {messages.length ? (
          <div className="profiling-questionnaire-page__messages">
            {messages.map(message => (
              <Message key={message.text} {...message} inline />
            ))}
          </div>
        ) : null}
        {description ? (
          <Text className="profiling-questionnaire-page__description">
            {description}
          </Text>
        ) : null}

        <form
          action={endpoint}
          className="profiling-questionnaire-page__form"
          method="post"
          onSubmit={handleSubmit}
        >
          {hiddenInputs.map(input => (
            <HiddenInput key={input.value} {...input} />
          ))}

          {multipleChoice.length ? (
            <div className="profiling-questionnaire-page__options">
              {multipleChoice.map((choice, index) => (
                <div key={index} className="profiling-questionnaire-page__item">
                  <Checkbox {...choice} />
                </div>
              ))}
            </div>
          ) : null}

          {singleChoice ? (
            <div className="profiling-questionnaire-page__options">
              <RadioList {...singleChoice} />
            </div>
          ) : null}

          <div className="profiling-questionnaire-page__actions">
            <div>
              <Button
                type="submit"
                text={next}
                variant={Button.variants.primary}
                wide
              />
            </div>
          </div>
        </form>
        <form
          action={endpoint}
          className="profiling-questionnaire-page__form"
          method="post"
          onSubmit={handleSubmit}
        >
          {hiddenInputs.map(input => (
            <HiddenInput key={input.value} {...input} />
          ))}
          <HiddenInput name="skip" value="true" />
          <div className="profiling-questionnaire-page__actions">
            <div>
              <Button
                type="submit"
                text={skip}
                variant={Button.variants.secondary}
                wide
              />
            </div>
            {back ? (
              <div>
                <LinkButton
                  {...back}
                  variant={LinkButton.variants.secondary}
                  wide
                />
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </ProfilingLayout>
  );
};

export default ProfilingQuestionnairePage;
