import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';

import type { NotFoundPage as Props } from './not-found-page.types';

const NotFoundPage: React.FunctionComponent<Props> = ({ layout, title }) => (
  <Layout {...layout}>
    <div className="not-found-page">
      <ContentContainer>
        <div className="not-found-page__title">
          <Text
            elementName="h1"
            size={Text.sizes.headline2}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
        </div>
      </ContentContainer>
    </div>
  </Layout>
);

export default NotFoundPage;
