import React, { useEffect, useRef } from 'react';

import Text from 'sats-ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';
import isNumber from 'client/helpers/is-number';

import type { DateItem as Props } from './date-item.types';

const DateItem: React.FunctionComponent<Props> = ({
  date,
  day,
  dayLabels,
  dayWithLabel,
  disabled,
  extraData,
  isCurrent,
  isoDate,
  isSelected,
  onClick = () => {},
  shouldScrollToCurrent,
}) => {
  const node = useRef<HTMLElement>();

  useEffect(() => {
    if (shouldScrollToCurrent && isCurrent && node.current) {
      node.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, []);

  const dayName =
    isNumber(day) && dayLabels
      ? (dayLabels[day] ?? dayWithLabel)
      : dayWithLabel;

  return React.createElement(
    disabled ? 'div' : 'button',
    {
      className: mod('date-list__date', {
        inactive: disabled,
      }),
      onClick: disabled ? undefined : () => onClick(isoDate, extraData),
      ref: node,
      ['data-test-active-date']: !disabled,
    },
    <time
      className={mod('date-list__time', {
        inactive: disabled,
      })}
      dateTime={isoDate}
    >
      <Text
        className="date-list__day-name"
        elementName="div"
        size={Text.sizes.basic}
      >
        {dayName}
      </Text>
      <Text
        className={mod('date-list__day', {
          selected: isSelected,
          inactive: disabled,
        })}
        elementName="div"
        size={Text.sizes.small}
      >
        {date}
      </Text>
    </time>
  );
};

export default DateItem;
