import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Link from 'sats-ui-lib/react/link';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import Spinner from 'components/spinner/spinner';

import type { MyCommunicationSettingsPage as Props } from './my-communication-settings-page.types';

const MyCommunicationSettingsPage: React.FunctionComponent<Props> = ({
  endpoint,
  hiddenInputs,
  mandatoryOptions,
  myPageLayout,
  options,
  submitText,
  text,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    if (isLoading) {
      e.preventDefault();
    }

    setIsLoading(true);
  };

  return (
    <MyPageLayout {...myPageLayout}>
      <ContentContainer>
        <div className="my-communication-settings-page">
          {isLoading ? <Spinner theme={Spinner.themes.overlaySticky} /> : null}
          {text.map(text => (
            <Text key={text}>{text}</Text>
          ))}
          {mandatoryOptions.map(({ checkbox, description }) => (
            <div
              className="my-communication-settings-page__option"
              key={checkbox.name}
            >
              <Checkbox {...checkbox} checked disabled />
              <Text
                className="my-communication-settings-page__option-part"
                size={Text.sizes.small}
              >
                {description}
              </Text>
            </div>
          ))}
          <form
            action={endpoint}
            className="my-communication-settings-page__form"
            method="POST"
            onSubmit={handleSubmit}
          >
            {hiddenInputs.map(input => (
              <HiddenInput {...input} key={input.name} />
            ))}
            {options.map(({ checkbox, description, terms }) => (
              <div
                className="my-communication-settings-page__option"
                key={checkbox.name}
              >
                <Checkbox {...checkbox} />
                <Text
                  className="my-communication-settings-page__option-part"
                  size={Text.sizes.small}
                >
                  {description}
                </Text>
                {terms ? (
                  <div className="my-communication-settings-page__option-part">
                    <Link {...terms} />
                  </div>
                ) : null}
              </div>
            ))}
            <div>
              <Button type="submit" text={submitText} />
            </div>
          </form>
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default MyCommunicationSettingsPage;
