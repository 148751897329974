import React from 'react';

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.928 1 1 5.928 1 12C1 18.072 5.928 23 12 23C18.072 23 23 18.072 23 12C23 5.928 18.072 1 12 1ZM2.30231 12C2.30231 17.358 6.64202 21.6977 12 21.6977C17.358 21.6977 21.6977 17.358 21.6977 12C21.6977 6.64202 17.358 2.30231 12 2.30231C6.64202 2.30231 2.30231 6.64202 2.30231 12Z"
      fill="currentColor"
    />
  </svg>
);
