import React from 'react';
import { useIsMounted } from 'sats-ui-lib/react/hooks/use-is-mounted';

import { mod } from 'client/helpers/add-bem-modifiers';
import ClubLoadIndicator from 'components/club-load-indicator/club-load-indicator';
import OpenStatus from 'components/open-status/open-status';

import type { LoadAndOpenStatus as Props } from './load-and-open-status.types';

const LoadAndOpenStatus: React.FunctionComponent<Props> = ({
  loadFactor,
  loadIndicator,
  openStatus,
}) => {
  const isMounted = useIsMounted();
  const isOpen = openStatus?.isOpen;

  return isMounted ? (
    <div className="load-and-open-status">
      {isOpen && loadFactor ? (
        <div className="load-and-open-status__item">
          <ClubLoadIndicator loadFactor={loadFactor} {...loadIndicator} />
        </div>
      ) : null}
      {openStatus ? (
        <div
          className={mod('load-and-open-status__item', {
            closed: !openStatus.isOpen,
          })}
        >
          <OpenStatus shouldShowStatus={!openStatus.isOpen} {...openStatus} />
        </div>
      ) : null}
    </div>
  ) : null;
};

export default LoadAndOpenStatus;
