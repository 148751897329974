// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function debounce<T extends any[]>(
  callback: (...args: T) => void,
  wait: number
) {
  let timerRef: number;

  return (...args: T) => {
    clearTimeout(timerRef);
    timerRef = Number(setTimeout(() => callback(...args), wait));
  };
}
