import type { CroppedImage } from 'sats-ui-lib/react/cropped-image/cropped-image.types';

import type { ObjectValues } from 'client/types';

export const themes = {
  rounded: 'rounded',
  round: 'round',
} as const;

export type DynamicImage = CroppedImage & {
  defaultHeight?: number;
  defaultWidth?: number;
  theme?: ObjectValues<typeof themes>;
};
