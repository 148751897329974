import type { ObjectValues } from 'client/types';
import type { Price } from 'components/price/price.types';

export const themes = {
  full: 'full',
  simple: 'simple',
} as const;

export type MembershipCard = {
  details?: string;
  hideCheckmark?: boolean;
  /** NOTE: Used outside of this component */
  id: string;
  isMultiSelect?: boolean;
  isSelected?: boolean;
  price?: Price;
  tag?: string;
  tagline?: string;
  theme?: ObjectValues<typeof themes>;
  title: string;
};
