import React from 'react';

import Text from 'sats-ui-lib/react/text';
import VisuallyButton from 'sats-ui-lib/react/visually-button';

import type { CampaignExpiredMessage as Props } from './campaign-expired-message.types';

const CampaignExpiredMessage: React.FunctionComponent<Props> = ({
  header,
  description,
  text,
  button,
}) => (
  <div className="campaign-expired-message">
    <Text elementName="h1" size={Text.sizes.headline1}>
      {header}
    </Text>
    {description ? <Text size={Text.sizes.large}>{description}</Text> : null}
    <Text size={Text.sizes.large}>{text}</Text>
    <VisuallyButton
      elementName="a"
      variant={VisuallyButton.variants.cta}
      {...button}
    />
  </div>
);

export default CampaignExpiredMessage;
