import React from 'react';

import Text from 'sats-ui-lib/react/text';

import DynamicImage from 'components/dynamic-image/dynamic-image';

import type { ImageLink as Props } from './image-link.types';

const ImageLink: React.FunctionComponent<Props> = ({ href, image, text }) => (
  <a className="image-link" href={href}>
    {image ? <DynamicImage className="image-link__image" {...image} /> : null}
    <Text
      className="image-link__text"
      theme={Text.themes.emphasis}
      size={Text.sizes.large}
    >
      {text}
    </Text>
  </a>
);

export default ImageLink;
