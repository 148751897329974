import React from 'react';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';
import type { PtShopError as Props } from './pt-shop-error.types';

const PtShopError: React.FunctionComponent<Props> = ({ layout }) => (
  <FlowTerminusLayout {...layout} />
);

export default PtShopError;
