import React from 'react';

import Text from 'sats-ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';

import type { Tagline as Props } from './tagline.types';
import { themes } from './tagline.types';

const Tagline: React.FC<Props> & { themes: typeof themes } = ({
  theme,
  words,
}) => (
  <div className={[mod('tagline', theme)].join(' ')}>
    {words.map((word, index) => (
      <Text key={word + index} size={Text.sizes.large} theme="headline">
        {word}
      </Text>
    ))}
  </div>
);

Tagline.themes = themes;

export default Tagline;
