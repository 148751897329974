import Comment from '@sats-group/icons/18/comment';
import cn from 'classnames';
import React from 'react';

import Text from 'sats-ui-lib/react/text';

import type { ChatButton as Props } from './chat-button.types';

const ChatButton: React.FunctionComponent<Props> = ({
  disabled,
  edge,
  onClick,
  text,
}) => (
  <button
    className={cn('chat-button', {
      'chat-button--edge': edge,
    })}
    disabled={disabled}
    onClick={onClick}
  >
    <Text>{text}</Text>
    <Comment />
  </button>
);

export default ChatButton;
