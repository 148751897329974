import type { DependencyList } from 'react';
import { useEffect } from 'react';

// NOTE: `useEffect` does not allow async functions.
export default function useAsyncEffect(
  asyncFunc: () => Promise<void>,
  dependencies: DependencyList
): void {
  useEffect(() => {
    (async function () {
      asyncFunc();
    })();
  }, dependencies);
}
