import ChevronUp from '@sats-group/icons/24/arrow-up';
import React from 'react';
import Collapse from 'react-tiny-collapse';

import useToggle from 'sats-ui-lib/react/hooks/use-toggle';
import Text from 'sats-ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';

import type { TrackOrSticky as Props } from './track-or-sticky.types';

const TrackOrSticky: React.FunctionComponent<Props> = ({
  content,
  footer,
  title,
}) => {
  const [isOpen, toggle] = useToggle(false);

  const collapseProps = {
    className: 'track-or-sticky__collapse',
    isOpen,
  };
  return (
    <div className="track-or-sticky">
      <div className="track-or-sticky__content">
        <button
          className="track-or-sticky__content-trigger"
          onClick={toggle}
          type="button"
        >
          <Text
            elementName="h2"
            size={Text.sizes.headline3}
            theme={Text.themes.normal}
          >
            {title}
          </Text>
          <div
            className={mod('track-or-sticky__content-trigger-icon', {
              flipped: isOpen,
            })}
          >
            <ChevronUp />
          </div>
        </button>
        <Collapse {...collapseProps}>
          <div>{content}</div>
        </Collapse>

        {footer ? (
          <div className="track-or-sticky__footer">{footer}</div>
        ) : null}
      </div>
    </div>
  );
};

export default TrackOrSticky;
