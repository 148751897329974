import SvgCreditcard from '@sats-group/icons/32/creditcard';
import SvgInvoice from '@sats-group/icons/32/invoice';
import React from 'react';

import Text from 'sats-ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';

import type { Icon, PaymentSection as Props } from './payment-section.types';

const icons: Record<Icon, React.FunctionComponent> = {
  creditCard: SvgCreditcard,
  invoice: SvgInvoice,
};

const PaymentSection: React.FunctionComponent<Props> = ({
  description,
  heading,
  icon,
  id,
  isDisabled,
  isSelected,
  onExpandSection = () => {},
}) => (
  <button
    className={mod('personal-trainer-session-payment-page__payment-section', {
      active: !isDisabled,
      selected: isSelected && !isDisabled,
    })}
    onClick={() => {
      if (isSelected) {
        onExpandSection(undefined);
      } else {
        onExpandSection(id);
      }
    }}
    data-test-id={id}
    disabled={isDisabled}
    aria-expanded={isSelected}
    aria-controls={id}
  >
    {React.createElement(icons[icon], {})}
    <Text elementName="h3" size={Text.sizes.basic} theme={Text.themes.emphasis}>
      {heading}
    </Text>
    <Text
      size={Text.sizes.small}
      className="personal-trainer-session-payment-page__payment-section-description"
    >
      {description}
    </Text>
  </button>
);

export default PaymentSection;
