import React from 'react';

import Text from 'sats-ui-lib/react/text';

import AccordionItem from 'components/accordion/accordion-item';
import PersonalTrainerSessionShopLayout from 'components/personal-trainer-session-shop-layout/personal-trainer-session-shop-layout';

import type { PersonalTrainerSessionShopConfirmationPage as Props } from './personal-trainer-session-shop-confirmation-page.types';

const PersonalTrainerSessionShopConfirmationPage: React.FunctionComponent<
  Props
> = ({
  confirmation,
  layout,
  summaryAccordion,
  summarySections,
  title,
  withdrawal,
  withdrawalTitle,
}) => (
  <PersonalTrainerSessionShopLayout {...layout}>
    <div className="personal-trainer-session-shop-confirmation-page">
      <Text
        elementName="h2"
        theme={Text.themes.headline}
        italic
        size={Text.sizes.headline2}
        className="personal-trainer-session-shop-confirmation-page__title"
      >
        {title}
      </Text>
      <div className="personal-trainer-session-shop-confirmation-page__confirmation">
        {confirmation}
      </div>
      <div className="personal-trainer-session-shop-confirmation-page__accordion">
        <AccordionItem {...summaryAccordion}>
          <div className="personal-trainer-session-shop-confirmation-page__summary">
            <div className="personal-trainer-session-shop-confirmation-page__summary-section">
              {summarySections.map(({ title, lines }) => (
                <React.Fragment key={title}>
                  <Text
                    elementName="h4"
                    theme={Text.themes.emphasis}
                    size={Text.sizes.headline2}
                  >
                    {title}
                  </Text>
                  <dl>
                    {lines.map(({ name, value }) => (
                      <div
                        className="personal-trainer-session-shop-confirmation-page__summary-line"
                        key={name}
                      >
                        <Text elementName="dt" size={Text.sizes.small}>
                          {name}
                        </Text>
                        <Text elementName="dd" size={Text.sizes.small}>
                          {value}
                        </Text>
                      </div>
                    ))}
                  </dl>
                </React.Fragment>
              ))}
            </div>
          </div>
        </AccordionItem>
      </div>
      <Text
        theme={Text.themes.emphasis}
        className="personal-trainer-session-shop-confirmation-page__withdrawal"
      >
        {withdrawalTitle}
      </Text>
      <Text>{withdrawal}</Text>
    </div>
  </PersonalTrainerSessionShopLayout>
);

export default PersonalTrainerSessionShopConfirmationPage;
