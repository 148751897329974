import React from 'react';

import Text from 'sats-ui-lib/react/text';

import CleanLink from 'client/components/clean-link/clean-link';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import RichText from 'components/rich-text/rich-text';

import type { CancellationAlternativeConfirmationPage as Props } from './cancellation-alternative-confirmation-page.types';

const CancellationAlternativeConfirmationPage: React.FunctionComponent<
  Props
> = ({
  frontpageLink,
  information,
  myPageLayout,
  registeredAlternative,
  thankYou,
  title,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div
        className="cancellation-alternative-confirmation-page"
        data-test-confirmation
      >
        <Text
          className="cancellation-alternative-confirmation-page__title"
          elementName="h1"
          size={Text.sizes.headline2}
          theme={Text.themes.normal}
        >
          {title}
        </Text>
        <Text
          theme={Text.themes.emphasis}
          size={Text.sizes.large}
          className="cancellation-alternative-confirmation-page__text"
        >
          {registeredAlternative}
        </Text>
        <Text className="cancellation-alternative-confirmation-page__text">
          {thankYou}
        </Text>
        <RichText {...information} />
        <CleanLink
          className="cancellation-alternative-confirmation-page__frontpage-link"
          theme={CleanLink.themes.orange}
          {...frontpageLink}
        />
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default CancellationAlternativeConfirmationPage;
