import React from 'react';

import type { CheckoutSummaryWrapper as Props } from './checkout-summary-wrapper.types';

const CheckoutSummaryWrapper: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children }) => (
  <div className="checkout-summary-wrapper">{children}</div>
);

export default CheckoutSummaryWrapper;
