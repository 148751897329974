import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ArrowLinkGroup from 'components/arrow-link-group/arrow-link-group';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';

import type { TermsAndConditionsListPage as Props } from './terms-and-conditions-list-page.types';

const TermsAndConditionsListPage: React.FunctionComponent<Props> = ({
  layout,
  links,
  secondaryLinks,
  title,
}) => (
  <Layout {...layout}>
    <ContentContainer>
      <div className="terms-and-conditions-list-page">
        <div className="terms-and-conditions-list-page__group">
          <Text
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
          <ArrowLinkGroup {...links} />
        </div>
        {secondaryLinks ? (
          <div className="terms-and-conditions-list-page__group">
            <Text
              elementName="h2"
              size={Text.sizes.headline2}
              theme={Text.themes.emphasis}
            >
              {secondaryLinks.title}
            </Text>
            <ArrowLinkGroup {...secondaryLinks.links} />
          </div>
        ) : null}
      </div>
    </ContentContainer>
  </Layout>
);

export default TermsAndConditionsListPage;
