import React from 'react';

import Text from 'sats-ui-lib/react/text';

import CleanLink from 'client/components/clean-link/clean-link';
import ContentContainer from 'components/content-container/content-container';
import Logo from 'components/logo/logo';

import type { ErrorPage as Props } from './error-page.types';

const ErrorPage: React.FunctionComponent<Props> = ({
  debug,
  links,
  logo,
  text,
  title,
}) => (
  <main className="error-page">
    <ContentContainer
      className="error-page__content"
      theme={ContentContainer.themes.narrow}
    >
      <div className="error-page__logo">
        <Logo {...logo} />
      </div>
      <Text
        className="error-page__title"
        elementName="h1"
        size={Text.sizes.headline2}
        theme={Text.themes.headline}
        italic
      >
        {title}
      </Text>
      <Text className="error-page__text" size={Text.sizes.large}>
        {text}
      </Text>
      <ul className="error-page__links">
        {links.map(link => (
          <li key={link.href}>
            <CleanLink {...link} />
          </li>
        ))}
      </ul>
      <pre className="error-page__debug">{debug}</pre>
    </ContentContainer>
  </main>
);

export default ErrorPage;
