import type FuzzySearch from 'fuzzy-search';

import type { Button } from 'sats-ui-lib/react/button/button.types';
import type { Select } from 'sats-ui-lib/react/select/select.types';

import type { Breadcrumbs } from 'client/components/breadcrumbs/breadcrumbs.types';
import type { DropdownWrapper } from 'client/components/dropdown-wrapper/dropdown-wrapper.types';
import type { DynamicImage } from 'client/components/dynamic-image/dynamic-image.types';
import type { Layout } from 'client/components/layout/layout.types';
import type { ObjectValues } from 'client/types';

export const descriptionThemes = {
  column: 'column',
} as const;

export type Profile = {
  actualLevel?: string; // NOTE: Only used in enricher for filtering
  availability?: {
    day: string;
    times: string[];
  }[]; // NOTE: Only used in enricher for filtering
  club?: string; // NOTE: Only used for sorting
  goals?: {
    title: string;
    id: string;
  }[]; // NOTE: Only used in enricher for filtering
  expertiseForFilter?: { name: string; id: string }[];
  clubId?: number; // NOTE: Only used in enricher for filtering
  clusterName?: string; // NOTE: Only used in enricher for filtering
  image?: DynamicImage;
  level?: string; // NOTE: Only used for sorting
  description?: string[];
  metadata: string[];
  name: string;
  position?: string; // NOTE: Only used for sorting
  url: string;
  selectedFilters: string[];
};

export enum Sort {
  Level = 'level',
  Name = 'name',
  Club = 'club',
  Position = 'position',
}

export type ProfileFilterPage = {
  breadcrumbs?: Breadcrumbs;
  defaultItemsToShow?: number;
  descriptionTheme?: ObjectValues<typeof descriptionThemes>;
  dropdownWrapper: DropdownWrapper;
  layout: Layout;
  profiles?: Profile[];
  searchOptions: {
    options: FuzzySearch.Options;
    searchKey: string[];
  };
  results: string;
  selectedFiltersLabel: string;
  showMoreButton: Button;
  sort?: Select;
  title: string;
};
