import React from 'react';

import NavigationCard from 'client/components/navigation-card/navigation-card';

import type { MemberCareCategoryGroup as Props } from './member-care-category-group.types';

const MemberCareCategoryGroup: React.FunctionComponent<Props> = ({
  categories,
}) => (
  <div className="member-care-category-group">
    {categories.map(category => (
      <div key={category.title}>
        <NavigationCard {...category} tall />
      </div>
    ))}
  </div>
);

export default MemberCareCategoryGroup;
