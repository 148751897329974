import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import NewClubCard from 'components/new-club-card/new-club-card';

import type { ChangeHomeClubConfirmationPage as Props } from './change-home-club-confirmation-page.types';
import Banner from 'sats-ui-lib/react/banner';

const ChangeHomeClubConfirmationPage: React.FunctionComponent<Props> = ({
  banners,
  club,
  myPageLayout,
  next,
  texts,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="change-home-club-confirmation-page">
        {texts.length ? (
          <div className="change-home-club-confirmation-page__texts">
            {texts.map(text => (
              <Text elementName="p" key={text}>
                {text}
              </Text>
            ))}
          </div>
        ) : null}
        {club ? (
          <div>
            <NewClubCard {...club} />
          </div>
        ) : null}
        {banners.length ? (
          <div className="change-home-club-confirmation-page__banners">
            {banners.map(banner => (
              <Banner {...banner} key={banner.text} />
            ))}
          </div>
        ) : null}
        <div className="change-home-club-confirmation-page__actions">
          <LinkButton
            {...next}
            size={LinkButton.sizes.basic}
            variant={LinkButton.variants.secondary}
          />
        </div>
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default ChangeHomeClubConfirmationPage;
