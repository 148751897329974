import Close from '@sats-group/icons/24/close';
import Crosshairs from '@sats-group/icons/24/geoposition';
import Location from '@sats-group/icons/24/location';
import Search from '@sats-group/icons/24/search';
import React, { useEffect, useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Text from 'sats-ui-lib/react/text';

import { replaceQueryParameters } from 'shared/replace-query-parameters';

import { get } from 'client/helpers/api-helper';
import debounce from 'client/helpers/debounce';
import { publish } from 'client/helpers/messages';
import ClubCard from 'components/club-card/club-card';
import type { ClubCard as ClubCardProps } from 'components/club-card/club-card.types';
import List from 'components/list/list';
import Spinner from 'components/spinner/spinner';
import useUrlState from 'hooks/use-url-state';

import type { ClubSearch as Props } from './club-search.types';

enum Mode {
  All = 'all',
  GeoLocation = 'geo-location',
  Region = 'region',
  Search = 'search',
}

const ClubSearch: React.FunctionComponent<Props> = ({
  allClubsButtonLabel,
  allClubsStateLabel,
  customQuery,
  endpoint,
  genericError,
  geoLocationButtonText,
  noResultsText,
  prefilledClubSearch,
  prefilledRegionId,
  regions,
  regionsTitle,
  resetButtonText,
  searchButtonText,
  searchPlaceholder,
}) => {
  const [query, setQuery] = useUrlState();

  const [clubs, setClubs] = useState<ClubCardProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [didSearch, setDidSearch] = useState(false);

  // NOTE: These names are extra verbose since they'll be stuck in the URL until the end of the checkout and there should be no confusion as to what view they belong to
  let { clubSearchMode, clubSearchRegion } = query;

  if (prefilledRegionId) {
    clubSearchRegion = prefilledRegionId;
    clubSearchMode = Mode.Region;
  }

  const mode = Object.values(Mode).includes(clubSearchMode as Mode)
    ? (clubSearchMode as Mode)
    : Mode.Search;
  const clubSearchTerm = String(
    query.clubSearchTerm || prefilledClubSearch || ''
  );

  const setMode = (clubSearchMode: Mode) => setQuery({ clubSearchMode });
  const setSearchTerm = (clubSearchTerm: string) =>
    setQuery({ clubSearchTerm });
  const setRegion = (clubSearchRegion: string) =>
    setQuery({ clubSearchRegion });

  const enableRegion = (region: string) => {
    setMode(Mode.Region);
    setRegion(region);
  };

  const search = (
    searchParameters: Record<string, boolean | number | string | undefined>
  ) => {
    setIsLoading(true);
    setDidSearch(true);
    //@ts-ignore
    return get<{ cards: Parameters<typeof setClubs>[0] }>(
      replaceQueryParameters(endpoint, {
        ...query,
        ...searchParameters,
        ...customQuery,
      })
    )
      .then(data => setClubs(data.cards))
      .catch(() => {
        publish({ text: genericError, theme: 'error' });
      })
      .finally(() => setIsLoading(false));
  };

  const handleReset = () => {
    setSearchTerm('');
    setRegion('');
    setClubs([]);
    setMode(Mode.Search);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    search({ clubSearchTerm });
  };

  useEffect(() => {
    switch (mode) {
      case Mode.All: {
        search({ showAllClubs: true });
        break;
      }
      case Mode.Search: {
        if (clubSearchTerm.length > 0) {
          search({ clubSearchTerm });
        } else {
          setClubs([]);
        }

        break;
      }
      case Mode.Region: {
        search({
          clubSearchRegion: clubSearchRegion
            ? String(clubSearchRegion)
            : undefined,
        });
        break;
      }
      case Mode.GeoLocation: {
        setClubs([]);
        setIsLoading(true); // NOTE: Finding the user's position sometimes takes a while
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            search({ latitude, longitude });
          },
          () => {
            // NOTE: We should probably show a message, letting the user know that geolocation failed
            handleReset();
            setIsLoading(false);
          }
        );
        break;
      }
    }
  }, [mode, clubSearchTerm, clubSearchRegion]);

  const showOptions =
    mode !== Mode.GeoLocation &&
    mode !== Mode.Region &&
    clubSearchTerm !== prefilledClubSearch &&
    !clubSearchTerm &&
    !isLoading;

  return (
    <div className="club-search">
      <div className="club-search__search">
        <form
          action="" // NOTE: The form needs an `action` in order for a search button to show in the iOS keyboard
          className="club-search__content"
          onReset={handleReset}
          onSubmit={handleSubmit}
          role="search"
        >
          <div className="club-search__search-icon">
            <Search />
          </div>

          {mode === Mode.Search ? (
            <div className="club-search__form">
              <input
                className="club-search__input"
                defaultValue={clubSearchTerm}
                onInput={debounce(
                  e => setSearchTerm((e.target as HTMLInputElement).value),
                  300
                )}
                placeholder={searchPlaceholder}
                type="search"
              />
              <Button
                className="club-search__submit"
                text={searchButtonText}
                type="submit"
                variant={Button.variants.cta}
                size={Button.sizes.small}
              />
            </div>
          ) : (
            <React.Fragment>
              <div className="club-search__state-label">
                {mode === Mode.All ? allClubsStateLabel : null}
                {mode === Mode.GeoLocation ? geoLocationButtonText : null}
                {mode === Mode.Region
                  ? regions.find(({ id }) => id === clubSearchRegion)?.text
                  : null}
              </div>
              <button
                className="club-search__cancel"
                title={resetButtonText}
                type="reset"
              >
                <Close />
              </button>
            </React.Fragment>
          )}
        </form>

        {showOptions ? (
          <div className="club-search__options">
            <button
              className="club-search__option-button"
              onClick={() => setMode(Mode.GeoLocation)}
            >
              <Crosshairs />
              {geoLocationButtonText}
            </button>

            {regions.length ? (
              <div className="club-search__option-group">
                <Text
                  className="club-search__option-group-title"
                  id="club-search-regions-label"
                  size={Text.sizes.small}
                >
                  {regionsTitle}
                </Text>

                <List
                  aria-labelledby="club-search-regions-label"
                  theme={List.themes.noMargin}
                >
                  {regions.map(({ id, text }) => (
                    <button
                      className="club-search__option-button"
                      onClick={() => enableRegion(id)}
                      key={text}
                    >
                      <Location />
                      {text}
                    </button>
                  ))}
                </List>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <div className="club-search__results">
        {isLoading ? <Spinner theme={Spinner.themes.centered} /> : null}

        {clubs.length > 0 ? (
          <List theme={List.themes.marginMedium}>
            {clubs.map(club => (
              <ClubCard key={club.title} {...club} />
            ))}
          </List>
        ) : null}

        {!isLoading && didSearch && clubs.length === 0 ? (
          <div className="club-search__no-results">{noResultsText}</div>
        ) : null}

        {!isLoading && mode !== Mode.All ? (
          <div className="club-search__show-all">
            <Button
              variant={Button.variants.secondary}
              data-test-show-all-clubs
              onClick={() => setMode(Mode.All)}
              text={allClubsButtonLabel}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ClubSearch;
