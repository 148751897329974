import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';

import type { AutomaticLogoutPage as Props } from './automatic-logout-page.types';

const AutomaticLogoutPage: React.FunctionComponent<Props> = ({
  layout,
  link,
  logInButton,
  text,
  title,
}) => (
  <Layout {...layout}>
    <div className="automatic-logout-page">
      <ContentContainer
        className="automatic-logout-page__content"
        theme={ContentContainer.themes.veryNarrow}
      >
        <Text elementName="h1" size={Text.sizes.headline1}>
          {title}
        </Text>

        {text ? <Text size={Text.sizes.large}>{text}</Text> : null}

        <LinkButton {...logInButton} />

        {link ? (
          <LinkButton variant={LinkButton.variants.secondary} {...link} />
        ) : null}
      </ContentContainer>
    </div>
  </Layout>
);

export default AutomaticLogoutPage;
