import type { HiddenInput } from 'sats-ui-lib/react/hidden-input/hidden-input.types';
import type { Message } from 'sats-ui-lib/react/message/message.types';
import type { TextInput } from 'sats-ui-lib/react/text-input/text-input.types';

import type { CleanLink } from 'client/components/clean-link/clean-link.types';
import type { ObjectValues } from 'client/types';

export const brands = {
  sats: 'sats',
  elixia: 'elixia',
} as const;

export type LoginPage = {
  brand: ObjectValues<typeof brands>;
  endpoint: string;
  hiddenInputs: HiddenInput[];
  inputs: TextInput[];
  messages: Message[];
  resetPasswordLink: CleanLink;
  submitLabel: string;
  text: string;
  title: string;
};
