import Forward from '@sats-group/icons/16/forward';
import cn from 'classnames';
import React from 'react';

import Tag from 'sats-ui-lib/react/tag';
import Text from 'sats-ui-lib/react/text';
import VisuallyButton from 'sats-ui-lib/react/visually-button';

import DynamicImage from '../dynamic-image/dynamic-image';

import type { ProfileCard as Props } from './profile-card.types';

/** SPEC: https://www.figma.com/file/dQeQgh6sANMhWBUNtgXQNN/satsweb-components?node-id=2242-23449 */
const ProfileCard: React.FunctionComponent<Props> = ({
  extra,
  image,
  link,
  name,
  role,
  tall,
}) => (
  <a
    className={cn('profile-card', `profile-card--${extra.type}`, {
      'profile-card--tall': tall,
    })}
    href={link.href}
  >
    <div className="profile-card__image">
      <DynamicImage
        {...image}
        aspectRatio={
          extra.type === 'instructor'
            ? DynamicImage.aspectRatios.threeFour
            : DynamicImage.aspectRatios.square
        }
      />
    </div>
    <div className="profile-card__text">
      <div className="profile-card__part">
        <div
          className={cn('profile-card__name', {
            ['profile-card__name--split']: extra.type === 'trainer',
          })}
        >
          <div>
            <Text
              size={Text.sizes.headline3}
              theme={Text.themes.emphasis}
              tight
            >
              {name}
            </Text>
          </div>
          {extra.type === 'trainer' ? (
            <div>
              <Tag
                size={Tag.sizes.s}
                text={extra.props.level}
                variant={Tag.variants.featured}
              />
            </div>
          ) : null}
        </div>
        <Text className="profile-card__muted" size={Text.sizes.small} tight>
          {role}
        </Text>
      </div>
      {extra.type === 'instructor' ? (
        <div className="profile-card__part">
          <Text>{extra.props.quote}</Text>
        </div>
      ) : null}
      {extra.type === 'therapist' ? (
        <div className="profile-card__part profile-card__part--airy">
          <ul className="profile-card__expertise">
            {extra.props.expertise.map(entry => (
              <li key={entry}>
                <Text>{entry}</Text>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {extra.type === 'trainer' ? (
        <React.Fragment>
          <div className="profile-card__part profile-card__part--airy">
            <Text size={Text.sizes.small} theme={Text.themes.emphasis} tight>
              {extra.props.expertise.title}
            </Text>
            <ul className="profile-card__expertise profile-card__expertise--tags">
              {extra.props.expertise.entries.map(entry => (
                <li key={entry}>
                  <Tag
                    size={Tag.sizes.s}
                    text={entry}
                    variant={Tag.variants.secondary}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="profile-card__part">
            <Text className="profile-card__muted" size={Text.sizes.small} tight>
              {extra.props.goals.title}
            </Text>
            <ul className="profile-card__goals">
              {extra.props.goals.entries.map((goal, index) => (
                <React.Fragment key={goal}>
                  {index ? (
                    <li>
                      <Text>·</Text>
                    </li>
                  ) : null}
                  <li>
                    <Text>{goal}</Text>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        </React.Fragment>
      ) : null}
    </div>
    <div className="profile-card__overlay">
      <Text theme={Text.themes.emphasis}>{link.text}</Text>
      <VisuallyButton
        ariaLabel={link.text}
        elementName="div"
        leadingIcon={<Forward />}
        role="img"
        variant={VisuallyButton.variants.secondaryWhite}
      />
    </div>
  </a>
);

export default ProfileCard;
