/* `values` is a map of token name and value to replace the token with.
 * Placeholders in the template are surrounded by dollar signs ($).
```js
interpolate("Hello $name$", { name: "John" });
```
 */
const interpolate = (template = '', values: Record<string, string> = {}) =>
  Object.entries(values)
    .reduce(
      (currentText, [key, value]) => currentText.split(`$${key}$`).join(value),
      template
    )
    .replace(/\$[a-zA-Z]+\$/g, ''); // NOTE: Remove any non-replaced tokens

export default interpolate;
