import React from 'react';

import { mod } from 'client/helpers/add-bem-modifiers';
import type { ThemedComponent } from 'client/types';

const loadLevels: Record<number, string> = {
  0: 'low-load',
  1: 'medium-load',
  2: 'high-load',
};

const maxLoad = Math.max(...Object.keys(loadLevels).map(Number));

import type { ClubLoadIndicator as Props } from './club-load-indicator.types';
import { themes } from './club-load-indicator.types';

const ClubLoadIndicator: ThemedComponent<Props, typeof themes> = ({
  loadFactor = {},
  loadTexts = {},
  theme,
}) => {
  // NOTE: We don't show the load if the props specify a different range than this component, because that would be lying.
  const currentLoad =
    typeof loadFactor.current === 'number' && loadFactor.max === maxLoad
      ? loadFactor.current
      : undefined;

  if (currentLoad === undefined) {
    return null;
  }

  return (
    <div className={mod('club-load-indicator', loadLevels[currentLoad], theme)}>
      {loadTexts[currentLoad]}
    </div>
  );
};

ClubLoadIndicator.themes = themes;

export default ClubLoadIndicator;
