import AvatarIcon from '@sats-group/icons/160/avatar-subtract';
import CalendarIcon from '@sats-group/icons/24/calendar';
import LocationIcon from '@sats-group/icons/24/location';
import PersonIcon from '@sats-group/icons/24/person';
import TimeIcon from '@sats-group/icons/24/time';
import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import LinkButton from 'sats-ui-lib/react/link-button';
import Modal from 'sats-ui-lib/react/modal';
import ProgressBar from 'sats-ui-lib/react/progress-bar';
import Text from 'sats-ui-lib/react/text';

import CleanLink from 'client/components/clean-link/clean-link';
import ContentContainer from 'client/components/content-container/content-container';
import DynamicImage from 'client/components/dynamic-image/dynamic-image';
import RichText from 'client/components/rich-text/rich-text';
import Layout from 'components/layout/layout';

import type { BootCampEventPage as Props } from './boot-camp-event-page.types';

const BootCampEventPage: React.FunctionComponent<Props> = ({
  dateSection,
  descriptionSection,
  eventDetailSection,
  layout,
  personalTrainerSection,
  shortConceptDescription,
  terms,
  title,
  titlePreText,
}) => {
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  return (
    <Layout {...layout}>
      <ContentContainer className="boot-camp-event-page">
        <div className="boot-camp-event-page__heading">
          <Text
            elementName="h2"
            className="boot-camp-event-page__pre-text"
            theme={Text.themes.headline}
            italic
            size={Text.sizes.large}
          >
            {titlePreText}
          </Text>
          <Text
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
        </div>

        <div className="boot-camp-event-page__content-wrapper">
          {eventDetailSection ? (
            <div className="boot-camp-event-page__details-wrapper">
              <div className="boot-camp-event-page__details">
                <div className="boot-camp-event-page__details-spots-wrapper">
                  <div className="boot-camp-event-page__details-spots"></div>
                  <ProgressBar
                    {...eventDetailSection.spotsAvailable.progressBar}
                    labelPosition={ProgressBar.labelPositions.topRight}
                  />
                </div>

                <div className="boot-camp-event-page__details-info-wrapper">
                  <Text className="boot-camp-event-page__details-info">
                    <TimeIcon /> {eventDetailSection.sessions}
                  </Text>
                  <Text className="boot-camp-event-page__details-info">
                    <PersonIcon /> {eventDetailSection.participants}
                  </Text>
                  <div className="boot-camp-event-page__details-info">
                    <CalendarIcon />
                    <div>
                      <Text className="boot-camp-event-page__details-info-start-date">
                        {eventDetailSection.startDate.text}
                      </Text>
                      <Text className="boot-camp-event-page__details-info-start-date">
                        {eventDetailSection.startDate.date}
                      </Text>
                    </div>
                  </div>
                  <Text className="boot-camp-event-page__details-info">
                    <LocationIcon /> {eventDetailSection.club}
                  </Text>
                </div>
                <div className="boot-camp-event-page__details-price-wrapper">
                  <div>
                    <Text
                      size={Text.sizes.small}
                      className="boot-camp-event-page__details-price-text"
                    >
                      {eventDetailSection.memberPrice.text}
                    </Text>
                    <div className="boot-camp-event-page__details-price">
                      <Text
                        theme={Text.themes.headline}
                        italic
                        size={Text.sizes.headline2}
                        className="boot-camp-event-page__details-price-number"
                      >
                        {eventDetailSection.memberPrice.price}
                      </Text>
                      <Text>{eventDetailSection.nonMemberPrice.currency}</Text>
                    </div>
                  </div>
                  <div>
                    <Text
                      size={Text.sizes.small}
                      className="boot-camp-event-page__details-price-text"
                    >
                      {eventDetailSection.nonMemberPrice.text}
                    </Text>
                    <div className="boot-camp-event-page__details-price">
                      <Text
                        theme={Text.themes.headline}
                        italic
                        size={Text.sizes.headline2}
                        className="boot-camp-event-page__details-price-number"
                      >
                        {eventDetailSection.nonMemberPrice.price}
                      </Text>
                      <Text>{eventDetailSection.nonMemberPrice.currency}</Text>
                    </div>
                  </div>
                </div>
                <LinkButton
                  {...eventDetailSection.signUpUrl}
                  variant={LinkButton.variants.cta}
                  wide={true}
                />
                {terms ? (
                  <div className="boot-camp-event-page__details-terms">
                    <button
                      className="boot-camp-event-page__terms-button"
                      type="button"
                      onClick={() => setTermsModalIsOpen(true)}
                    >
                      {terms.label}
                    </button>
                    {termsModalIsOpen ? (
                      <Modal
                        {...terms.modal}
                        onClose={() => setTermsModalIsOpen(false)}
                      >
                        <RichText
                          className="personal-trainer-session-payment-page__terms"
                          {...terms.text}
                        />
                        <div className="personal-trainer-session-payment-page__terms-close">
                          <Button
                            onClick={() => setTermsModalIsOpen(false)}
                            {...terms.close}
                          />
                        </div>
                      </Modal>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="boot-camp-event-page__content">
            {shortConceptDescription ? (
              <Text theme={Text.themes.emphasis}>
                {shortConceptDescription}
              </Text>
            ) : null}
            {descriptionSection ? (
              <div className="boot-camp-event-page__section">
                <Text
                  elementName="h2"
                  theme={Text.themes.emphasis}
                  size={Text.sizes.headline2}
                >
                  {descriptionSection.heading}
                </Text>
                <Text>{descriptionSection.text}</Text>
              </div>
            ) : null}

            {dateSection ? (
              <div className="boot-camp-event-page__section">
                <Text
                  elementName="h2"
                  size={Text.sizes.headline2}
                  theme={Text.themes.emphasis}
                >
                  {dateSection.heading}
                </Text>
                <Text>{dateSection.text}</Text>
                <div className="boot-camp-event-page__dates-wrapper">
                  {dateSection.times.map((date, i) => (
                    <div key={date.month + i}>
                      <Text
                        className="boot-camp-event-page__date-month"
                        theme={Text.themes.emphasis}
                        size={Text.sizes.basic}
                      >
                        {date.month}
                      </Text>
                      {date.bootCamps.map(bootCamp => (
                        <div
                          key={bootCamp.date}
                          className="boot-camp-event-page__date-time"
                        >
                          <Text className="boot-camp-event-page__date">
                            {bootCamp.date}
                          </Text>
                          <Text>{bootCamp.time}</Text>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {personalTrainerSection ? (
              <div className="boot-camp-event-page__section">
                <Text
                  elementName="h2"
                  size={Text.sizes.headline2}
                  theme={Text.themes.emphasis}
                >
                  {personalTrainerSection.heading}
                </Text>
                <Text>{personalTrainerSection.text}</Text>

                <div className="boot-camp-event-page__personal-trainer">
                  <div className="boot-camp-event-page__introduction-wrapper">
                    <div className="boot-camp-event-page__image">
                      {personalTrainerSection.personalTrainer.image ? (
                        <DynamicImage
                          {...personalTrainerSection.personalTrainer.image}
                          aspectRatio={DynamicImage.aspectRatios.square}
                          theme={DynamicImage.themes.round}
                        />
                      ) : (
                        <AvatarIcon />
                      )}
                    </div>
                    <div>
                      <Text elementName="h1" theme={Text.themes.emphasis}>
                        {personalTrainerSection.personalTrainer.name}
                      </Text>
                      <div className="boot-camp-event-page__meta-info-wrap">
                        {personalTrainerSection.personalTrainer.meta?.map(
                          (info, index) =>
                            index > 0 ? (
                              <div
                                className="boot-camp-event-page__meta-info"
                                key={info}
                              >
                                <div className="boot-camp-event-page__dot-dark"></div>

                                <Text>{info}</Text>
                              </div>
                            ) : (
                              <div key={info}>
                                <Text>{info}</Text>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  <Text>
                    {personalTrainerSection.personalTrainer.description}
                  </Text>
                  <div className="boot-camp-event-page__contact-wrap">
                    {personalTrainerSection.personalTrainer.email?.email ? (
                      <div className="boot-camp-event-page__contact-field">
                        <Text>
                          {personalTrainerSection.personalTrainer.email?.label}
                        </Text>
                        <CleanLink
                          href={`mailto:${personalTrainerSection.personalTrainer.email?.email}`}
                        >
                          {personalTrainerSection.personalTrainer.email?.email}
                        </CleanLink>
                      </div>
                    ) : null}
                    {personalTrainerSection.personalTrainer.email?.email &&
                    personalTrainerSection.personalTrainer.phoneNumber
                      ?.number ? (
                      <div className="boot-camp-event-page__dot-dark"></div>
                    ) : null}
                    {personalTrainerSection.personalTrainer.phoneNumber
                      ?.number ? (
                      <div className="boot-camp-event-page__contact-field">
                        <Text>
                          {
                            personalTrainerSection.personalTrainer.phoneNumber
                              ?.label
                          }
                        </Text>
                        <Text>
                          {
                            personalTrainerSection.personalTrainer.phoneNumber
                              ?.number
                          }
                        </Text>
                      </div>
                    ) : null}
                  </div>
                  {personalTrainerSection.personalTrainer.profileUrl ? (
                    <div className="boot-camp-event-page__profile-link">
                      <LinkButton
                        variant={LinkButton.variants.secondary}
                        size={LinkButton.sizes.small}
                        {...personalTrainerSection.personalTrainer.profileUrl}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default BootCampEventPage;
