import React from 'react';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Hero from 'components/hero/hero';
import Layout from 'components/layout/layout';

import type { CorporateMembershipPage as Props } from './corporate-membership-page.types';
import ContentBlocks from 'client/components/content-blocks/content-blocks';

const CorporateMembershipPage: React.FunctionComponent<Props> = ({
  blocks,
  hero,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="corporate-membership-page">
      {hero ? (
        <Hero {...hero} />
      ) : (
        <ContentContainer theme={ContentContainer.themes.wide}>
          <Text
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.headline}
            italic
          >
            {title}
          </Text>
        </ContentContainer>
      )}
      <ContentContainer theme={ContentContainer.themes.wide}>
        <ContentBlocks {...blocks} />
      </ContentContainer>
    </div>
  </Layout>
);

export default CorporateMembershipPage;
