import React from 'react';
import Tag from 'sats-ui-lib/react/tag';
import Text from 'sats-ui-lib/react/text';

import Off from './off';
import On from './on';
import type { ProductOption as Props } from './product-option.types';

const ProductOption: React.FC<Props> = ({
  active,
  description,
  domAttributes,
  href,
  name,
  onClick,
  price,
  title,
  tag,
}) => (
  <a
    {...domAttributes}
    className="product-option"
    href={href}
    onClick={onClick}
  >
    <div className="product-option__head">
      <div
        className={
          active
            ? 'product-option__indicator product-option__indicator--active'
            : 'product-option__indicator product-option__indicator--inactive'
        }
      >
        {active ? <On /> : <Off />}
      </div>
      <Text>{name}</Text>
      {price ? (
        <div className="product-option__price">
          <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
            {price.value}
          </Text>
          <Text theme={Text.themes.emphasis}>{price.period}</Text>
        </div>
      ) : null}
      {tag ? (
        <div className="product-option__tag">
          <Tag {...tag} size={Tag.sizes.m} variant={Tag.variants.tertiary} />
        </div>
      ) : null}
    </div>
    <div className="product-option__body">
      {title ? <Text size={Text.sizes.small}>{title}</Text> : null}
      <div className="product-option__description">
        <Text size={Text.sizes.small}>{description}</Text>
      </div>
    </div>
  </a>
);

export default ProductOption;
