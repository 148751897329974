import Check from '@sats-group/icons/16/check';
import cn from 'classnames';
import React from 'react';

import Link from 'sats-ui-lib/react/link';
import LinkButton from 'sats-ui-lib/react/link-button';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import { replaceQueryParameters } from 'shared/replace-query-parameters';

import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import Contact from 'components/contact/contact';
import ContentContainer from 'components/content-container/content-container';
import GroupExerciseSchedule from 'components/group-exercise-schedule/group-exercise-schedule';
import Layout from 'components/layout/layout';
import List from 'components/list/list';
import LoadAndOpenStatus from 'components/load-and-open-status/load-and-open-status';
import LocationCard from 'components/location-card/location-card';
import LogoLetter from 'components/logo-letter/logo-letter';
import Map from 'components/map/map';
import ModalSlideshow from 'components/modal-slideshow/modal-slideshow';
import type { OpenStatus } from 'components/open-status/open-status.types';
import OpeningHours from 'components/opening-hours/opening-hours';
import ProfileCards from 'components/profile-cards/profile-cards';
import RichText from 'components/rich-text/rich-text';
import VisitorLoadGraph from 'components/visitor-load-graph/visitor-load-graph';
import useClubLoadFactors from 'hooks/use-club-load-factors';
import usePolling from 'hooks/use-polling';
import useUrlState from 'hooks/use-url-state';

import type { ClubPage as Props } from './club-page.types';

const Section: React.FunctionComponent<
  React.PropsWithChildren<{ fullbleed?: boolean; title?: string }>
> = ({ children, fullbleed, title }) => (
  <div
    className={cn('club-page__section', {
      'club-page__section--fullbleed': fullbleed,
    })}
  >
    {title ? (
      <Text
        className={cn('club-page__section-title', {
          'club-page__section-title--fullbleed': fullbleed,
        })}
        elementName="h2"
        size={Text.sizes.headline2}
      >
        {title}
      </Text>
    ) : null}
    {children}
  </div>
);

/* eslint-disable react/no-multi-comp */
const ClubPage: React.FunctionComponent<Props> = ({
  bannerLink,
  bannerText,
  body,
  breadcrumbs,
  checkoutLinkHref,
  checkoutLinkText,
  clubId,
  clubLoadEndpoint,
  contact,
  contactTitle,
  header,
  highlights,
  intro,
  layout,
  links,
  lists,
  loadTitle,
  load,
  loadAndOpenStatus,
  logo,
  map,
  messages,
  nearbyTitle,
  nearby,
  openingHours,
  openStatusesEndpoint,
  profiles,
  schedule,
  scheduleTitle,
  title,
  warnings,
}) => {
  const [queryParams] = useUrlState();
  const [loadFactors] = useClubLoadFactors(clubLoadEndpoint);
  const [openStatuses = {}] =
    usePolling<Record<string, OpenStatus>>(openStatusesEndpoint);

  const loadFactor = loadFactors ? loadFactors[clubId] : undefined;
  const openStatus = openStatuses ? openStatuses[clubId] : undefined;

  return (
    <Layout {...layout}>
      <div className="club-page">
        <Breadcrumbs {...breadcrumbs} />
        <ModalSlideshow
          {...header}
          link={{
            text: checkoutLinkText,
            // NOTE: The checkout links to this page with checkout parameters in the URL. Params are added here so that the checkout state is preserved when linking back to the checkout
            href: replaceQueryParameters(checkoutLinkHref, queryParams),
          }}
        />
        <ContentContainer>
          <div className="club-page__logo">
            <LogoLetter {...logo} />
          </div>
          <Text
            className="club-page__title"
            elementName="h1"
            size={Text.sizes.headline1}
          >
            {title}
          </Text>
          {messages.length ? (
            <div className="club-page__messages">
              {messages.map(message => (
                <Message key={message.text} {...message} />
              ))}
            </div>
          ) : null}
          {warnings.length ? (
            <div className="club-page__warnings">
              {warnings.map(warning => (
                <Message
                  key={warning}
                  text={warning}
                  theme={Message.themes.warning}
                />
              ))}
            </div>
          ) : null}
          {loadAndOpenStatus && loadFactor && openStatus ? (
            <div className="club-page__status">
              <LoadAndOpenStatus
                loadFactor={loadFactor}
                openStatus={openStatus}
                {...loadAndOpenStatus}
              />
            </div>
          ) : null}
          <div className="club-page__content">
            <div className="club-page__top">
              <RichText {...intro} />
              {highlights.length ? (
                <ul className="club-page__highlights">
                  {highlights.map(text => (
                    <li key={text}>
                      <Check />
                      <Text>{text}</Text>
                    </li>
                  ))}
                </ul>
              ) : null}
              {links ? (
                <Section title={links.title}>
                  <ul>
                    {links.items.map(link => (
                      <li key={link.href}>
                        <Link {...link} />
                      </li>
                    ))}
                  </ul>
                </Section>
              ) : null}
              {contact ? (
                <Section title={contactTitle}>
                  <Contact {...contact} />
                </Section>
              ) : null}
              {map ? (
                <Section>
                  <Map {...map} />
                </Section>
              ) : null}
            </div>

            <div className="club-page__sidebar">
              <div className="club-page__sidebar-inner">
                {openingHours ? (
                  <Section fullbleed title={openingHours.title}>
                    <OpeningHours {...openingHours.data} />
                  </Section>
                ) : null}
                {load ? (
                  <Section title={loadTitle}>
                    <VisitorLoadGraph {...load} />
                  </Section>
                ) : null}
              </div>
            </div>

            <div className="club-page__body">
              {lists.map(({ title, elements = [] }) =>
                elements.length === 0 ? null : (
                  <Section key={title} title={title}>
                    <ul className="club-page__list">
                      {elements.map(text => (
                        <li key={text}>
                          <Text>{text}</Text>
                        </li>
                      ))}
                    </ul>
                  </Section>
                )
              )}

              {body ? (
                <Section title={body.title}>
                  <RichText {...body.text} />
                </Section>
              ) : null}
            </div>
          </div>

          {bannerLink ? (
            <div className="club-page__banner">
              <Text theme={Text.themes.emphasis}>{bannerText}</Text>
              <LinkButton
                variant={LinkButton.variants.secondary}
                {...bannerLink}
              />
            </div>
          ) : null}

          {schedule ? (
            <Section title={scheduleTitle}>
              <GroupExerciseSchedule {...schedule} />
            </Section>
          ) : null}

          {profiles.length
            ? profiles.map(({ header, profileCards }) => (
                <Section key={header}>
                  <Text elementName="h2" size={Text.sizes.headline2}>
                    {header}
                  </Text>
                  <ProfileCards {...profileCards} />
                </Section>
              ))
            : null}

          {nearby.length ? (
            <Section title={nearbyTitle}>
              <List theme={List.themes.grid}>
                {nearby.map(item => (
                  <LocationCard
                    key={item.title}
                    openStatus={openStatuses[item.id]}
                    {...item}
                  />
                ))}
              </List>
            </Section>
          ) : null}
        </ContentContainer>
      </div>
    </Layout>
  );
};

export default ClubPage;
