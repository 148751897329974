import { useEffect, useState } from 'react';

import isRunningOnClient from 'client/helpers/is-running-on-client';

const useStorage = <T>(
  storageType: 'localStorage' | 'sessionStorage',
  key: string
) => {
  const [storedValue, setStoredValue] = useState<T>();

  useEffect(() => {
    try {
      const item = window[storageType].getItem(key);
      setStoredValue(item ? JSON.parse(item) : undefined);
    } catch {
      // Ignore
    }
  }, []);

  const setValue = (value: T) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;

    if (isRunningOnClient) {
      try {
        window[storageType].setItem(key, JSON.stringify(valueToStore));
      } catch {
        // Ignore
      }
    }

    setStoredValue(valueToStore);
  };

  return [storedValue, setValue] as [
    storedValue: typeof storedValue,
    setValue: typeof setValue,
  ];
};

export const useSessionStorage = <T>(key: string) =>
  useStorage<T>('sessionStorage', key);
