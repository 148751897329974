import { useMemo } from 'react';

import { tuple } from 'shared/tuple';

import usePolling from './use-polling';

type VisitorLoads = {
  visitorLoads?: {
    clubId: string;
    load: { max: number; current: number };
  }[];
};

/** Returns and object with club IDs as keys and load data as values. The data is refreshed automatically every minute. */
export default function useClubLoadFactors(endpoint: string) {
  const [data = {}, isLoading] = usePolling<VisitorLoads>(endpoint);

  const loadFactors = useMemo(() => {
    const { visitorLoads = [] } = data;
    return Object.fromEntries(
      visitorLoads.map(({ clubId, load }) => [clubId, load])
    );
  }, [data]);

  return tuple([loadFactors, isLoading]);
}
