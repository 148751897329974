import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import Checkbox from 'sats-ui-lib/react/checkbox';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import CleanLink from 'client/components/clean-link/clean-link';
import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import RadioList from 'components/radio-list/radio-list';
import RichText from 'components/rich-text/rich-text';
import Spinner from 'components/spinner/spinner';

import type { CancellationAlternativeFormPage as Props } from './cancellation-alternative-form-page.types';

const CancellationAlternativeFormPage: React.FunctionComponent<Props> = ({
  continueLabel,
  description,
  endpoint,
  extraOptions,
  formTitle,
  formDescription,
  hiddenInputs,
  myPageLayout,
  previousPage,
  terms,
  thankYou,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      e.preventDefault();
    }
    setIsSubmitting(true);
  };

  return (
    <MyPageLayout {...myPageLayout}>
      <ContentContainer>
        <div className="cancellation-alternative-form-page">
          {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
          {thankYou ? (
            <Text
              className="cancellation-alternative-form-page__thank-you-text"
              theme={Text.themes.emphasis}
              size={Text.sizes.headline3}
            >
              {thankYou}
            </Text>
          ) : null}
          <RichText {...description} />
          {formTitle ? (
            <Text elementName="h2" size={Text.sizes.large}>
              {formTitle}
            </Text>
          ) : null}
          <RichText
            className="cancellation-alternative-form-page__form-description"
            {...formDescription}
          />
          <form action={endpoint} method="POST" onSubmit={handleSubmit}>
            {hiddenInputs.map(input => (
              <HiddenInput {...input} key={input.name} />
            ))}
            {extraOptions.map(options => (
              <RadioList
                key={options.label}
                {...options}
                showOnlyOneCustomError={true}
              />
            ))}
            {terms ? (
              <div className="cancellation-alternative-form-page__terms">
                <Checkbox
                  {...terms.input}
                  data-test-terms
                  required
                  extra={
                    <CleanLink
                      {...terms.link}
                      theme={CleanLink.themes.orange}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  }
                />
              </div>
            ) : null}
            {previousPage ? (
              <LinkButton
                className="cancellation-alternative-form-page__previous"
                variant={LinkButton.variants.secondary}
                {...previousPage}
              />
            ) : null}
            <Button type="submit" data-test-submit text={continueLabel} />
          </form>
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default CancellationAlternativeFormPage;
