import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import type { MemberCareCard as Props } from './member-care-card.types';

const MemberCareCard: React.FunctionComponent<Props> = ({
  label,
  text,
  link,
}) => (
  <div className="member-care-card">
    <div className="member-care-card__content">
      <div className="member-care-card__label">
        <Text
          elementName="h2"
          theme={Text.themes.emphasis}
          size={Text.sizes.large}
        >
          {label}
        </Text>
      </div>
      <div className="member-care-card__text">
        <Text>{text}</Text>
      </div>
      <div className="member-care-card__link">
        <LinkButton {...link} variant={LinkButton.variants.secondary} />
      </div>
    </div>
  </div>
);

export default MemberCareCard;
