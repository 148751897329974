import SvgCreditcard from '@sats-group/icons/24/creditcard';
import React from 'react';
import Text from 'sats-ui-lib/react/text';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';

import type { PayDebtConfirmation as Props } from './pay-debt-confirmation.types';

const PayDebtConfirmation: React.FunctionComponent<Props> = ({
  information,
  layout,
}) => (
  <FlowTerminusLayout {...layout}>
    <div className="pay-debt-confirmation">
      <Text size={Text.sizes.large} theme={Text.themes.headline}>
        {information.title}
      </Text>
      <div className="pay-debt-confirmation__information">
        <div aria-hidden>
          <SvgCreditcard />
        </div>
        <Text>{information.text}</Text>
      </div>
    </div>
  </FlowTerminusLayout>
);

export default PayDebtConfirmation;
