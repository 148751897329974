import cn from 'classnames';
import { isDefined } from 'list-fns';
import React, { useEffect, useRef, useState } from 'react';

import Message from 'sats-ui-lib/react/message';
import type { Message as MessageProps } from 'sats-ui-lib/react/message/message.types';

import { useMapbox } from 'hooks/use-mapbox';

import type { Map as Props } from './map.types';

const Map: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  accessToken,
  errors,
  icon,
  markers,
  options,
  showMarkers,
  variant,
}) => {
  const { error, mapbox, supported } = useMapbox();
  const mapNode = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<MessageProps[]>([]);

  useEffect(() => {
    setMessages(
      [
        error
          ? {
              text: errors.generic,
              theme: 'error' as const,
            }
          : undefined,
        supported
          ? undefined
          : {
              text: errors.unsupported,
              theme: 'error' as const,
            },
      ].filter(isDefined)
    );
  }, [error, supported]);

  useEffect(() => {
    if (!mapbox || !mapNode.current) {
      return;
    }

    mapbox.accessToken = accessToken;

    const map = new mapbox.Map(
      Object.assign({}, options, { container: mapNode.current })
    );

    map.addControl(new mapbox.NavigationControl(), 'top-left');

    const bounds = new mapbox.LngLatBounds();

    markers.forEach(({ lat, lng, onClick, popup }) => {
      const markerElement = document.createElement('div');
      markerElement.innerHTML = icon;

      if (onClick) {
        markerElement.addEventListener('click', onClick);
      }

      const marker = new mapbox.Marker({
        anchor: 'bottom',
        element: markerElement,
        offset: [-20, -52], // NOTE: We offset with -20 (40px width / 2) so that the marker is center and -52 (height of marker)
      }).setLngLat([lng, lat]);

      if (popup) {
        marker.setPopup(
          new mapbox.Popup({
            offset: [0, -55],
            focusAfterOpen: mapNode.current
              ? mapNode.current.clientWidth ===
                document.documentElement.clientWidth
              : undefined,
          }).setHTML(popup)
        );
        markerElement.addEventListener('click', marker.togglePopup);
      }

      if (showMarkers) {
        marker.addTo(map);
      }

      if (!options.center) {
        bounds.extend([lng, lat]);
      }
    });

    if (!bounds.isEmpty()) {
      // NOTE: mapbox-gl crashes if the padding is too much for the rendered map node.
      // We have to subtract one from half to have room for a marker point.
      const maxVerticalPadding =
        Math.ceil(mapNode.current.clientHeight / 2) - 1;
      const maxHorizontalPadding =
        Math.ceil(mapNode.current.clientWidth / 2) - 1;
      const padding = Math.min(maxHorizontalPadding, maxVerticalPadding, 100);
      map.fitBounds(bounds, { padding, duration: 0 });
    }
  }, [markers, mapbox, options, showMarkers]);

  return (
    <div className="map">
      {messages.length ? (
        <div className="map__messages">
          {messages.map(message => (
            <Message {...message} inline key={message.text} />
          ))}
        </div>
      ) : null}
      <div
        className={cn('map__node-wrapper', {
          [`map__node-wrapper--${variant}`]: variant,
          ['map__node-wrapper--collapsed']: messages.length,
        })}
      >
        <div className="map__node" id="map" ref={mapNode} />
      </div>
    </div>
  );
};

export default Map;
