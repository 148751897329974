import { useEffect, useState } from 'react';

import { fromQueryString } from 'shared/from-query-string';
import { replaceQueryParameters } from 'shared/replace-query-parameters';

import useCustomEvent from 'hooks/use-custom-event';

type StateInput = NonNullable<Parameters<typeof replaceQueryParameters>[1]>;
type StateOutput = ReturnType<typeof fromQueryString>;
type StateSetter = (
  newState: StateInput | ((previousState: StateInput) => StateInput)
) => void;

/** `shouldReactToExternalChange` can be used to force the state to be updated
 * whenever the URL is changed by another instance of `useUrlState` */
const useUrlState = (
  shouldReactToExternalChange?: boolean
): [currentState: StateOutput, setState: StateSetter, hasReadUrl: boolean] => {
  const [urlState, setInternalUrlState] = useState<StateOutput>({});
  const [hasReadUrl, setHasReadUrl] = useState(false);

  const hasUpdatedUrlState = useCustomEvent('url-update', () => {
    if (shouldReactToExternalChange) {
      setInternalUrlState(fromQueryString(window.location.search));
    }
  });

  useEffect(() => {
    setInternalUrlState(fromQueryString(window.location.search));
    setHasReadUrl(true);
  }, []);

  const setUrlState: StateSetter = stateOrFunction => {
    const newState =
      typeof stateOrFunction === 'function'
        ? stateOrFunction(urlState)
        : stateOrFunction;
    const newUrl = replaceQueryParameters(window.location.href, newState);

    history.replaceState(null, document.title, newUrl);
    setInternalUrlState(fromQueryString(window.location.search));
    hasUpdatedUrlState();
  };

  return [urlState, setUrlState, hasReadUrl];
};

export default useUrlState;
