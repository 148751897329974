import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Logo from 'components/logo/logo';
import Spinner from 'components/spinner/spinner';

import type { WaitingArea as Props } from './waiting-area.types';

const WaitingArea: React.FunctionComponent<Props> = ({
  form,
  logo,
  translation,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <div className="waiting-area">
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div className="waiting-area__content">
          <Logo color={Logo.colors.dark} {...logo} />
          <Text>{translation.text}</Text>
          <form action={form.action} method="post" onSubmit={handleSubmit}>
            {form.hiddenInputs.map(input => (
              <HiddenInput key={input.name} {...input} />
            ))}
            <Button text={translation.submit} type="submit" />
          </form>
        </div>
      </ContentContainer>
    </div>
  );
};

export default WaitingArea;
