import React, { useEffect, useRef } from 'react';

import Message from 'sats-ui-lib/react/message';

import ContentContainer from 'components/content-container/content-container';
import useMessage from 'hooks/use-message';

import type { DynamicMessage as Props } from './dynamic-message.types';

const DynamicMessage: React.FunctionComponent<Props> = ({ closeText }) => {
  const timerRef = useRef<number>();
  const [message, clearMessage] = useMessage();

  useEffect(() => {
    if (message && message.theme === 'success') {
      timerRef.current = Number(setTimeout(clearMessage, 10000));
    }

    return () => clearTimeout(timerRef.current);
  }, [message]);

  // NOTE: Always make sure that the live region is present in the document first,
  // and only then dynamically add/change any content.
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
  return (
    <ContentContainer aria-live="polite" className="dynamic-message">
      {message ? (
        <Message
          action={{
            elementName: 'button',
            onClick: clearMessage,
            text: closeText,
          }}
          {...message}
        />
      ) : null}
    </ContentContainer>
  );
};

export default DynamicMessage;
