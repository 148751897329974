import React from 'react';

import Bomb from 'sats-ui-lib/react/bomb';
import Flag from 'sats-ui-lib/react/flag';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import BackgroundImage from 'components/background-image/background-image';

import type { PredefinedMembershipHeader as Props } from './predefined-membership-header.types';

const PredefinedMembershipHeader: React.FunctionComponent<Props> = ({
  bomb,
  highlightedText,
  image,
  links = [],
  subTitle,
  tag,
  text,
  title,
}) => (
  <div className="predefined-membership-header">
    <div className="predefined-membership-header__content">
      <div className="predefined-membership-header__tag">
        <Flag text={tag} />
      </div>

      {bomb ? (
        <div className="predefined-membership-header__bomb">
          <Bomb {...bomb} />
        </div>
      ) : null}

      <div className="predefined-membership-header__band">
        <div className="predefined-membership-header__band-content">
          <Text size={Text.sizes.basic}>{subTitle}</Text>

          <Text
            size={Text.sizes.headline1}
            theme={Text.themes.emphasis}
            elementName="h1"
          >
            {title}
          </Text>

          {highlightedText ? (
            <Text
              size={Text.sizes.large}
              className="predefined-membership-header__highlighted-text"
            >
              {highlightedText}
            </Text>
          ) : null}

          {text ? <Text size={Text.sizes.large}>{text}</Text> : null}

          {links.length ? (
            <div className="predefined-membership-header__buttons">
              {links.map((link, index) => (
                <LinkButton
                  {...link}
                  key={link.href}
                  variant={
                    index === 0
                      ? LinkButton.variants.cta
                      : LinkButton.variants.ctaSecondaryWhite
                  }
                  size={LinkButton.sizes.large}
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
    {image ? <BackgroundImage {...image} /> : null}
  </div>
);

export default PredefinedMembershipHeader;
