import React from 'react';

import Text from 'sats-ui-lib/react/text';

import RichText from '../rich-text/rich-text';
import YoutubePlayer from '../youtube-player/youtube-player';

import type { EmbedYoutube as Props } from './embed-youtube.types';

const EmbedYoutube: React.FunctionComponent<Props> = ({
  autoplay,
  id,
  title,
  text,
}) => (
  <div className="embed-youtube">
    {title ? (
      <Text
        className="embed-youtube__title"
        elementName="h2"
        size={Text.sizes.headline2}
        theme={Text.themes.emphasis}
      >
        {title}
      </Text>
    ) : null}
    <RichText className="embed-youtube__text" {...text} />
    <YoutubePlayer autoplay={autoplay} id={id} />
  </div>
);

export default EmbedYoutube;
