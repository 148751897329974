import type React from 'react';
import type { AnchorHTMLAttributes } from 'react';

import type { replaceQueryParameters } from 'shared/replace-query-parameters';

import type { ObjectValues } from 'client/types';

export const themes = {
  noUnderline: 'no-underline',
  orange: 'orange',
} as const;

export interface CleanLink extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode;
  className?: string;
  elementName?: string;
  href: string;
  text?: string;
  theme?: ObjectValues<typeof themes>;
  query?: Parameters<typeof replaceQueryParameters>[1];
}
