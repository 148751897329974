import { useEffect } from 'react';

const dispatch = (eventName: string) => () =>
  window.dispatchEvent(new CustomEvent(eventName));

const subscriber = (listener: () => void) => () => listener();

const subscribe = (eventName: string, listener: () => void) => {
  window.addEventListener(eventName, subscriber(listener));
  return () => window.removeEventListener(eventName, subscriber(listener));
};

export default function useCustomEvent(
  eventName: string,
  eventHandler: () => void
): ReturnType<typeof dispatch> {
  useEffect(() => subscribe(eventName, eventHandler), []);

  return dispatch(eventName);
}
