import type { Block, Inline } from '@contentful/rich-text-types';

import type { EmbedYoutube } from 'components/embed-youtube/embed-youtube.types';

export const toEmbedYoutubeProps = (
  node: Block | Inline
): EmbedYoutube | undefined => {
  const id = node?.data?.target?.fields?.embedCode;

  if (id) {
    return { id };
  }

  return undefined;
};
