import React from 'react';

import Elixia from 'sats-ui-lib/react/logos/elixia';
import ElixiaSmall from 'sats-ui-lib/react/logos/elixia-small';
import Sats from 'sats-ui-lib/react/logos/sats';
import SatsSmall from 'sats-ui-lib/react/logos/sats-small';

import type { Logo as Props } from './logo.types';
import { brands, colors, sizes } from './logo.types';

const Logo: React.FC<Props> & {
  colors: typeof colors;
  brands: typeof brands;
  sizes: typeof sizes;
} = ({
  brand = brands.sats,
  color = colors.light,
  href,
  label,
  size = sizes.small,
}) => {
  const L =
    brand === brands.sats
      ? size === sizes.large
        ? Sats
        : SatsSmall
      : size === sizes.large
        ? Elixia
        : ElixiaSmall;

  return (
    <div className={`logo logo--${color}`}>
      {href ? (
        <a aria-label={label} className="logo__link" href={href}>
          <L />
        </a>
      ) : (
        <L />
      )}
    </div>
  );
};

Logo.colors = colors;
Logo.brands = brands;
Logo.sizes = sizes;

export default Logo;
