import React from 'react';

import type { RichTextQuoteBlock as Props } from './rich-text-quote-block.types';

import Text from 'sats-ui-lib/react/text';

const RichTextQuoteBlock: React.FunctionComponent<Props> = ({ text }) => (
  <blockquote className="rich-text-quote-block">
    <div aria-hidden className="rich-text-quote-block__pre">
      <Text italic size={Text.sizes.headline3}>
        {'“'}
      </Text>
    </div>
    <div className="rich-text-quote-block__text">
      <Text italic size={Text.sizes.headline3}>
        {text} <span aria-hidden>{'”'}</span>
      </Text>
    </div>
  </blockquote>
);

export default RichTextQuoteBlock;
