import ChevronLeft from '@sats-group/icons/24/arrow-left';
import ChevronRight from '@sats-group/icons/24/arrow-right';
import React, { useEffect, useState } from 'react';

import Text from 'sats-ui-lib/react/text';

import { get, overrideStatus } from 'client/helpers/api-helper';
import Spinner from 'components/spinner/spinner';
import useTrackChange from 'hooks/use-track-change';

import type { VisitorLoadGraph as Props } from './visitor-load-graph.types';

type Day = {
  dayOfWeek: string;
  hours: {
    hourNumber: number;
    predictedLoadFactor: number;
    actualLoadFactor: number;
  }[];
};

const VisitorLoadGraph: React.FunctionComponent<Props> = ({
  actualLoadText,
  errorMessage,
  nextDayText,
  previousDayText,
  predictedLoadText,
  url,
  weekdayTexts = {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [days, setDays] = useState<Day[]>([]);
  const [dayIndex, setDayIndex] = useState<number>(0);
  const [error, setError] = useState<string>();

  const errorOverride = () => {
    setError(errorMessage);
    return true;
  };

  useEffect(() => {
    setDays([]);
    setIsLoading(true);
    setError(undefined);
    get<{ days: Day[] }>(url)
      .then(({ days }) => {
        if (days && days.length > 0) {
          setDays(days);
        } else {
          setError(errorMessage);
        }
      })
      .catch(
        overrideStatus({
          404: errorOverride,
          500: errorOverride,
          502: errorOverride,
          503: errorOverride,
        })
      )
      .finally(() => setIsLoading(false));
  }, [url]);

  useEffect(() => setDayIndex(0), [days]);

  const day = dayIndex !== undefined ? days?.[dayIndex] : undefined;
  const { dayOfWeek, hours } = day || {};

  useTrackChange(dayIndex, index => ({
    category: 'ClubPages',
    action: 'ChangeDay',
    label: index,
  }));

  return (
    <div className="visitor-load-graph">
      {isLoading ? (
        <div className="visitor-load-graph__spinner">
          <Spinner />
        </div>
      ) : null}
      {error ? (
        <Text className="visitor-load-graph__error" size={Text.sizes.small}>
          {errorMessage}
        </Text>
      ) : null}
      {hours && hours?.length > 0 ? (
        <>
          <div className="visitor-load-graph__graph">
            {hours.map(
              ({ hourNumber, predictedLoadFactor, actualLoadFactor }) => (
                <div
                  className="visitor-load-graph__item"
                  key={hourNumber}
                  style={{ width: `${(1 / hours.length) * 100}%` }}
                >
                  <div className="visitor-load-graph__bars">
                    <meter value={predictedLoadFactor} min={0} max={1}>
                      {predictedLoadText}
                    </meter>
                    <div
                      className="visitor-load-graph__bar-predicted"
                      style={{ height: `${predictedLoadFactor * 100}%` }}
                    />

                    {actualLoadFactor ? (
                      <>
                        <meter value={actualLoadFactor} min={0} max={1}>
                          {actualLoadText}
                        </meter>
                        <div
                          className="visitor-load-graph__bar-actual"
                          style={{ height: `${actualLoadFactor * 100}%` }}
                        />
                      </>
                    ) : null}
                  </div>
                  <Text
                    className="visitor-load-graph__hour"
                    size={Text.sizes.small}
                  >
                    {hourNumber}
                  </Text>
                </div>
              )
            )}
          </div>
          <div aria-hidden="true" className="visitor-load-graph__legend">
            <Text
              className="visitor-load-graph__legend-actual"
              size={Text.sizes.small}
            >
              {actualLoadText}
            </Text>
            <Text
              className="visitor-load-graph__legend-predicted"
              size={Text.sizes.small}
            >
              {predictedLoadText}
            </Text>
          </div>
          <div className="visitor-load-graph__nav">
            <button
              aria-label={previousDayText}
              className="visitor-load-graph__button"
              disabled={dayIndex === 0}
              onClick={() => setDayIndex(i => i - 1)}
            >
              <ChevronLeft />
            </button>
            <Text className="visitor-load-graph__day" size={Text.sizes.basic}>
              {dayOfWeek ? weekdayTexts[dayOfWeek] || dayOfWeek : dayOfWeek}
            </Text>
            <button
              aria-label={nextDayText}
              className="visitor-load-graph__button"
              disabled={dayIndex >= days.length - 1}
              onClick={() => setDayIndex(i => i + 1)}
            >
              <ChevronRight />
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default VisitorLoadGraph;
