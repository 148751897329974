import PtIcon from '@sats-group/icons/24/pt-label';
import React from 'react';

import Text from 'sats-ui-lib/react/text';
import VisuallyHidden from 'sats-ui-lib/react/visually-hidden';

import type { ScheduleMetadata as Props } from './group-exercise-schedule.types';

const ScheduleMetadata: React.FunctionComponent<Props> = ({
  clubName,
  duration,
  durationText,
  instructor,
  pt,
  name,
  startsAt,
  time,
}) => (
  <div className="group-exercise-schedule__metadata">
    <time className="group-exercise-schedule__time" dateTime={`${startsAt}`}>
      <Text theme={Text.themes.emphasis} size={Text.sizes.basic}>
        {time}
      </Text>
      <span className="group-exercise-schedule__time-duration">
        <time dateTime={`P${duration}M`}>{duration}</time> {durationText}
      </span>
    </time>
    <div className="group-exercise-schedule__metadata-content">
      <div>
        <Text
          elementName="span"
          size={Text.sizes.basic}
          theme={Text.themes.emphasis}
        >
          {name}
        </Text>
        <div className="group-exercise-schedule__instructor">
          {instructor}
          {pt ? (
            <span className="group-exercise-schedule__pt">
              <PtIcon />
              <VisuallyHidden>{pt}</VisuallyHidden>
            </span>
          ) : undefined}
        </div>
      </div>
      <Text
        className="group-exercise-schedule__club"
        elementName="div"
        size={Text.sizes.basic}
      >
        {clubName}
      </Text>
    </div>
  </div>
);

export default ScheduleMetadata;
