import React from 'react';

import DateItem from './date-item';
import type { DateList as Props } from './date-list.types';

const DateList: React.FunctionComponent<Props> = ({
  dayLabels,
  dates = [],
  onClick,
  selectedDate,
  shouldScrollToCurrent,
}) =>
  dates.length === 0 ? null : (
    <div className="date-list">
      <ul className="date-list__dates" tabIndex={0}>
        {dates.map(({ isoDate, ...rest }) => (
          <li key={isoDate}>
            <DateItem
              dayLabels={dayLabels}
              isoDate={isoDate}
              isSelected={selectedDate === isoDate}
              onClick={onClick}
              shouldScrollToCurrent={shouldScrollToCurrent}
              {...rest}
            />
          </li>
        ))}
      </ul>
    </div>
  );

export default DateList;
