import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ContentBlocks from 'client/components/content-blocks/content-blocks';
import ContentContainer from 'client/components/content-container/content-container';
import Hero from 'client/components/hero/hero';
import Layout from 'client/components/layout/layout';

import type { MarketingSubpage as Props } from './marketing-subpage.types';

const MarketingSubpage: React.FunctionComponent<Props> = ({
  blocks,
  hero,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="marketing-subpage">
      {hero ? (
        <div className="marketing-subpage__hero">
          <Hero {...hero} clean />
        </div>
      ) : (
        <Text
          elementName="h1"
          className="marketing-subpage__title"
          size={Text.sizes.headline1}
          theme={Text.themes.headline}
          italic
        >
          {title}
        </Text>
      )}
      {blocks ? (
        <ContentContainer theme={ContentContainer.themes.wide}>
          <div className="marketing-subpage__blocks">
            <ContentBlocks {...blocks} />
          </div>
        </ContentContainer>
      ) : null}
    </div>
  </Layout>
);

export default MarketingSubpage;
