// NOTE: We can't import this module here,
// because this module is shared between the client and server,
// and the client type checking and build doesn't understand
// what to do with this at all (URL is a global on the client).
// It seems to work OK to not import `URL` on the server,
// but it looks a little dirty.
// import { URL } from 'url';

type QueryInnerValue = boolean | number | string;
type QueryValue = QueryInnerValue | QueryInnerValue[] | undefined;
type QueryObject = Record<string, QueryValue>;

const isDefinedQueryValue = (
  value: QueryValue
): value is Exclude<QueryValue, undefined> => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'string') {
    return value.length > 0;
  }

  if (typeof value === 'boolean') {
    return true;
  }

  if (typeof value === 'undefined') {
    return false;
  }

  if (!isNaN(value)) {
    return true;
  }

  return false;
};

export const toQueryString = (queryObject: QueryObject) => {
  const url = new URL('https://example.com');

  Object.entries(queryObject).forEach(([key, value]) => {
    if (isDefinedQueryValue(value)) {
      if (Array.isArray(value)) {
        value.forEach(innerValue =>
          url.searchParams.append(key, String(innerValue))
        );
      } else {
        url.searchParams.append(key, String(value));
      }
    }
  });

  return `?${url.searchParams.toString()}`;
};
