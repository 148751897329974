import type { ObjectValues } from 'client/types';

export const themes = {
  onDarkBackground: 'on-dark-background',
} as const;

export type Tagline = {
  words: string[];
  theme?: ObjectValues<typeof themes>;
};
