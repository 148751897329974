import type { Document } from '@contentful/rich-text-types';

import type { ObjectValues } from 'client/types';

export const themes = {
  blueLinks: 'blue-links',
  greenCheckmarkList: 'green-checkmark-list',
  orangeLinks: 'orange-links',
  whiteCheckmarkList: 'white-checkmark-list',
} as const;

export type RichText = {
  className?: string;
  content?: Document;
  theme?: ObjectValues<typeof themes>;
};
