import React from 'react';

import type { CircleProgress as Props } from './circle-progress.types';

const CircleProgress: React.FunctionComponent<Props> = ({
  value,
  max,
  size = 40,
}) => {
  const circumference = size * Math.PI;

  const circleProps = {
    r: size / 2 - 1,
    cx: size / 2,
    cy: size / 2,
    strokeWidth: 2,
  };

  return (
    <svg
      className="circle-progress"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <circle className="circle-progress__background-circle" {...circleProps} />
      <circle
        {...circleProps}
        className="circle-progress__semi-circle"
        style={{
          strokeDasharray: `${circumference}px`,
          // NOTE: This math is a little weird but it works
          strokeDashoffset: `${circumference * (-1 - value / max)}px`,
        }}
      />
    </svg>
  );
};

export default CircleProgress;
