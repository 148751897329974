import React from 'react';

import Text from 'sats-ui-lib/react/text';

import BreakOutContainer from 'components/break-out-container/break-out-container';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import EmbedMedia from 'components/embed-media/embed-media';

import type { PageHeader as Props } from './page-header.types';

const PageHeader: React.FunctionComponent<Props> = ({
  image,
  media,
  shouldBreakOut,
  title,
}) => {
  const MediaWrapper = shouldBreakOut ? BreakOutContainer : React.Fragment;

  return (
    <div className="page-header">
      <Text
        className="page-header__title"
        elementName="h1"
        size={Text.sizes.headline2}
        theme={Text.themes.headline}
        italic
      >
        {title}
      </Text>

      {image || media ? (
        <MediaWrapper>
          <div className="page-header__media">
            {image ? (
              <DynamicImage
                aspectRatio={DynamicImage.aspectRatios.sixteenNine}
                {...image}
              />
            ) : null}
            {media ? (
              <EmbedMedia
                theme={
                  shouldBreakOut ? EmbedMedia.themes.paddedButtons : undefined
                }
                {...media}
              />
            ) : null}
          </div>
        </MediaWrapper>
      ) : null}
    </div>
  );
};

export default PageHeader;
