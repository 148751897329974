import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { FreezeConfirmationPage as Props } from './freeze-confirmation-page.types';

const FreezeConfirmationPage: React.FunctionComponent<Props> = ({
  information,
  myPageLayout,
  subtitle,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="freeze-confirmation-page">
        <Text elementName="h2" size={Text.sizes.headline2}>
          {subtitle}
        </Text>
        {information.map(i => (
          <Text key={i}>{i}</Text>
        ))}
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default FreezeConfirmationPage;
