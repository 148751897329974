import React from 'react';

import Flag from 'sats-ui-lib/react/flag';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import List from 'components/list/list';
import Price from 'components/price/price';

import type { AccessLevelCard as Props } from './access-level-card.types';

const AccessLevelCard: React.FunctionComponent<Props> = ({
  id,
  isRecommended,
  link,
  negativeBullets = [],
  positiveBullets = [],
  price,
  tag,
  reducedPriceDisclaimer,
  title,
}) => (
  <div className="access-level-card">
    <Text
      elementName="h2"
      className="access-level-card__title"
      size={Text.sizes.headline3}
      theme={Text.themes.normal}
    >
      {title}
    </Text>

    <div className="access-level-card__body">
      {tag ? (
        <div className="access-level-card__tag">
          <Flag text={tag} />
        </div>
      ) : null}

      {price ? (
        <div className="access-level-card__price">
          <Price {...price} />
        </div>
      ) : null}

      {reducedPriceDisclaimer ? (
        <div className="access-level-card__reduced-price-disclaimer">
          {reducedPriceDisclaimer.description ? (
            <Text size={Text.sizes.small}>
              {reducedPriceDisclaimer.description}
            </Text>
          ) : null}
          <Text
            className="access-level-card__reduced-price"
            size={Text.sizes.interface}
          >
            {reducedPriceDisclaimer.normalPrice}
          </Text>
        </div>
      ) : null}

      {positiveBullets.length || negativeBullets.length ? (
        <Text className="access-level-card__bullets" size={Text.sizes.small}>
          {positiveBullets.length ? (
            <List theme={List.themes.greenCheckmarks}>{positiveBullets}</List>
          ) : null}
          {negativeBullets.length ? (
            <List theme={List.themes.redCrosses}>{negativeBullets}</List>
          ) : null}
        </Text>
      ) : null}
    </div>

    {link ? (
      <div className="access-level-card__link-wrapper">
        <LinkButton
          data-test-access-level={id}
          className="access-level-card__link"
          size={LinkButton.sizes.large}
          variant={
            isRecommended
              ? LinkButton.variants.cta
              : LinkButton.variants.secondary
          }
          {...link}
        />
      </div>
    ) : null}
  </div>
);

export default AccessLevelCard;
