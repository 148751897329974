import React from 'react';

import { publish } from 'client/helpers/messages';
import SalesforceChatTrigger from 'components/salesforce-chat-trigger/salesforce-chat-trigger';

import type { CheckoutChat as Props } from './checkout-chat.types';

const CheckoutChat: React.FunctionComponent<Props> = ({ salesforce }) => {
  const handleSalesforceMessage = (message: Parameters<typeof publish>[0]) =>
    publish(message);

  return (
    <div className="checkout-chat">
      {salesforce ? (
        <SalesforceChatTrigger
          {...salesforce}
          floating
          onMessage={handleSalesforceMessage}
        />
      ) : null}
    </div>
  );
};

export default CheckoutChat;
