import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Message from 'sats-ui-lib/react/message';
import TabTrapper from 'sats-ui-lib/react/modal/tab-trapper';

import { replaceQueryParameters } from 'shared/replace-query-parameters';

import absoluteUrl from 'client/helpers/absolute-url';
import { get, post } from 'client/helpers/api-helper';
import extractServerError from 'client/helpers/extract-server-error';
import { publish } from 'client/helpers/messages';
import Spinner from 'components/spinner/spinner';
import { useCheckoutSessionContext } from 'contexts/checkout-session-context-provider';
import {
  useAdyen,
  type AdyenState,
  type ApiResponse,
  type Core,
  type DropinElement,
} from 'hooks/use-adyen';
import useAsyncEffect from 'hooks/use-async-effect';
import useUrlState from 'hooks/use-url-state';

import type { Payment as Props } from './payment.types';

const magicAdyenId = 'dropin';
const magic3dsId = 'payment-3ds-frame';

const handleError = (error: Error) => {
  const message = extractServerError(error);
  if (message) {
    publish({ text: message, theme: 'error' });
  }
};

const Payment: React.FunctionComponent<Props> = ({
  adyenClientKey,
  adyenEnv,
  isMemberIdRequiredForIntegrations,
  locale,
  memberId: memberIdProp,
  messages,
  onPaymentComplete = () => {},
  onPaymentError = () => {},
  payButtonText,
  payEndpoint,
  paymentDetailsEndpoint,
  paymentErrorMessage,
  paymentMethodsEndpoint,
  paymentModuleLoadingErrorMessage,
  paymentRefusedMessage,
  returnUrl,
  sessionId: sessionIdProp,
  warning,
}) => {
  const { checkIsExpired } = useCheckoutSessionContext();
  const checkoutRef = useRef<Core>();
  const [is3ds2, setIs3ds2] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSettingAdditionDetails, setIsSettingAdditionDetails] =
    useState(false);
  const [isSettingPaymentMethods, setIsSettingPaymentMethods] = useState(false);
  const [paymentMessages, setPaymentMessages] = useState(messages);
  const [paymentMethods, setPaymentMethods] = useState();
  const [query, setUrlState] = useUrlState();

  const [{ memberId, sessionId }, setIds] = useState({
    memberId: memberIdProp,
    sessionId: sessionIdProp,
  });

  const show3ds2 = () => {
    setIs3ds2(true);
    // NOTE: Disable page scrolling:
    document.body.style.position = 'fixed';
  };

  const hide3ds2 = () => {
    document.body.style.position = '';
    setIs3ds2(false);
  };

  // NOTE: Both payment submit and payment details submit share the same response logic on purpose. See https://docs.adyen.com/checkout/drop-in-web
  const handleResponse = (response: ApiResponse, dropin: DropinElement) => {
    if (!checkoutRef.current) {
      // NOTE: This should never happen
      return;
    }

    if (response.action) {
      switch (response.action.type) {
        case 'threeDS2':
        case 'threeDS2Challenge': // NOTE: To be deprecated.
        case 'threeDS2Fingerprint': {
          // NOTE: These types are handled separately from the default,
          // in order to be able to size the 3DS window
          show3ds2();
          checkoutRef.current
            .createFromAction(response.action, {
              challengeWindowSize: '05', // NOTE: '05' is 100% * 100%
            })
            .mount(`#${magic3dsId}`);
          break;
        }
        default: {
          dropin.handleAction(response.action);
          break;
        }
      }

      return;
    }

    switch (response.resultCode) {
      case 'Authorised': {
        setUrlState({ stale: 'true' });
        onPaymentComplete(response.pspReference);
        return;
      }
      case 'Refused': {
        setPaymentMessages([
          {
            text: paymentRefusedMessage,
            theme: 'error',
          },
        ]);

        if (checkoutRef.current) {
          checkoutRef.current.update();
        }

        break;
      }
      case 'Error':
      default: {
        setPaymentMessages([
          {
            text: paymentErrorMessage,
            theme: 'error',
          },
        ]);

        if (checkoutRef.current) {
          checkoutRef.current.update();
        }
      }
    }

    hide3ds2();
    onPaymentError();
    dropin.update(dropin.props);
  };

  const [isLoadingAdyen, hasAdyenLoaded] = useAdyen(() =>
    setPaymentMessages([
      {
        text: paymentModuleLoadingErrorMessage,
        theme: 'error',
      },
    ])
  );

  useEffect(() => {
    // NOTE: Props always "win" over query
    if (memberIdProp && sessionIdProp) {
      setIds({
        memberId: memberIdProp,
        sessionId: sessionIdProp,
      });
    } else if (query.memberId && query.sessionId) {
      setIds({
        memberId: String(query.memberId),
        sessionId: String(query.sessionId),
      });
    }
  }, [query, memberIdProp, sessionIdProp]);

  useEffect(() => {
    if (isMemberIdRequiredForIntegrations && !memberId) {
      return;
    }

    setIsSettingPaymentMethods(true);

    //@ts-ignore
    get<Parameters<typeof setPaymentMethods>[0]>(
      replaceQueryParameters(paymentMethodsEndpoint, {
        memberId,
        sessionId,
      })
    )
      .then(setPaymentMethods)
      .catch(checkIsExpired)
      .catch(() => {})
      .finally(() => setIsSettingPaymentMethods(false));
  }, [isMemberIdRequiredForIntegrations, memberId, sessionId]);

  const shouldShowSpinner = useMemo(
    () =>
      isLoadingAdyen ||
      isSettingAdditionDetails ||
      isSettingPaymentMethods ||
      isSubmitting,
    [
      isLoadingAdyen,
      isSettingAdditionDetails,
      isSettingPaymentMethods,
      isSubmitting,
    ]
  );

  useAsyncEffect(async () => {
    if (!hasAdyenLoaded || !window.AdyenCheckout || !paymentMethods) {
      return;
    }

    if (isMemberIdRequiredForIntegrations && !memberId) {
      return;
    }

    const translations = payButtonText
      ? {
          [`${locale}`]: {
            payButton: payButtonText,
          },
        }
      : undefined;

    const options: Parameters<typeof window.AdyenCheckout>[0] = {
      clientKey: adyenClientKey,
      environment: adyenEnv,
      locale,
      translations,
      onAdditionalDetails: (state: AdyenState, dropin: DropinElement) => {
        setIsSettingAdditionDetails(true);
        //@ts-ignore
        post<ApiResponse>(paymentDetailsEndpoint, {
          memberId,
          sessionId,
          paymentDetails: state.data,
        })
          .then(response => handleResponse(response, dropin))
          .catch(checkIsExpired)
          .catch(handleError)
          .finally(() => setIsSettingAdditionDetails(false));
      },
      onChange: (_state: AdyenState, _dropin: DropinElement) => {
        // NOTE: Handler added at Adyen's request, ref. PO Tina
        // https://satsdigital.slack.com/archives/C01SRUA17LZ/p1666088050997739
        console.debug(_state, _dropin); // eslint-disable-line no-console
      },
      onSubmit: (state: AdyenState, dropin: DropinElement) => {
        setIsSubmitting(true);
        setPaymentMessages([]);

        // NOTE: URLs provided by our web server are relative. Adyen needs an absolute URL.
        const redirectUrl = absoluteUrl(
          replaceQueryParameters(returnUrl, query)
        );

        //@ts-ignore
        post<ApiResponse>(payEndpoint, {
          memberId,
          sessionId,
          originUrl: `${window.location.protocol}//${window.location.host}`,
          browserInfo: state.data.browserInfo,
          billingAddress: state.data.billingAddress,
          redirectUrl,
          ...state.data.paymentMethod,
        })
          .then(response => handleResponse(response, dropin))
          .catch(checkIsExpired)
          .catch(handleError)
          .finally(() => setIsSubmitting(false));
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
        },
      },
      paymentMethodsResponse: paymentMethods,
    };

    const checkout = await window.AdyenCheckout(options);
    checkout.create('dropin').mount(`#${magicAdyenId}`);
    checkoutRef.current = checkout;
    setPaymentMessages([]);
  }, [
    hasAdyenLoaded,
    isMemberIdRequiredForIntegrations,
    memberId,
    paymentMethods,
    sessionId,
  ]);

  return (
    <div className="payment" data-adyen-client-key={adyenClientKey}>
      {shouldShowSpinner ? <Spinner theme={Spinner.themes.overlay} /> : null}
      {paymentMessages.map((message, index) => (
        <Message key={index} {...message} />
      ))}
      {warning ? (
        <div className="payment__warning">
          <Message text={warning} theme={Message.themes.warning} />
        </div>
      ) : null}
      {/* NOTE: We use `tabIndex` here so the tests can focus this element. */}
      <div data-adyen-mount-point id={magicAdyenId} tabIndex={-1} />

      <TabTrapper isActive={is3ds2}>
        <div
          aria-modal="true"
          role="dialog"
          className={cn('payment__3ds2-wrapper', {
            'payment__3ds2-wrapper--active': is3ds2,
          })}
        >
          {shouldShowSpinner ? (
            <Spinner theme={Spinner.themes.overlay} />
          ) : null}
          <div
            className="payment__3ds2-frame"
            data-adyen-3ds2-frame
            id={magic3dsId}
          />
        </div>
      </TabTrapper>
    </div>
  );
};

export default Payment;
