import React from 'react';

import Text from 'sats-ui-lib/react/text';

import PredefinedMembershipHeader from 'client/components/predefined-membership-header/predefined-membership-header';
import CampaignExpiredMessage from 'components/campaign-expired-message/campaign-expired-message';
import CheckoutLayoutHeader from 'components/checkout-layout-header/checkout-layout-header';
import ClubSearch from 'components/club-search/club-search';
import ContentContainer from 'components/content-container/content-container';
import Footer from 'components/footer/footer';
import List from 'components/list/list';
import RichText from 'components/rich-text/rich-text';
import Testimonials from 'components/testimonials/testimonials';

import type { PredefinedMembershipPage as Props } from './predefined-membership-page.types';

const PredefinedMembershipPage: React.FunctionComponent<Props> = ({
  active: {
    banner,
    clubSearchElementId,
    includedFeatures,
    richText,
    search,
    searchDescription,
    searchTitle,
    testimonials,
  },
  header,
  footer,
  inactive,
}) => (
  <div className="predefined-membership-page">
    <CheckoutLayoutHeader {...header} />
    <main className="predefined-membership-page__main">
      {inactive ? (
        <ContentContainer
          className="predefined-membership-page__inactive-campaign"
          theme={ContentContainer.themes.narrow}
        >
          <CampaignExpiredMessage {...inactive} />
        </ContentContainer>
      ) : (
        <>
          {banner ? (
            <ContentContainer
              className="predefined-membership-page__banner"
              raw
              theme={ContentContainer.themes.wide}
            >
              <PredefinedMembershipHeader {...banner} />
            </ContentContainer>
          ) : null}

          <ContentContainer
            className="predefined-membership-page__features"
            theme={ContentContainer.themes.narrow}
          >
            <Text size={Text.sizes.headline2} theme={Text.themes.emphasis}>
              {includedFeatures.title}
            </Text>
            <div className="predefined-membership-page__features-list">
              {includedFeatures.list.map(feature => (
                <List theme={List.themes.greenCheckmarks} key={feature}>
                  {feature}
                </List>
              ))}
            </div>
          </ContentContainer>

          <div className="predefined-membership-page__band">
            <ContentContainer theme={ContentContainer.themes.narrow}>
              <RichText {...richText} />
            </ContentContainer>
          </div>

          {testimonials ? (
            <ContentContainer className="predefined-membership-page__testimonials">
              <Testimonials {...testimonials} />
            </ContentContainer>
          ) : null}

          <ContentContainer
            className="predefined-membership-page__club-search"
            id={clubSearchElementId}
          >
            <Text
              className="predefined-membership-page__club-search-title"
              size={Text.sizes.headline2}
              theme={Text.themes.normal}
              elementName="h2"
            >
              {searchTitle}
            </Text>
            <Text
              className="predefined-membership-page__club-search-description"
              size={Text.sizes.basic}
              theme={Text.themes.normal}
            >
              {searchDescription}
            </Text>
            <ClubSearch {...search} />
          </ContentContainer>
        </>
      )}
    </main>

    <div className="predefined-membership-page__footer">
      <Footer {...footer} />
    </div>
  </div>
);

export default PredefinedMembershipPage;
