import React from 'react';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';

import type { TerminationError as Props } from './termination-error.types';

const TerminationError: React.FC<Props> = ({ layout }) => (
  <FlowTerminusLayout {...layout} />
);

export default TerminationError;
