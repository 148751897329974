import type { Block, Inline } from '@contentful/rich-text-types';

import type { FactBox } from 'components/fact-box/fact-box.types';

import type { RichText } from './rich-text.types';

export const toFactBoxProps = (node: Block | Inline): FactBox | undefined => {
  const heading = node?.data?.target?.fields?.heading;
  const body: RichText = { content: node?.data?.target?.fields?.body };

  if (heading && body.content) {
    return {
      heading,
      body,
    };
  }

  return undefined;
};
