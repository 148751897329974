import Forward from '@sats-group/icons/16/forward';
import cn from 'classnames';
import React from 'react';

import Tag from 'sats-ui-lib/react/tag';
import Text from 'sats-ui-lib/react/text';
import VisuallyButton from 'sats-ui-lib/react/visually-button';

import { MediaType } from 'shared/media-type-helper';

import BackgroundImage from 'components/background-image/background-image';
import BackgroundVideo from 'components/background-video/background-video';

import type { ArticleCard as Props } from './article-card.types';

const ArticleCard: React.FunctionComponent<Props> = ({
  href,
  linkText,
  media,
  tags = [],
  tall,
  text,
  title,
  type,
}) => (
  <a
    className={cn('article-card', {
      'article-card--tall': tall,
    })}
    href={href}
  >
    <div
      className={cn('article-card__inner', {
        [`article-card__inner--type-${type}`]: type,
      })}
    >
      <div className="article-card__media">
        {media.type === MediaType.Image ? (
          <BackgroundImage {...media.props} />
        ) : media.type === MediaType.Video ? (
          <BackgroundVideo {...media.props} />
        ) : null}
        <div className="article-card__media-minimum" />
      </div>
      <div className="article-card__content">
        {tags.length ? (
          <div className="article-card__tags">
            {tags.map(tag => (
              <Tag {...tag} key={tag.key} variant={Tag.variants.secondary} />
            ))}
          </div>
        ) : null}
        <Text theme={Text.themes.emphasis} size={Text.sizes.headline3} tight>
          {title}
        </Text>
        {text ? <Text tight>{text}</Text> : null}
      </div>
    </div>

    <div className="article-card__overlay">
      <Text theme={Text.themes.emphasis}>{linkText}</Text>
      <VisuallyButton
        ariaLabel={linkText}
        elementName="div"
        leadingIcon={<Forward />}
        role="img"
        variant={VisuallyButton.variants.secondaryWhite}
      />
    </div>
  </a>
);

export default ArticleCard;
