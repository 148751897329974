import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import Spinner from 'components/spinner/spinner';

import type { AvtaleGiroInitiatePage as Props } from './avtale-giro-initiate-page.types';

const AvtaleGiroInitiatePage: React.FC<Props> = ({
  endpoint,
  help,
  hiddenInputs,
  myPageLayout,
  nextStep,
  previousStep,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };
  return (
    <MyPageLayout {...myPageLayout}>
      <ContentContainer>
        {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
        <form
          action={endpoint}
          method="post"
          className="avtale-giro-initiate-page"
          onSubmit={handleSubmit}
        >
          <Text>{help}</Text>
          {hiddenInputs.map(input => (
            <HiddenInput {...input} key={input.name} />
          ))}
          <div className="avtale-giro-initiate-page__actions">
            <LinkButton
              {...previousStep}
              variant={LinkButton.variants.secondary}
            />
            <Button text={nextStep} type="submit" />
          </div>
        </form>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default AvtaleGiroInitiatePage;
