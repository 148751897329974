/** Helper to create tuples without to do explicit type casting */
function tuple<T1, T2, T3, T4, T5>(array: [T1, T2, T3, T4, T5]): typeof array;
function tuple<T1, T2, T3, T4>(array: [T1, T2, T3, T4]): typeof array;
function tuple<T1, T2, T3>(array: [T1, T2, T3]): typeof array;
function tuple<T1, T2>(array: [T1, T2]): typeof array;
function tuple<T1>(array: [T1]): typeof array;
function tuple(array: unknown) {
  return array;
}

export { tuple };
