import React from 'react';

import Text from 'sats-ui-lib/react/text';

import UpcomingTrainings from 'components/upcoming-trainings/upcoming-trainings';

import type { UpcomingTrainingsList as Props } from './upcoming-trainings-list.types';

const UpcomingTrainingsList: React.FunctionComponent<Props> = ({
  title,
  titleProps,
  upcomingTrainings,
}) => (
  <div className="upcoming-trainings-list">
    <Text {...titleProps}>{title}</Text>
    <UpcomingTrainings {...upcomingTrainings} />
  </div>
);

export default UpcomingTrainingsList;
