import React from 'react';

import ContentBlocks from 'components/content-blocks/content-blocks';
import ContentContainer from 'components/content-container/content-container';
import PreviewLayout from 'components/preview-layout/preview-layout';

import type { ContentBlockPreview as Props } from './content-block-preview.types';

const ContentBlockPreview: React.FunctionComponent<Props> = ({ blocks }) => (
  <PreviewLayout>
    <ContentContainer
      className="content-block-preview"
      theme={ContentContainer.themes.wide}
    >
      <ContentBlocks {...blocks} />
    </ContentContainer>
  </PreviewLayout>
);

export default ContentBlockPreview;
