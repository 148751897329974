import React from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { CorporateCodePreview as Props } from './corporate-code-preview.types';

const CorporateCodePreview: React.FunctionComponent<Props> = ({
  cancel,
  form,
  myPageLayout,
  priceSections,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="corporate-code-preview">
        {priceSections.map(section => (
          <div className="corporate-code-preview__section" key={section.title}>
            <Text size={Text.sizes.large} theme={Text.themes.normal}>
              {section.title}
            </Text>
            {section.description ? (
              <Text
                className="corporate-code-preview__description"
                size={Text.sizes.small}
              >
                {section.description}
              </Text>
            ) : null}
            {section.lineItems.map(line => (
              <div className="corporate-code-preview__line" key={line.label}>
                <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
                  {line.label}
                </Text>
                <Text
                  className={`corporate-code-preview__price corporate-code-preview__price--${line.price.type}`}
                  size={
                    line.price.type === 'new'
                      ? Text.sizes.headline2
                      : Text.sizes.headline3
                  }
                  theme={Text.themes.normal}
                >
                  {line.price.value}
                </Text>
              </div>
            ))}
            {section.message ? (
              <Message {...section.message} inline mini />
            ) : null}
          </div>
        ))}
        <div className="corporate-code-preview__actions">
          <LinkButton {...cancel} variant={LinkButton.variants.secondary} />
          {form ? (
            <form action={form.action} method="post">
              {form.hiddenInputs.map(input => (
                <HiddenInput key={input.name} {...input} />
              ))}
              <Button
                text={form.submit}
                type="submit"
                variant={Button.variants.cta}
              />
            </form>
          ) : null}
        </div>
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default CorporateCodePreview;
