import Lock from '@sats-group/icons/24/lock';
import React from 'react';

import CleanLink from 'client/components/clean-link/clean-link';
import ContentContainer from 'components/content-container/content-container';
import PersonalTrainerSessionShopLayout from 'components/personal-trainer-session-shop-layout/personal-trainer-session-shop-layout';

import type { PersonalTrainerSessionShopErrorPage as Props } from './personal-trainer-session-shop-error-page.types';

const PersonalTrainerSessionShopErrorPage: React.FunctionComponent<Props> = ({
  layout,
  link,
  text,
}) => (
  <PersonalTrainerSessionShopLayout {...layout}>
    <ContentContainer className="personal-trainer-session-shop-error-page">
      <div className="personal-trainer-session-shop-error-page__icon">
        <Lock />
      </div>
      {text}
      <CleanLink {...link} theme={CleanLink.themes.orange} />
    </ContentContainer>
  </PersonalTrainerSessionShopLayout>
);

export default PersonalTrainerSessionShopErrorPage;
