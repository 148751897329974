import cn from 'classnames';
import React from 'react';

import ElixiaLogo from 'sats-ui-lib/react/logos/elixia-small';
import SatsLogo from 'sats-ui-lib/react/logos/sats-small';

import CleanLink from 'client/components/clean-link/clean-link';

import type { CheckoutLogo as Props } from './checkout-logo.types';
import { brands, colors } from './checkout-logo.types';

const CheckoutLogo: React.FunctionComponent<Props> & {
  colors: typeof colors;
  brands: typeof brands;
} = ({ brand = brands.sats, color = colors.light, href, label }) => {
  const Wrapper = href
    ? ({ ...rest }) => (
        <CleanLink
          aria-label={label}
          className="checkout-logo__link"
          href={href}
          {...rest}
        />
      )
    : React.Fragment;

  return (
    <div
      className={cn('checkout-logo', {
        'checkout-logo--dark': color === colors.dark,
      })}
    >
      <Wrapper>{brand === brands.sats ? <SatsLogo /> : <ElixiaLogo />}</Wrapper>
    </div>
  );
};

CheckoutLogo.colors = colors;
CheckoutLogo.brands = brands;

export default CheckoutLogo;
