import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ArticleCard from 'client/components/article-card/article-card';
import ComponentList from 'client/components/component-list/component-list';
import ContentBlocks from 'client/components/content-blocks/content-blocks';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';

import type { ArticlesSubcategoryPage as Props } from './articles-subcategory-page.types';

const ArticlesSubcategoryPage: React.FunctionComponent<Props> = ({
  blocks,
  articleList,
  intro,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="articles-subcategory-page">
      <ContentContainer theme={ContentContainer.themes.wide}>
        <Text
          className="articles-subcategory-page__title"
          elementName="h1"
          theme={Text.themes.headline}
          italic
          size={Text.sizes.headline2}
        >
          {title}
        </Text>
      </ContentContainer>
      {intro ? (
        <ContentContainer>
          <Text
            className="articles-subcategory-page__intro"
            elementName="p"
            theme={Text.themes.normal}
            size={Text.sizes.basic}
          >
            {intro}
          </Text>
        </ContentContainer>
      ) : null}
      <ContentContainer theme={ContentContainer.themes.wide}>
        <div className="articles-subcategory-page__content">
          {blocks ? <ContentBlocks {...blocks} /> : null}
          {articleList ? (
            <ComponentList {...articleList} entryRenderer={ArticleCard} />
          ) : null}
        </div>
      </ContentContainer>
    </div>
  </Layout>
);

export default ArticlesSubcategoryPage;
