import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LogoElixia from 'sats-ui-lib/react/logos/elixia';
import LogoSats from 'sats-ui-lib/react/logos/sats';
import Message from 'sats-ui-lib/react/message';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import CleanLink from 'client/components/clean-link/clean-link';
import ContentContainer from 'components/content-container/content-container';
import Spinner from 'components/spinner/spinner';

import type { LoginPage as Props } from './login-page.types';
import { brands } from './login-page.types';

const LoginPage: React.FunctionComponent<Props> = ({
  brand,
  endpoint,
  hiddenInputs,
  inputs,
  messages,
  resetPasswordLink,
  submitLabel,
  text,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    if (isLoading) {
      e.preventDefault();
    }

    setIsLoading(true);
  };

  return (
    <main className="login-page">
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div className="login-page__card">
          <div className="login-page__logo">
            {brand === brands.sats ? <LogoSats /> : <LogoElixia />}
          </div>
          <Text
            className="login-page__info"
            elementName="h1"
            size={Text.sizes.headline3}
          >
            {title}
          </Text>
          <div className="login-page__info">
            <Text>{text}</Text>
          </div>
          {messages.length ? (
            <div className="login-page__messages">
              {messages.map(message => (
                <Message key={message.text} {...message} />
              ))}
            </div>
          ) : null}
          <form action={endpoint} method="post" onSubmit={handleSubmit}>
            {isLoading ? <Spinner theme={Spinner.themes.overlay} /> : null}
            {hiddenInputs.map(input => (
              <HiddenInput {...input} key={input.name} />
            ))}
            <div className="login-page__inputs">
              {inputs.map(input => (
                <div key={input.name}>
                  <TextInput {...input} />
                </div>
              ))}
            </div>
            <div className="login-page__actions">
              <Button
                type="submit"
                text={submitLabel}
                size={Button.sizes.large}
              />
            </div>
          </form>
          <div className="login-page__reset">
            <CleanLink {...resetPasswordLink} />
          </div>
        </div>
      </ContentContainer>
    </main>
  );
};

export default LoginPage;
