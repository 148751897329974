import React from 'react';

import ContentBlocks from 'components/content-blocks/content-blocks';
import ContentContainer from 'components/content-container/content-container';
import Hero from 'components/hero/hero';
import Layout from 'components/layout/layout';

import type { PersonalTrainerLandingPage as Props } from './personal-trainer-landing-page.types';

const PersonalTrainerLandingPage: React.FunctionComponent<Props> = ({
  blocks,
  hero,
  layout,
}) => (
  <Layout {...layout}>
    <div className="personal-trainer-landing-page">
      {hero ? <Hero {...hero} /> : null}
      {blocks ? (
        <section className="personal-trainer-landing-page__blocks">
          <ContentContainer theme={ContentContainer.themes.wide}>
            <ContentBlocks {...blocks} />
          </ContentContainer>
        </section>
      ) : null}
    </div>
  </Layout>
);

export default PersonalTrainerLandingPage;
