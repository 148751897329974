import React from 'react';

import Modal from 'sats-ui-lib/react/modal';
import Text from 'sats-ui-lib/react/text';

import RichText from 'components/rich-text/rich-text';

import type { CheckoutSummaryModal as Props } from './checkout-summary-modal.types';

const CheckoutSummaryModal: React.FunctionComponent<Props> = ({
  hide = () => {},
  modal,
  subTitle,
  text,
}) => (
  <Modal onClose={hide} {...modal}>
    <div className="checkout-summary-modal">
      {subTitle ? (
        <Text
          className="checkout-summary-modal__subtitle"
          size={Text.sizes.small}
        >
          {subTitle}
        </Text>
      ) : null}
      {text ? (
        <RichText
          className="checkout-summary-modal__text"
          theme={RichText.themes.whiteCheckmarkList}
          {...text}
        />
      ) : null}
    </div>
  </Modal>
);

export default CheckoutSummaryModal;
