import type { BackgroundImage } from 'client/components/background-image/background-image.types';
import type { BackgroundVideo } from 'client/components/background-video/background-video.types';

export enum MediaType {
  Image = 'image',
  Video = 'video',
}

export type ImageMedia = {
  props: BackgroundImage;
  type: MediaType.Image;
};

export type VideoMedia = {
  props: BackgroundVideo;
  type: MediaType.Video;
};
