import type { ObjectValues } from 'client/types';
import type { DynamicImage } from 'components/dynamic-image/dynamic-image.types';
import type { EmbedVideo } from 'components/embed-video/embed-video.types';
import type { EmbedYoutube } from 'components/embed-youtube/embed-youtube.types';

export const themes = {
  paddedButtons: 'padded-buttons',
} as const;

export type Media = {
  buttonText: string;
  isPrimaryButton?: boolean;
  video?: EmbedVideo;
  youTube?: EmbedYoutube;
};

export type EmbedMedia = {
  error?: string;
  media: Media[];
  poster?: DynamicImage;
  theme?: ObjectValues<typeof themes>;
};

export default EmbedMedia;
