import { useEffect, useRef } from 'react';

/** Returns a ref that indicates whether the component is currently mounted. Useful for solving some edge cases in Promise chains when components unmount before the Promise is resolved. */
export default function useDidMount() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}
