import type { Block, Inline } from '@contentful/rich-text-types';

export type ImageLink = { href: string; imageSrc: string; text: string };

export const toImageLinkProps = (
  node: Block | Inline
): ImageLink | undefined => {
  if (!node || !node.data || !node.data.target || !node.data.target.fields) {
    return;
  }

  const { href, imageSrc, text } = node.data.target.fields;

  if (href && imageSrc && text) {
    return {
      href,
      text,
      imageSrc,
    };
  }
};
