import React, { useState } from 'react';

import Button from 'sats-ui-lib/react/button';
import HiddenInput from 'sats-ui-lib/react/hidden-input';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';
import TextInput from 'sats-ui-lib/react/text-input';

import ContentContainer from 'components/content-container/content-container';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import Spinner from 'components/spinner/spinner';
import TextInterpolator from 'components/text-interpolator/text-interpolator';

import type { FreezeProvideReasonPage as Props } from './freeze-provide-reason-page.types';

const FreezeProvideReasonPage: React.FunctionComponent<Props> = ({
  action,
  characterLimit,
  hiddenInputs,
  myPageLayout,
  provideReasonText,
  reason,
  reasonInput,
  previousPage,
  nextPage,
}) => {
  const [charactersRemaining, setCharactersRemaining] = useState(
    characterLimit.inputLimit
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) {
      event.preventDefault();
    }

    setIsSubmitting(true);
  };

  return (
    <MyPageLayout {...myPageLayout}>
      {isSubmitting ? <Spinner theme={Spinner.themes.overlay} /> : null}
      <ContentContainer>
        <div className="freeze-provide-reason-page">
          <Text className="freeze-provide-reason-page__text" elementName="p">
            {provideReasonText}
          </Text>
          <form action={action} method="GET" onSubmit={handleSubmit}>
            {hiddenInputs.map(input => (
              <HiddenInput {...input} key={input.name} />
            ))}
            <div className="freeze-provide-reason-page__text-input">
              <TextInput
                data-test-write-reason
                defaultValue={reason}
                onChange={e =>
                  setCharactersRemaining(
                    characterLimit.inputLimit - e.target.value.length
                  )
                }
                {...reasonInput}
              />
            </div>

            <Text
              className="freeze-provide-reason-page__character-limit-text"
              elementName="p"
            >
              <TextInterpolator
                template={characterLimit.limitText}
                tokens={{ characterLimit: String(charactersRemaining) }}
              />
            </Text>
            <LinkButton
              className="freeze-provide-reason-page__previous"
              {...previousPage}
              variant={LinkButton.variants.secondary}
            />
            <Button data-test-submit type="submit" text={nextPage} />
          </form>
        </div>
      </ContentContainer>
    </MyPageLayout>
  );
};

export default FreezeProvideReasonPage;
