import Facebook from '@sats-group/icons/32/facebook';
import Instagram from '@sats-group/icons/32/instagram';
import Linkedin from '@sats-group/icons/32/linkedin';
import Youtube from '@sats-group/icons/32/youtube';
import React from 'react';

import type { Icon, SocialIcons as Props } from './social-icons.types';

const icons: Record<Icon, React.FunctionComponent> = {
  facebook: Facebook,
  instagram: Instagram,
  linkedIn: Linkedin,
  youTube: Youtube,
};

const SocialIcons: React.FunctionComponent<Props> = ({ links }) => (
  <div className="social-icons">
    <div className="social-icons__group">
      {links.map(({ href, icon, title }) => (
        <a className="social-icons__icon" key={href} href={href} title={title}>
          {React.createElement(icons[icon])}
        </a>
      ))}
    </div>
  </div>
);

export default SocialIcons;
