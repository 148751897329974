import React from 'react';

import Text from 'sats-ui-lib/react/text';

import BackgroundImage from 'components/background-image/background-image';
import ContentContainer from 'components/content-container/content-container';
import DynamicMessage from 'components/dynamic-message/dynamic-message';
import Logo from 'components/logo/logo';
import CheckoutSessionContextProvider from 'contexts/checkout-session-context-provider';

import type { PersonalTrainerSessionShopLayout as Props } from './personal-trainer-session-shop.types';

const PersonalTrainerSessionShopLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ children, image, logo, message, subTitle, title }) => (
  <CheckoutSessionContextProvider>
    <div className="personal-trainer-session-shop-layout">
      <DynamicMessage {...message} />
      <header className="personal-trainer-session-shop-layout__band">
        <ContentContainer className="personal-trainer-session-shop-layout__band-content">
          <Logo {...logo} />
        </ContentContainer>
      </header>
      <main>
        <div className="personal-trainer-session-shop-layout__header">
          {image ? (
            <BackgroundImage
              className="personal-trainer-session-shop-layout__header-image"
              {...image}
            />
          ) : null}
          <div className="personal-trainer-session-shop-layout__header-text">
            <ContentContainer>
              <Text
                elementName="h1"
                theme={Text.themes.headline}
                italic
                size={Text.sizes.headline3}
              >
                {title}
              </Text>
              <Text size={Text.sizes.headline3}>{subTitle}</Text>
            </ContentContainer>
          </div>
        </div>
        <div className="personal-trainer-session-shop-layout__content">
          {children}
        </div>
      </main>
    </div>
  </CheckoutSessionContextProvider>
);

export default PersonalTrainerSessionShopLayout;
