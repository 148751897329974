import cn from 'classnames';
import React from 'react';

import UniqueSellingPoint from '../unique-selling-point/unique-selling-point';

import type { UspList as Props } from './usp-list.types';

const UspList: React.FunctionComponent<Props> = ({ actions, entries }) => (
  <div className="usp-list">
    <div
      className={cn(
        'usp-list__entries',
        `usp-list__entries--${entries.length < 5 ? entries.length : 4}`,
        { 'usp-list__entries--actions': actions }
      )}
    >
      {entries.map(entry => (
        <div className="usp-list__entry" key={entry.title}>
          <UniqueSellingPoint {...entry} tall />
        </div>
      ))}
    </div>
  </div>
);

export default UspList;
