import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import MyMembershipCards from 'components/my-membership-cards/my-membership-cards';
import MyPageLayout from 'components/my-page-layout/my-page-layout';

import type { MyOtherMembersPage as Props } from './my-other-members-page.types';

const MyPage: React.FunctionComponent<Props> = ({
  memberships,
  metadata,
  myPageLayout,
}) => (
  <MyPageLayout {...myPageLayout}>
    <ContentContainer>
      <div className="my-other-members-page">
        {metadata ? <Text>{metadata}</Text> : null}
        <MyMembershipCards {...memberships} />
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default MyPage;
