import React from 'react';
import Banner from 'sats-ui-lib/react/banner';
import Text from 'sats-ui-lib/react/text';

import ClubElement from '../club-element/club-element';

import type { NewClubCard as Props } from './new-club-card.types';

const NewClubCard: React.FunctionComponent<Props> = ({
  banner,
  club,
  title,
}) => (
  <div className="new-club-card">
    <div className="new-club-card__title">
      <Text size={Text.sizes.large} theme={Text.themes.emphasis} tight>
        {title}
      </Text>
    </div>
    {banner ? <Banner {...banner} icon nested /> : null}
    <ClubElement {...club} small />
  </div>
);

export default NewClubCard;
