// NOTE: This is copied from the source of 'classnames' and modified to produce BEM modifier classes. (source: https://github.com/JedWatson/classnames/blob/master/index.js)

/** Creates modifier classnames of the provided values. The API is similar to `classnames`. All values passed will be prefixed by `className` (the first argument)
 *
 * Note that unlike with `classnames`, when an array is passed all elements must be strings

```
mod("block__element", "something", { "is-active": true });
// Returns "block__element block__element--something block__element--is-active"
```
 */

type Args =
  | number
  | string
  | string[]
  | Record<string, boolean | undefined>
  | undefined;

export function mod(...args: Args[]) {
  const baseName = args[0];
  if (!baseName) {
    return '';
  }

  const classes = [baseName];

  for (let i = 1; i < args.length; i++) {
    const arg = args[i];
    if (!arg) {
      continue;
    }

    if (typeof arg === 'string' || typeof arg === 'number') {
      classes.push(baseName + '--' + arg);
      continue;
    }

    if (Array.isArray(arg)) {
      arg.forEach(el => {
        if (el) {
          classes.push(baseName + '--' + el);
        }
      });
      continue;
    }

    if (typeof arg === 'object') {
      Object.entries(arg).forEach(([key, value]) => {
        if (value) {
          classes.push(baseName + '--' + key);
        }
      });
    }
  }

  return classes.join(' ');
}
