import React from 'react';

import ContentContainer from 'components/content-container/content-container';

// NOTE: This file very visibly has multiple components so we ignore the rule
/* eslint-disable react/no-multi-comp */

type FcWithChildren = React.FunctionComponent<React.PropsWithChildren<unknown>>;

const Content: FcWithChildren = ({ children }) => (
  <div className="sidebar-layout__content">{children}</div>
);

const Sidebar: FcWithChildren = ({ children }) => (
  <div className="sidebar-layout__sidebar">{children}</div>
);

const Top: FcWithChildren = ({ children }) => (
  <div className="sidebar-layout__top">{children}</div>
);

const SidebarLayout: FcWithChildren & {
  Top: typeof Top;
  Content: typeof Content;
  Sidebar: typeof Sidebar;
} = ({ children }) => (
  <ContentContainer className="sidebar-layout">{children}</ContentContainer>
);

SidebarLayout.Top = Top;
SidebarLayout.Content = Content;
SidebarLayout.Sidebar = Sidebar;

export default SidebarLayout;
