import cn from 'classnames';
import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import type { ComponentList as Props } from './component-list.types';

const ComponentList = <T extends { tall?: boolean }>({
  actions,
  description,
  entryRenderer,
  entries,
  title,
}: Props<T> & { entryRenderer: React.FC<T> }) => {
  const EntryRenderer = entryRenderer;
  return (
    <div className="component-list">
      {description || title ? (
        <div className="component-list__texts">
          {title ? (
            <Text
              elementName="h2"
              size={Text.sizes.headline2}
              theme={Text.themes.emphasis}
              tight
            >
              {title}
            </Text>
          ) : null}
          {description ? (
            <Text elementName="p" size={Text.sizes.basic} tight>
              {description}
            </Text>
          ) : null}
        </div>
      ) : null}
      <div
        className={cn('component-list__entries', {
          'component-list__entries--duo': entries.length === 2,
        })}
      >
        {entries.map(item => (
          <div className="component-list__entry" key={item.key}>
            <EntryRenderer {...item.props} tall />
          </div>
        ))}
      </div>
      {actions.length ? (
        <div className="component-list__actions">
          {actions.map(action => (
            <div key={action.href}>
              <LinkButton {...action} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ComponentList;
