import type { ObjectValues } from 'client/types';
import type { Logo } from 'components/logo/logo.types';

export const themes = {
  dark: 'dark',
  light: 'light',
} as const;

export type CheckoutLayoutHeader = {
  bullets: string[];
  logo: Logo;
  theme: ObjectValues<typeof themes>;
};
