import { useRef } from 'react';

import { tuple } from 'shared/tuple';

const useFocus = <ElementType extends HTMLElement = HTMLElement>() => {
  const ref = useRef<ElementType>(null);
  const setFocus = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  return tuple([ref, setFocus]);
};

export default useFocus;
