import React, { useEffect, useState } from 'react';

import { toQueryString } from 'shared/to-query-string';
import { tuple } from 'shared/tuple';

import type { EmbeddedAssetImage as Props } from './embedded-asset-image.types';

// NOTE: See docs at https://www.contentful.com/developers/docs/references/images-api/
// "The maximum allowed value is 4000 pixels.".
// Larger values result in error responses.
const maximumPixels = 4000;

const imageQuery = (width: number) =>
  toQueryString({
    fit: 'fill',
    w: Math.ceil(Math.min(width, maximumPixels)),
    q: 75,
  });

const useBoundingClientRect = () => {
  const [element, setElement] = useState<HTMLElement | null>();
  const [rect, setRect] = useState<DOMRect>();

  useEffect(() => {
    if (element) {
      setRect(element.getBoundingClientRect());
    }
  }, [element]);

  return tuple([setElement, rect]);
};

const useDpr = () => {
  const [dpr, setDpr] = useState(1);

  useEffect(() => {
    setDpr(window.devicePixelRatio);
  }, []);

  return tuple([dpr]);
};

const useImageQuery = (width: number) => {
  const [setElement, rect] = useBoundingClientRect();
  const [dpr] = useDpr();
  const w = rect ? rect.width * dpr : width;

  return tuple([setElement, imageQuery(w)]);
};

const EmbeddedAssetImage: React.FC<Props> = ({ alt, src }) => {
  const [setElement, query] = useImageQuery(150);

  return (
    <div className="embedded-asset-image" ref={setElement}>
      <img alt={alt} loading="lazy" src={`${src}${query}`} />
    </div>
  );
};

export default EmbeddedAssetImage;
