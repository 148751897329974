import React from 'react';

import Text from 'sats-ui-lib/react/text';

import FlowTerminusLayout from 'components/flow-terminus-layout/flow-terminus-layout';
import type { PtShopConfirmation as Props } from './pt-shop-confirmation.types';

const PtShopConfirmation: React.FC<Props> = ({
  information,
  layout,
  paymentInformation,
  productInformation,
}) => (
  <FlowTerminusLayout {...layout}>
    <div className="pt-shop-confirmation">
      <Text>{information}</Text>
      <dl className="pt-shop-confirmation__info">
        {productInformation.map(entry => (
          <div className="pt-shop-confirmation__line-item" key={entry.name}>
            <Text elementName="dt" theme={Text.themes.emphasis}>
              {entry.name}
            </Text>
            <Text elementName="dd">{entry.value}</Text>
          </div>
        ))}
      </dl>
      <dl className="pt-shop-confirmation__info">
        {paymentInformation.map(entry => (
          <div className="pt-shop-confirmation__line-item" key={entry.name}>
            <Text elementName="dt" theme={Text.themes.emphasis}>
              {entry.name}
            </Text>
            <Text elementName="dd">{entry.value}</Text>
          </div>
        ))}
      </dl>
    </div>
  </FlowTerminusLayout>
);

export default PtShopConfirmation;
