import React from 'react';

import Button from 'sats-ui-lib/react/button';
import LinkButton from 'sats-ui-lib/react/link-button';
import LinkCard from 'sats-ui-lib/react/link-card';
import Text from 'sats-ui-lib/react/text';

import { trackEvent } from 'client/helpers/tracking';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import Categories from 'components/categories/categories';
import ContactUs from 'components/contact-us/contact-us';
import ContentContainer from 'components/content-container/content-container';
import Layout from 'components/layout/layout';
import RichText from 'components/rich-text/rich-text';
import { useSessionStorage } from 'hooks/use-storage';

import type { MemberCareArticlePage as Props } from './member-care-article-page.types';

const MemberCareArticlePage: React.FunctionComponent<Props> = ({
  breadcrumbs,
  contactUs,
  feedbackResponseText,
  feedbackTitle,
  layout,
  linkCards,
  links,
  negativeFeedbackText,
  otherCategories,
  positiveFeedbackText,
  storageKey,
  text,
  title,
  updated,
  updatedLabel,
}) => {
  const [hasGivenFeedback, setHasGivenFeedback] = useSessionStorage(storageKey);

  const handleFeedback = (memberCareHelpful: 'Yes' | 'No') => {
    setHasGivenFeedback('true');
    trackEvent({ event: 'memberCare', memberCareHelpful });
  };

  const handlePositiveFeedback = () => handleFeedback('Yes');
  const handleNegativeFeedback = () => handleFeedback('No');

  return (
    <Layout {...layout}>
      <div className="member-care-article-page">
        <Breadcrumbs {...breadcrumbs} />
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <Text
            className="member-care-article-page__title"
            elementName="h1"
            size={Text.sizes.headline1}
            theme={Text.themes.emphasis}
          >
            {title}
          </Text>

          <RichText theme={RichText.themes.blueLinks} {...text} />
          <div className="member-care-article-page__links">
            {links.map(link => (
              <div key={link.href}>
                <LinkButton {...link} />
              </div>
            ))}
          </div>
          {linkCards.length ? (
            <div className="member-care-article-page__link-cards">
              {linkCards.map(card => (
                <div key={card.href}>
                  <LinkCard {...card} />
                </div>
              ))}
            </div>
          ) : null}
          <div className="member-care-article-page__metadata">
            <Text size={Text.sizes.small}>
              {updatedLabel}: {updated}
            </Text>
          </div>
          <div className="member-care-article-page__feedback">
            <Text className="member-care-article-page__feedback-title">
              {feedbackTitle}
            </Text>
            {hasGivenFeedback ? (
              <Text
                size={Text.sizes.small}
                elementName="div"
                className="member-care-article-page__feedback-response"
              >
                {feedbackResponseText}
              </Text>
            ) : (
              <div>
                <Button
                  className="member-care-article-page__button"
                  variant={Button.variants.secondary}
                  onClick={handlePositiveFeedback}
                  text={positiveFeedbackText}
                />
                <Button
                  className="member-care-article-page__button"
                  variant={Button.variants.secondary}
                  onClick={handleNegativeFeedback}
                  text={negativeFeedbackText}
                />
              </div>
            )}
          </div>
        </ContentContainer>
        <ContentContainer theme={ContentContainer.themes.narrow}>
          <div className="member-care-article-page__contact-us">
            <ContactUs {...contactUs} mode="closed" />
          </div>
        </ContentContainer>
        {otherCategories ? (
          <div className="member-care-article-page__other-categories">
            <ContentContainer theme={ContentContainer.themes.narrow}>
              <Categories {...otherCategories} />
            </ContentContainer>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default MemberCareArticlePage;
