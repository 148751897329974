import { supported } from '@mapbox/mapbox-gl-supported';
import type mapboxgl from 'mapbox-gl';
import { useEffect, useState } from 'react';

import { appendScript } from 'client/helpers/append-script';
import { appendStylesheet } from 'client/helpers/append-stylesheet';

/**
 * https://docs.mapbox.com/mapbox-gl-js/guides/install/
 */
export const useMapbox = () => {
  const [state, setState] = useState<{
    error: boolean;
    loading: boolean;
    mapbox?: typeof mapboxgl;
    supported: boolean;
  }>({
    error: false,
    loading: true,
    supported: true,
  });

  useEffect(() => {
    const s = supported();
    if (!s) {
      setState(prev =>
        Object.assign({}, prev, {
          loading: false,
          supported: s,
        })
      );
      return;
    }

    if (state.mapbox) {
      setState(prev =>
        Object.assign({}, prev, {
          loading: false,
        })
      );
      return;
    }

    appendStylesheet(
      'https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.css'
    );

    appendScript(
      'https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.js',
      () =>
        setState(prev =>
          Object.assign({}, prev, {
            loading: false,
            mapbox: window.mapboxgl,
          })
        ),
      {
        onError: () =>
          setState(prev =>
            Object.assign({}, prev, {
              loading: false,
              error: true,
            })
          ),
      }
    );
  }, []);

  return state;
};
