import Download from '@sats-group/icons/18/download';
import React from 'react';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import Price from '../price/price';
import type { Debt as Props } from './debt.types';

const Debt: React.FunctionComponent<Props> = ({
  action,
  amount,
  document,
  id,
  name,
}) => (
  <div className="debt" data-id={id}>
    <div className="debt__content">
      <div className="debt__primary">
        <div>
          <Text>{name}</Text>
        </div>
        <div>
          <LinkButton
            {...document}
            rel="noopener noreferrer"
            size={LinkButton.sizes.small}
            variant={LinkButton.variants.tertiary}
            target="_blank"
            trailingIcon={<Download />}
          />
        </div>
      </div>
      <div className="debt__secondary">
        <div>
          <Price {...amount} small />
        </div>
        <div>
          <LinkButton {...action} />
        </div>
      </div>
    </div>
  </div>
);

export default Debt;
