import ChevronUp from '@sats-group/icons/24/arrow-up';
import React from 'react';
import Collapse from 'react-tiny-collapse';

import useToggle from 'sats-ui-lib/react/hooks/use-toggle';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import { mod } from 'client/helpers/add-bem-modifiers';
import List from 'components/list/list';
import Price from 'components/price/price';

import type { Summary as Props } from './summary.types';

const Summary: React.FunctionComponent<Props> = ({
  discount,
  next,
  splitPayment,
  title,
  products = [],
  productsTitle,
  price,
  priceTitle,
}) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <div className="personal-trainer-select-session-page__summary">
      <div className="personal-trainer-select-session-page__summary-content">
        <button
          className="personal-trainer-select-session-page__trigger"
          onClick={toggle}
        >
          <div
            className={mod(
              'personal-trainer-select-session-page__trigger-icon',
              {
                flipped: isOpen,
              }
            )}
          >
            <ChevronUp />
          </div>
          <Text
            elementName="h2"
            size={Text.sizes.basic}
            theme={Text.themes.emphasis}
          >
            {title}
          </Text>
        </button>
        <Collapse
          className="personal-trainer-select-session-page__summary-collapse"
          isOpen={isOpen}
        >
          <div>
            <div className="personal-trainer-select-session-page__product-summary">
              <Text
                className="personal-trainer-select-session-page__summary-heading"
                elementName="h2"
                size={Text.sizes.headline3}
                theme={Text.themes.normal}
              >
                {title}
              </Text>
              <div className="personal-trainer-select-session-page__product-summary-products">
                <Text
                  size={Text.sizes.small}
                  className="personal-trainer-select-session-page__product-summary-products-title"
                >
                  {productsTitle}
                </Text>
                <List theme={List.themes.greenCheckmarks}>
                  {products.map(item => (
                    <Text size={Text.sizes.small} key={item}>
                      {item}
                    </Text>
                  ))}
                </List>
              </div>
            </div>
            {discount ? (
              <Text
                size={Text.sizes.small}
                className="personal-trainer-select-session-page__summary-discount"
              >
                {discount}
              </Text>
            ) : undefined}
          </div>
        </Collapse>
      </div>
      <div className="personal-trainer-select-session-page__summary-price">
        {price ? (
          <div className="personal-trainer-select-session-page__price">
            <div className="personal-trainer-select-session-page__price-title">
              <Text
                elementName="h3"
                size={Text.sizes.small}
                theme={Text.themes.emphasis}
              >
                {priceTitle}
              </Text>
            </div>
            <div className="personal-trainer-select-session-page__price-content">
              {price.secondary ? (
                <div className="personal-trainer-select-session-page__price-discounted">
                  <Text
                    size={Text.sizes.basic}
                    theme={Text.themes.emphasis}
                    elementName="span"
                    className="personal-trainer-select-session-page__amount-discounted"
                  >
                    {price.secondary.amount}
                  </Text>
                  {price.secondary.currency ? (
                    <div className="personal-trainer-select-session-page__web-view">
                      <Text
                        size={Text.sizes.small}
                        theme={Text.themes.emphasis}
                        elementName="span"
                        className="personal-trainer-select-session-page__currency-discounted"
                      >
                        {price.secondary.currency}
                      </Text>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {price.highlighted ? (
                <>
                  <div className="personal-trainer-select-session-page__web-view">
                    <Price {...price.highlighted} />
                  </div>
                  <div className="personal-trainer-select-session-page__mobile-view">
                    <Price {...price.highlighted} />
                  </div>
                </>
              ) : null}
              {price.perSession ? (
                <>
                  <div className="personal-trainer-select-session-page__web-view">
                    <div className="personal-trainer-select-session-page__price-per-session">
                      <Text elementName="span" theme={Text.themes.emphasis}>
                        {price.perSession.price}
                      </Text>
                      <Text elementName="span">{price.perSession.text}</Text>
                    </div>
                  </div>
                  <div className="personal-trainer-select-session-page__mobile-view">
                    <div className="personal-trainer-select-session-page__price-per-session">
                      <Text
                        elementName="span"
                        theme={Text.themes.emphasis}
                        size={Text.sizes.basic}
                      >
                        {price.perSession.price}
                      </Text>
                      <Text elementName="span" size={Text.sizes.basic}>
                        {price.perSession.text}
                      </Text>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {next?.href ? (
          <LinkButton
            data-test-to-payment
            className="personal-trainer-select-session-page__next"
            {...next}
            variant={LinkButton.variants.cta}
          />
        ) : undefined}
      </div>
      {splitPayment ? (
        <div className="personal-trainer-select-session-page__summary-split-payment">
          <Text size={Text.sizes.interface}>{splitPayment}</Text>
        </div>
      ) : undefined}
    </div>
  );
};

export default Summary;
