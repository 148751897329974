import React from 'react';

import Text from 'sats-ui-lib/react/text';

import DynamicImage from 'components/dynamic-image/dynamic-image';

import type { Author as Props } from './author.types';

const Author: React.FunctionComponent<Props> = ({
  image,
  jobTitle,
  name,
  publishDate,
}) => (
  <div className="author">
    <div>
      {image ? (
        <DynamicImage
          aria-hidden="true"
          className="author__image"
          theme={DynamicImage.themes.round}
          {...image}
        />
      ) : null}
    </div>
    <div className="author__info">
      <Text className="author__name" theme={Text.themes.emphasis}>
        {name}
      </Text>
      <Text className="author__title" size={Text.sizes.interface}>
        {jobTitle ? jobTitle : null}
        {publishDate ? publishDate : null}
      </Text>
    </div>
  </div>
);

export default Author;
