import Barbell from '@sats-group/icons/24/barbell';
import Body from '@sats-group/icons/24/body';
import Location from '@sats-group/icons/24/location';
import Person from '@sats-group/icons/24/person';
import ClockMini from '@sats-group/icons/24/time';
import React from 'react';

import Text from 'sats-ui-lib/react/text';

import type { ActivityMetadata as Props } from './activity-metadata.types';

const ActivityMetadata: React.FunctionComponent<Props> = ({
  bodypart,
  equipment,
  instructors,
  time,
  space,
}) => (
  <Text className="activity-metadata" size={Text.sizes.small}>
    {time ? (
      <div className="activity-metadata__item">
        <div className="activity-metadata__icon">
          <ClockMini />
        </div>
        {time}
      </div>
    ) : null}
    {space ? (
      <div className="activity-metadata__item">
        <div className="activity-metadata__icon">
          <Location />
        </div>
        {space}
      </div>
    ) : null}
    {bodypart ? (
      <div className="activity-metadata__item">
        <div className="activity-metadata__icon">
          <Body />
        </div>
        {bodypart}
      </div>
    ) : null}
    {equipment ? (
      <div className="activity-metadata__item">
        <div className="activity-metadata__icon">
          <Barbell />
        </div>
        {equipment}
      </div>
    ) : null}
    {instructors ? (
      <div className="activity-metadata__item">
        <div className="activity-metadata__icon">
          <Person />
        </div>
        {instructors}
      </div>
    ) : null}
  </Text>
);

export default ActivityMetadata;
