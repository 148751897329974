import type { MessageDetail } from './messages.types';

type Listener = (event: MessageDetail) => void;

const eventName = 'user-message';

export const publish = (message: MessageDetail) => {
  if (!message) {
    return;
  }

  window.dispatchEvent(new CustomEvent(eventName, { detail: message }));
};

const subscriber = (listener: Listener) => (event: CustomEvent) =>
  listener(event.detail);

export const subscribe = (listener: Listener) => {
  window.addEventListener(eventName, subscriber(listener));
  return () => window.removeEventListener(eventName, subscriber(listener));
};
