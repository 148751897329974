import React from 'react';

import interpolate from 'shared/interpolate';

import type { TextInterpolator as Props } from './text-interpolator.types';

const TextInterpolator: React.FunctionComponent<Props> = ({
  template,
  tokens,
}) => <>{interpolate(template, tokens)}</>;

export default TextInterpolator;
