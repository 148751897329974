import React, { useCallback, useState } from 'react';

import { replaceQueryParameters } from 'shared/replace-query-parameters';

import { del, get, post } from 'client/helpers/api-helper';
import { publish } from 'client/helpers/messages';

import type {
  FavoriteContext as FavoriteContextType,
  FavoriteContextProvider as FavoriteContextProviderType,
} from './favorite-context-provider.types';

const FavoriteContext = React.createContext<FavoriteContextType>({
  isFavorite: () => false,
  toggleFavorite: () => Promise.resolve(),
});

const FavoriteContextProvider: React.FunctionComponent<
  React.PropsWithChildren<FavoriteContextProviderType>
> = ({
  children,
  endpoint,
  errorMessage,
  favoriteIds: initialFavoriteIds = [],
}) => {
  const [favoriteIds, setFavoriteIds] = useState<string[]>(initialFavoriteIds);

  const handleError = () => {
    publish({ text: errorMessage, theme: 'error' });
  };

  const updateFavorites = () =>
    get<Record<string, { id: string }[]>>(endpoint)
      .then(favorites => {
        const ids = Object.values(favorites)
          .flat()
          .map(favorite => favorite.id);
        setFavoriteIds(ids);
      })
      .catch(handleError);

  const isFavorite = useCallback(
    (id: string) => favoriteIds.includes(id),
    [favoriteIds]
  );

  const addFavorite = (id: string, type: string) =>
    post(endpoint, { id, type }).then(updateFavorites).catch(handleError);

  const removeFavorite = (id: string, type: string) =>
    del(replaceQueryParameters(endpoint, { id, type }))
      .then(updateFavorites)
      .catch(handleError);

  const toggleFavorite = (id: string, type: string) =>
    isFavorite(id) ? removeFavorite(id, type) : addFavorite(id, type);

  return (
    <FavoriteContext.Provider value={{ isFavorite, toggleFavorite }}>
      {children}
    </FavoriteContext.Provider>
  );
};

export const useFavoriteContext = () => React.useContext(FavoriteContext);

export default FavoriteContextProvider;
