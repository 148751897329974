import isRunningOnClient from './is-running-on-client';

const getValueFromAttribute = (attribute: string) => {
  if (!isRunningOnClient) {
    return;
  }
  const element = document.querySelector(`[${attribute}]`);
  return element ? element.getAttribute(attribute) || undefined : undefined;
};

export default getValueFromAttribute;
