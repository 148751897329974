import React from 'react';

import Text from 'sats-ui-lib/react/text';

import ArrowLink from 'components/arrow-link/arrow-link';
import ArticleCard from 'components/article-card/article-card';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import ComponentList from 'components/component-list/component-list';
import ContentContainer from 'components/content-container/content-container';
import DynamicImage from 'components/dynamic-image/dynamic-image';
import Layout from 'components/layout/layout';
import RichText from 'components/rich-text/rich-text';

import type { CorporateArticlePage as Props } from './corporate-article-page.types';

const CorporateArticlePage: React.FunctionComponent<Props> = ({
  breadcrumbs,
  introduction,
  layout,
  mainImage,
  pdfs,
  related,
  text,
  title,
  webLinks,
}) => (
  <Layout {...layout}>
    <Breadcrumbs {...breadcrumbs} />
    <div className="corporate-article-page">
      <ContentContainer>
        <div className="corporate-article-page__header">
          {mainImage ? (
            <DynamicImage
              aspectRatio={DynamicImage.aspectRatios.sixteenNine}
              {...mainImage}
            />
          ) : null}
          <div className="corporate-article-page__title">
            <Text
              elementName="h1"
              size={Text.sizes.headline1}
              theme={Text.themes.headline}
              italic
            >
              {title}
            </Text>
          </div>
        </div>
      </ContentContainer>
      <ContentContainer theme={ContentContainer.themes.narrow}>
        <div className="corporate-article-page__content">
          {introduction ? (
            <Text elementName="p" size={Text.sizes.large}>
              {introduction}
            </Text>
          ) : null}
          {text ? <RichText {...text} /> : null}
          {webLinks.length ? (
            <div className="corporate-article-page__link-container">
              {webLinks.map(link => (
                <ArrowLink key={link.href} {...link} />
              ))}
            </div>
          ) : null}
          {pdfs.length ? (
            <div className="corporate-article-page__link-container">
              {pdfs.map(pdf => (
                <ArrowLink key={pdf.href} {...pdf} />
              ))}
            </div>
          ) : null}
        </div>
      </ContentContainer>
      {related.entries.length ? (
        <div className="corporate-article-page__related">
          <ContentContainer theme={ContentContainer.themes.wide}>
            <ComponentList {...related} entryRenderer={ArticleCard} />
          </ContentContainer>
        </div>
      ) : null}
    </div>
  </Layout>
);

export default CorporateArticlePage;
