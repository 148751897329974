import type { ObjectValues } from 'client/types';

export const colors = {
  dark: 'dark',
  light: 'light',
} as const;

export const brands = {
  sats: 'sats',
  elixia: 'elixia',
} as const;

export const sizes = {
  large: 'large',
  small: 'small',
} as const;

export type Logo = {
  brand?: ObjectValues<typeof brands>;
  color?: ObjectValues<typeof colors>;
  href?: string;
  label?: string;
  size?: ObjectValues<typeof sizes>;
};
