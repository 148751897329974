import type { JsonObject, JsonValue } from 'type-fest';

import { fromQueryString } from 'shared/from-query-string';

import extractServerError from './extract-server-error';
import { publish } from './messages';
import { isMessageDetail } from './messages.types';
import { trackEvents } from './tracking';

const defaultFetchOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const parseResponse = async (response: Response): Promise<JsonObject> => {
  const text = await response.text();
  if (!text) {
    return {};
  }

  try {
    const json = JSON.parse(text);

    if (Array.isArray(json)) {
      return {};
    }

    if (typeof json === 'object') {
      return json;
    }

    return {};
  } catch {
    return {};
  }
};

class ApiError extends Error {
  responseStatus: number;
  responseBody: JsonObject;
  extra: {
    query?: ReturnType<typeof fromQueryString>;
    response: JsonObject;
  };
  constructor(message: string) {
    super(message);
  }
}

const handleMessage = (json: JsonObject) => {
  if (isMessageDetail(json.message)) {
    publish(json.message);
  }
};

const handleNotOk = (response: Response, json: JsonObject) => {
  if (!response.ok) {
    const message = extractServerError({ responseBody: json });
    const error = new ApiError(message ? message : response.statusText);

    const [url, query] = response.url.split('?');

    error.name = `${response.status} on ${url}`;
    error.responseStatus = response.status;
    error.responseBody = json;
    error.extra = {
      query: query ? fromQueryString(query) : undefined,
      response: json,
    };

    throw error;
  }
};

const handleAnalytics = (data: JsonObject) => {
  if (Array.isArray(data.dataLayer)) {
    trackEvents(data.dataLayer);
  }
};

const unpackPayload = <T extends JsonObject>(body: JsonObject): T => {
  if (body && body.payload) {
    return body.payload as T;
  }

  return body as T;
};

/** Use this in a `catch` before a generic `catch`.

If a specified status' handler returns a truthy value,
the rejection is not propagated further.
If a specified status' handler returns a falsy value,
the rejection is propagated, so the callee can handle any
generic errors by cleaning up UI, displaying a message,
cancelling timers etc.

```js
post("some/url")
      .then(resultHandler)
      .catch(overrideStatus({
        401: () => {
          showErrorMessage("Please log in");
          return true;
        }
      }))
      .catch(genericErrorHandler)
      .finally(finallyHandler)
```
*/
export const overrideStatus =
  (overrides: Record<number, (body: JsonObject) => boolean | undefined> = {}) =>
  (error: ApiError) => {
    if (overrides[error.responseStatus]?.(error.responseBody || {})) {
      return;
    }

    throw error;
  };

const request = async <T extends JsonObject>(
  url: string,
  options: RequestInit = {}
): Promise<T> => {
  const headers = Object.assign(
    {},
    defaultFetchOptions.headers,
    options.headers
  );

  const fetchOptions = Object.assign({}, defaultFetchOptions, options, {
    headers,
  });

  const response = await fetch(url, fetchOptions);
  const json = await parseResponse(response);

  handleMessage(json);
  handleNotOk(response, json);
  handleAnalytics(json);

  return unpackPayload<T>(json);
};

export const del = <T extends JsonObject>(
  endpoint: string,
  options: RequestInit = {}
) => request<T>(endpoint, Object.assign({}, options, { method: 'delete' }));

export const get = <T extends JsonObject>(
  endpoint: string,
  options: RequestInit = {}
) => request<T>(endpoint, options);

export const post = <T extends JsonObject>(
  endpoint: string,
  data: Record<string, JsonValue>,
  options: RequestInit = {}
) =>
  request<T>(
    endpoint,
    Object.assign({}, options, {
      body: JSON.stringify(data),
      method: 'post',
    })
  );
