import React, { useRef } from 'react';

import clamp from 'client/helpers/clamp';
import useScrollEffect from 'hooks/use-scroll-effect';

/** Adds sticky positioning for content.
 * If content is taller than the screen, sticks content to the top when scrolling upwards and to the bottom when scrolling down (this behavior is not possible with CSS alone).
 * If content is shorter than the screen, sticks content to the top  */
const StickyContainer: React.FunctionComponent<
  React.PropsWithChildren<{
    margin?: number;
  }>
> = ({ children, margin = 24 }) => {
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const innerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const displacement = useRef(0);

  // NOTE: This effect mutates styles instead of using state in order to ensure maximum framerate
  useScrollEffect((_, delta) => {
    if (!innerRef.current || !containerRef.current) {
      return;
    }

    if (getComputedStyle(innerRef.current).position !== 'sticky') {
      innerRef.current.setAttribute('style', '');
      return;
    }

    innerRef.current.style.top = `${margin}px`;

    const containerRect = containerRef.current.getBoundingClientRect();
    const innerRect = innerRef.current.getBoundingClientRect();

    // NOTE: If the height of the children is smaller than the screen, there's no need to do any tricks since the entire children will fit on screen
    if (innerRect.height + margin < window.innerHeight) {
      innerRef.current.style.transform = 'none';
      return;
    }

    // NOTE: This maximum displacement makes it so that the element sticks to the bottom when scrolling downwards
    const maxDisplacement = Math.max(
      0,
      innerRect.height - window.innerHeight + margin * 2
    );

    // NOTE: Measures the distance from the bottom of the screen to the bottom of the container element (`containerRect.bottom` is measured from the top of the screen)
    const containerBottom = containerRect.bottom - window.innerHeight;

    // NOTE: If we don't check this, the content will do a weird, super fast scroll when at the bottom of the page and scrolling upwards.
    if (containerBottom + margin <= maxDisplacement) {
      return;
    }

    if (containerRect.top <= margin) {
      const newDisplacement = displacement.current + delta;
      displacement.current = clamp(newDisplacement, 0, maxDisplacement);
    } else {
      displacement.current = 0;
    }

    innerRef.current.style.transform = `translateY(${-displacement.current}px)`;
  });

  return (
    <div className="sticky-container" ref={containerRef}>
      <div className="sticky-container__inner" ref={innerRef}>
        {children}
      </div>
    </div>
  );
};

export default StickyContainer;
