import PersonIcon from '@sats-group/icons/24/person';
import TicketIcon from '@sats-group/icons/24/ticket';
import TimeIcon from '@sats-group/icons/24/time';
import React from 'react';

import Text from 'sats-ui-lib/react/text';

import Breadcrumbs from 'client/components/breadcrumbs/breadcrumbs';
import ContentContainer from 'client/components/content-container/content-container';
import DisplayBootCampEvents from 'client/components/display-boot-camp-events/display-boot-camp-events';
import DropdownWrapper from 'client/components/dropdown-wrapper/dropdown-wrapper';
import DynamicImage from 'client/components/dynamic-image/dynamic-image';
import ElementInterpolator from 'client/components/element-interpolator/element-interpolator';
import Layout from 'components/layout/layout';

import type { BootCampConceptPage as Props } from './boot-camp-concept-page.types';

const BootCampConceptPage: React.FunctionComponent<Props> = ({
  breadcrumbs,
  details,
  description,
  dropdownWrapper,
  events,
  eventsHeader,
  headerImage,
  idToJumpTo,
  layout,
  results,
  title,
  totalBootCampEvents,
}) => (
  <Layout {...layout}>
    <Breadcrumbs {...breadcrumbs} />
    <ContentContainer>
      {headerImage ? <DynamicImage {...headerImage} /> : null}
      <Text
        className="boot-camp-concept-page__heading"
        elementName="h1"
        size={Text.sizes.headline2}
        theme={Text.themes.headline}
        italic
      >
        {title}
      </Text>
    </ContentContainer>
    <ContentContainer className="boot-camp-concept-page__content">
      {description ? (
        <div className="boot-camp-concept-page__description">
          {description.heading ? (
            <Text theme={Text.themes.emphasis}>{description.heading}</Text>
          ) : null}
          <Text>{description.text}</Text>
        </div>
      ) : null}

      {details ? (
        <div className="boot-camp-concept-page__details">
          <Text
            className="boot-camp-concept-page__events-header"
            elementName="h3"
            size={Text.sizes.headline3}
            theme={Text.themes.emphasis}
          >
            {details.heading}
          </Text>
          <div className="boot-camp-concept-page__details-item-wrapper">
            {details.sessions ? (
              <div className="boot-camp-concept-page__details-item">
                <TimeIcon />
                <Text className="boot-camp-concept-page__details-session">
                  {details.sessions}
                </Text>
              </div>
            ) : null}
            <div className="boot-camp-concept-page__details-item">
              <PersonIcon />
              <Text>{details.participants}</Text>
            </div>
            <div className="boot-camp-concept-page__details-item">
              <TicketIcon /> <Text>{details.price}</Text>
            </div>
          </div>
          <Text
            className="boot-camp-concept-page__details-sub-text"
            size={Text.sizes.small}
          >
            {details.subText}
          </Text>
        </div>
      ) : null}
    </ContentContainer>
    <ContentContainer id={idToJumpTo}>
      <Text elementName="h2" size={Text.sizes.headline2}>
        {eventsHeader}
      </Text>
    </ContentContainer>
    <div className="boot-camp-concept-page__dropdown-wrapper">
      <ContentContainer>
        <DropdownWrapper {...dropdownWrapper} />
      </ContentContainer>
    </div>
    <ContentContainer className="boot-camp-concept-page__events">
      <Text size={Text.sizes.basic}>
        <ElementInterpolator
          template={results}
          elements={{
            number: (
              <Text
                className="boot-camp-concept-page__number"
                elementName="span"
                theme={Text.themes.emphasis}
              >
                {totalBootCampEvents}
              </Text>
            ),
          }}
        />
      </Text>
      <DisplayBootCampEvents {...events} />
    </ContentContainer>
  </Layout>
);

export default BootCampConceptPage;
